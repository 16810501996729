import s from '../styles.module.css';
import { ReactComponent as LogoSvg } from 'resources/svg/logo.svg';
import { ReactComponent as CertikLogo } from 'resources/svg/certik-logotype-h-w.svg';
import { COPYRIGHT_TEXT } from '../../../constants';
import cx from 'classnames';
import SocialContacts from 'components/social-links/social-contacts';
import { Layout } from 'antd';

const Footer: React.FunctionComponent = () => {
  return (
    <Layout.Footer className={cx(s.footer, 'highlighted')}>
      <div className={s.logos}>
        <LogoSvg />

        <a href='https://www.certik.com/projects/lotteryx' title={'Certik site'}
           className={s.certik}
           target={'_blank'} rel={'noopener noreferrer'}
        > <CertikLogo aria-hidden /></a>

      </div>
      <span className={s.center}>{COPYRIGHT_TEXT}</span>

      <div className={s.footerLinks}>
        <SocialContacts />
      </div>
    </Layout.Footer>
  );
};

export default Footer;
