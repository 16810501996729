import classes from './styles.module.css';

const WalletTab = () => {
  return (
    <div className={classes.root}>

    </div>
  );
};

export default WalletTab;
