export const winningNumbersArray = [
  {
    id: 1,
    number: 0,
    color: '#5A8AFC',
  },
  {
    id: 2,
    number: 0,
    color: '#EC4746',
  },
  {
    id: 3,
    number: 0,
    color: '#EEF769',
  },
  {
    id: 4,
    number: 0,
    color: '#00D26D',
  },
  {
    id: 5,
    number: 0,
    color: '#FD8F77',
  },
  {
    id: 6,
    number: 5,
    color: '#A9347C',
  },
];

export const TICKET_LIMIT_PER_REQUEST = 2500;

export const CLIENT_TICKETS_HISTORY =
  'https://api.thegraph.com/subgraphs/name/koinor/mumbailot';

export const NUM_ROUNDS_TO_CHECK_FOR_REWARDS = 3;

export const  GRAPH_API_LOTTERY = ''
 export const MAX_LOTTERIES_REQUEST_SIZE = 100;
