import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useWallet } from 'wallets/wallet';
import cx from 'classnames';
import Web3 from 'web3';

import { InfoSquare, Category, Document } from 'react-iconly';

// import { useTheme } from 'components/theme-provider';

import { getWSRpcUrl } from 'web3/utils';

import Warnings from 'components/warnings';
import { SiderNavLinkProps } from 'components/sider-nav-link';
import MobileMenu from 'components/mobile-menu';
import WalletConnect from 'components/wallet-connector';
import MainView from 'views/main';
import DashboardView from 'views/dashboard';
import LotteryView from 'views/lottery';

import { ReactComponent as LogoSvg } from 'resources/svg/logo.svg';
import { ReactComponent as LogoCutSvg } from 'resources/svg/logoCut.svg';
import { ReactComponent as LotterySvg } from '../../resources/svg/icons/lottery.svg';
import { ReactComponent as SwappSvg } from '../../resources/svg/tokens/swapp.svg';
// import { ReactComponent as MoonSvg } from 'resources/svg/icons/moon.svg';
// import { ReactComponent as SunSvg } from 'resources/svg/icons/sun.svg';
import { ReactComponent as DashboardSvg } from 'resources/svg/icons/dashboard.svg';

import s from './styles.module.css';
import { menuConfig } from '../../constants';
import IconSelector from 'components/icon-selector';
import Footer from './components/footer';
import ProfileView from '../profile';
import ProtectedRoute from 'components/protected-route';
import ContactsView from '../contacts';
import paths from '../../constants/paths';
import HighLight from 'components/highlight';

declare let window: any;

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const DEFAULT_CONTRACT_PROVIDER = new Web3.providers.WebsocketProvider(
  getWSRpcUrl(),
);
const web3 = new Web3(DEFAULT_CONTRACT_PROVIDER);

// noinspection JSUnusedLocalSymbols
const saveNewChainId = (id: number = 1) => {
  return [
    [
      {
        icon: <LotterySvg />,
        label: 'How It Works',
        path: '/how-it-works',
      },
      {
        icon: <SwappSvg />,
        label: 'Swapp',
        path: '/swapp',
      },
      {
        icon: <DashboardSvg />,
        label: 'Dashboard',
        path: '/dashboard',
      },
    ],
  ];
};

const LayoutView: React.FunctionComponent = () => {
  const wallet = useWallet();

  // const { toggleDarkTheme, isDarkTheme } = useTheme();
  const [, /*siderNavLinks*/ setSiderNavLinks] = useState<SiderNavLinkProps[][]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const query = window.location.search.substring(1);
  if (query.substring(0, 3) === 'ref' && web3.utils.isAddress(query.substring(4))) {
    localStorage.setItem('referral', query.substring(4));
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('networkChanged', (id: number) => {
        setSiderNavLinks(saveNewChainId(id));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSiderNavLinks(saveNewChainId(wallet.networkId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.networkId]);

  const onCollapse = useCallback(() => {
    setCollapsed(prevState => !prevState);
  }, []);

  // const handleThemeToggle = () => {
  //   toggleDarkTheme();
  // };

  return (
    <>
      <Layout className={s.container}>
        {/* <SiderNav className={s.siderNav} links={siderNavLinks} /> */}
        <Sider
          className={cx(s.siderNav, collapsed ? s.collapsedX : '')}
          width={250}
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <div className={cx(s.header, collapsed && s.collapsed)}>
            {collapsed ? <LogoCutSvg /> : <LogoSvg />}
          </div>
          <Menu theme='dark' defaultSelectedKeys={['1']} mode='inline'>
            <Menu.Item key='dashboard' icon={<Category primaryColor={'var(--primary-theme-color)'} />}>
              <Link to='/dashboard'>
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <span className={s.titleDivider}>LOTTERIES</span>
            {(menuConfig ?? []).map(({ networkName, chainId, tokens }) => (
              <SubMenu
                className={s.submenu}
                key={chainId}
                icon={<IconSelector name={networkName} />}
                title={networkName}
              >
                {tokens?.length ? (
                  tokens.map((token) => (
                    <Menu.Item key={token.route}>
                      <Link className={s.tokenMenuItem} to={`/${token.route}`}>
                        <div className={s.tokenImageWrapper}>
                          {typeof token.tokenImgUrl === 'string' &&
                            token.tokenImgUrl && (
                              <img
                                className={s.tokenImage}
                                width={16}
                                height={16}
                                src={token.tokenImgUrl}
                                alt={token.tokenName}
                              />
                            )}
                        </div>
                        <span>{token.tokenName}</span>
                      </Link>
                    </Menu.Item>
                  ))
                ) : (
                  <Menu.Item key={networkName + chainId}>
                    <div className={s.tokenMenuItem}>
                      <div className={s.tokenImageWrapper} />
                      <span>Coming soon</span>
                    </div>
                  </Menu.Item>
                )}
              </SubMenu>
            ))}

            <span className={s.titleDivider}>Other</span>
            <Menu.Item key='11' icon={<InfoSquare />}>
              <Link to={paths.HOW_IT_WORKS}>
                <span>How it works</span>
              </Link>
            </Menu.Item>


            <Menu.Item key='121' icon={<Document />}>
              <Link to={paths.CONTACTS}>
                <span>Contacts</span>
              </Link>
            </Menu.Item>
          </Menu>
          {/*<Button*/}
          {/*  type='link'*/}
          {/*  className={s.themeToggle}*/}
          {/*  onClick={handleThemeToggle}>*/}
          {/*  {!isDarkTheme ? <MoonSvg /> : <SunSvg />}*/}
          {/*  <FadeBlock className={s.themeText} visible={!collapsed}>*/}
          {/*    {!isDarkTheme ? 'Dark' : 'Light'} Theme*/}
          {/*  </FadeBlock>*/}
          {/*</Button>*/}
        </Sider>
        <Content>
          <WalletConnect />

          <Layout className={s.main}>
            <MobileMenu />
            <Warnings>
              <div className={s.content}>
                <HighLight position={{ right: -50, top: 0 }} size={117} blur={118} />
                <HighLight position={{ right: '10%', bottom: '10%' }} size={117} blur={118} />
                <Switch>
                  <Route path={paths.HOW_IT_WORKS} component={MainView} />
                  <Route path={paths.DASHBOARD} component={DashboardView} />
                  <ProtectedRoute path={paths.PROFILE} component={ProfileView} />
                  <Route path={paths.CONTACTS} component={ContactsView} />
                  <Route path={paths.LOTTERY} component={LotteryView} />
                  <Route exact path={paths.ROOT}>
                    <Redirect to={paths.DASHBOARD} />
                  </Route>
                </Switch>
              </div>
            </Warnings>
          </Layout>
        </Content>
      </Layout>
      <Footer />
    </>
  );
};

export default LayoutView;
