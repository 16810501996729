import { CSSProperties, FC } from 'react';
import classes from './styles.module.css';
import cx from 'classnames';

type P = 'px' | '%' | 'em' | 'rem';
type PositionType = `${number}${P}` | number;

interface Position {
  top: PositionType;
  left: PositionType;
  right: PositionType;
  bottom: PositionType;
}

interface HighLightProps {
  className?: string;
  size?: number;
  blur?: number;
  zIndex?: number;
  position?: Partial<Position>;
}

const checkIsPixels = (val: number | string | undefined): string => {

  if (val) {
    if (typeof val === 'number') return `${val}px`;
    return val;
  }

  return '';
};


const HighLight: FC<HighLightProps> = (
  {
    size = 94, zIndex = 0, blur = 168, className,
    position = {},
  }) => {


  return (
    <div
      style={{
        '--size': `${size}px`,
        '--blur': `${blur}px`,
        '--index': zIndex,
        '--left': checkIsPixels(position?.left),
        '--right': checkIsPixels(position?.right),
        '--top': checkIsPixels(position?.top),
        '--bottom': checkIsPixels(position?.bottom),
      } as CSSProperties}
      className={cx(classes.root, className)}
    />
  );
};

export default HighLight;
