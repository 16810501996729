import axios, { AxiosResponse, CancelToken } from 'axios';
import { apiUrl } from '../constants';
import { SignMessageDto } from '../interfaces/signMessage.dto';

const setUserData = (response: AxiosResponse) => {
  if (response.data.accessToken) {
    const { user, accessToken, refreshToken } = response.data;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }
  return response.data;
};
export const register = (address: string, signature: string, expiredDate: number) => {
  return axios.post(apiUrl.auth.register, {
    address,
    signature,
    expiredDate,
  })
    .then((response) => {
      return setUserData(response);
    });
};

export const login = (address: string, signature: string, expiredDate: number) => {
  return axios
    .post(apiUrl.auth.login, {
      address,
      signature,
      expiredDate,
    })
    .then((response) => {
      return setUserData(response);
    })
    .catch((error) => {
      console.log({ error });
      return register(address, signature, expiredDate);
    });
};

export const logout = () => {
  const userData = localStorage.getItem('user');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  localStorage.removeItem('signatureMessage');
  localStorage.removeItem('signatureMessageExpired');
  localStorage.removeItem('user');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('signMessage');
  localStorage.removeItem('avatar');
  if (userData) {
    const { address } = JSON.parse(userData);
    axios.post(apiUrl.auth.logout, {
      address,
      accessToken,
      refreshToken,
    });
  }
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  return null;
};


export const refreshToken = (cancelToken?: CancelToken) => {
  const userData = localStorage.getItem('user');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (userData && accessToken && refreshToken) {
    const { address } = JSON.parse(userData);
    return axios.post(apiUrl.auth.refreshToken, {
      address,
      accessToken,
      refreshToken,
    }, { cancelToken })
      .then((response) => {
        return setUserData(response);
      })
      .catch(() => {
        logout();
        return false;
      });
  } else {
    logout();
    return false;
  }
};


export const getSignature = (address: string, cancelToken?: CancelToken): Promise<SignMessageDto> => {
  return axios.post(apiUrl.auth.signMessage, {
    address,
  }, { cancelToken })
    .then((response) => {
      localStorage.setItem('signatureMessage', response.data.message);
      localStorage.setItem('signatureMessageExpired', response.data.expiredDate.toString());
      return response.data;
    });
};
