// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT: '/',
  HOW_IT_WORKS: '/how-it-works',
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  CONTACTS: '/contacts',
  LOTTERY: '/:lotteryPath',
  IFRAME: '/iframe'
};

export const appLink = 'https://lotteryx.io'
