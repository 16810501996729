import classes from './styles.module.css';
import React, { DetailedHTMLProps, FC } from 'react';
import cx from 'classnames';


const ModalBody:  FC<DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({ className, ...props }) => {
  return (
    <div className={cx(classes.body, className)} {...props} />
  );
};

export default ModalBody;
