import classes from './styles.module.css';
import { contactPageText, contacts } from '../../constants';

import image from 'resources/images/Sit and using laptop 1.png';
import { FC, Fragment, useState } from 'react';
import OutlinedTextField from 'components/outlined-textfield';
import Button from 'components/themed-button';
import flags from 'config/flags';
import SocialContacts from 'components/social-links/social-contacts';


const createUri = (key: keyof typeof contacts): string => {

  switch (key) {

    case 'mailto' :
      // case 'tel' :
      return `${key}:${contacts[key].value}`;

    case 'location':
      return `https://maps.google.com/?q=${contacts[key].value}`;

    default:
      return key;

  }
};


interface FooterProps {
  onSubmit: (email: string) => Promise<void>;
}

const ContactsView = () => {

  const onSubscribe = async (email: string) => {

  };

  return (
    <section className={classes.root}>


      <header className={classes.header}>
        <div className={classes.info}>
          <h2>Contact Us</h2>
          <address className={classes.links}>
            {
              Object.keys(contacts).map((key) => (
                <a key={key}
                   rel={'noopener noreferrer'}
                   href={createUri(key as keyof typeof contacts)}
                   target={key === 'location' ? '_blank' : undefined}
                   className={classes.link}
                >
                  <span
                    className={classes.linkIcon}>{contacts[key as keyof typeof contacts].icon}</span> {contacts[key as keyof typeof contacts].value}
                </a>
              ))
            }

          </address>

        </div>
        <div className={classes.imageContainer}>
          <div className={classes.higLight} />
          <img src={image} alt='Sit and using laptop' className={classes.heroImage} />
        </div>
      </header>


      <article className={classes.text}>
        {
          contactPageText.map(({ text, title }, ind) => (
            <Fragment key={title + ind}>
              <h3>{title}</h3>
              {
                text.map(t => (
                  <p key={t.substring(0, 10)}>{t}</p>
                ))
              }
            </Fragment>
          ))
        }
      </article>


      <Footer onSubmit={onSubscribe} />
    </section>
  );
};


const Footer: FC<FooterProps> = ({ onSubmit }) => {


  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmitClick = async () => {
    if (!email.trim()) return;

    try {
      setLoading(true);
      await onSubmit(email.trim());
    } catch (e) {

    } finally {
      setLoading(false);
    }

  };

  if (!flags.subscribe) return null;

  return (
    <footer className={classes.subscribe}>
      <h3>Subscribe for updates</h3>

      <form className={classes.inputContainer}>
        <OutlinedTextField onChange={() => null} label={''}
                           value={email} onChangeText={setEmail}
        />
        <Button primary onClick={onSubmitClick} disabled={loading} className={classes.subscribeBtn}>Subscribe</Button>
      </form>

      <h4>Join the community</h4>

      {/*<div className={classes.social}>*/}

        <SocialContacts className={classes.socialLink}
        />


      {/*</div>*/}

    </footer>
  );
};


export default ContactsView;
