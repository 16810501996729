/* eslint-disable no-useless-concat */
import { ReactComponent as EthereumSvg } from '../resources/svg/icons/ethereum.svg';
import { ReactComponent as BinanceSvg } from '../resources/svg/icons/binance.svg';
import { ReactComponent as PolygonSvg } from '../resources/svg/icons/polygon.svg';
import { ReactComponent as CronosSvg } from '../resources/svg/icons/cronos.svg';

import { ReactComponent as Swapp } from '../resources/svg/tokens/swapp.svg';
import { ReactComponent as ChainLink } from '../resources/svg/tokens/chainlink.svg';
// import { ReactComponent as VaultBTC } from '../resources/svg/tokens/btc-vault.svg';
// import { ReactComponent as Floki } from '../resources/svg/tokens/floki-logo.svg';
import { ReactComponent as Hibiki } from '../resources/svg/tokens/hibiki-logo.svg';
import { ReactComponent as Anyswap } from '../resources/svg/tokens/anyswap.svg';
import { ReactComponent as USDT } from '../resources/svg/tokens/usdt.svg';
import { ReactComponent as BUSD } from '../resources/svg/tokens/busd.svg';
import { ReactComponent as BANANA } from '../resources/svg/tokens/banana.svg';
import { ReactComponent as DOGE } from '../resources/svg/tokens/doge.svg';

import { ReactComponent as Facebook } from '../resources/svg/icons/socials/facebook.svg';
import { ReactComponent as Instagram } from '../resources/svg/icons/socials/instagram.svg';
import { ReactComponent as Discord } from '../resources/svg/icons/socials/discord.svg';
import { ReactComponent as Twitter } from '../resources/svg/icons/socials/twitter.svg';
import { ReactComponent as Telegram } from '../resources/svg/icons/socials/telegram.svg';


import { Message, Location } from 'react-iconly';

export const swappWallets = [
  '0x0d970a04d46c73B6d20d9a0B2B07C35F2495ca9c',
  '0x915D99375Ba8EDbbee46bE1AD045718a05A6655b',
  '0x7Db4456a73a9C94a381d244E9dfC76E83C05913E',
  '0x62F16a5bA06693B1E96a656d46e66A8CdaE17C69',
  '0x94dc2f1823AbfdC2fb9BB8Ae10162b65D2Cf1c65',
  '0x2a8eA8a4842DA268FA4180b1a99B7876f820ECC1',
  '0x000baFB91ED6436ad2888C2418197aFDB85785C5',
  '0xd4041e1c24A54134Fb9657e8DA85e75001D7Ea44',
];

const chainId = process.env.REACT_APP_WEB3_CHAIN_ID;
const lotteryApiUrl = process.env.REACT_APP_LOTTERY_API_URL;
const isTest = !!process.env.REACT_APP_IS_TEST;

export const apiUrl = {
  auth: {
    signMessage: `${lotteryApiUrl}/auth/sign-message`,
    login: `${lotteryApiUrl}/auth/login`,
    logout: `${lotteryApiUrl}/auth/logout`,
    register: `${lotteryApiUrl}/auth/register`,
    refreshToken: `${lotteryApiUrl}/auth/auth-by-token`,
  },
  user: {
    update:  `${lotteryApiUrl}/users`,
    currentUser:  `${lotteryApiUrl}/users`,
    getByParams:  `${lotteryApiUrl}/users/get`,
    getList:  `${lotteryApiUrl}/users/list`,
  },
  files: {
    upload: `${lotteryApiUrl}/files`,
    getBlob:`${lotteryApiUrl}/files/get-blob`,
    getData:`${lotteryApiUrl}/files/get/`,
  }
};

export const refreshTokenInterval = 10*60*1000;//10min

export const contactPageText = [
  {
    title: 'How can I list my token on the Lottery?',
    text: [`Please contact the lotteryX team with your token contract address, the address where you want the proceeds to go to from the lottery (2-4% of winning depending on the promotion that you do), and an SVG icon for your token.`],
  }, {
    title: 'Someone made a fake copy of my token! Can you block it?',
    text: [`Please reach out to our support team with verifiable information of the original token that was copied and we will do our best to ensure this token is taken down from LotteryX.`],
  }, {
    title: 'Something else',
    text: [`You can shoot us an email at support@lottryx.io if the above parts don’t cover your questions.`],
  },
];


export const contacts = {

  mailto: {
    value: 'support@lottryx.io',
    icon: <Message />,
  },
  // tel:
  //   {
  //     value: '+1 (999) 999 - 99 - 99',
  //     icon: <Calling />,
  //   },
  location: {
    value: 'Estonia, Tallinn',
    icon: < Location />,
  },
};


export const chainArrays = [
  {
    label: 'Binance',
    icon: <BinanceSvg />,
    id: 'binance',
    selected: false,
    current: false,
    bridgeId: 2,
    chainId: +chainId === 1 ? 56 : 97,
    symbol: 'BNB',
    decimals: 18,
    rpcUrls: +chainId === 1 ? 'https://bsc-dataseed.binance.org/' : 'https://data-seed-prebsc-2-s1.binance.org:8545/',
    blockExplorerUrls: 'https://bscscan.com',
  },
  {
    label: 'Ethereum',
    icon: <EthereumSvg />,
    id: 'ethereum',
    selected: false,
    current: false,
    bridgeId: 1,
    chainId: +chainId === 1 ? 1 : 3,
    symbol: 'ETH',
    decimals: 18,
    rpcUrls: 'https://mainnet.infura.io/v3/8d342375c0044757adf4e8661cb62deb',
    blockExplorerUrls: 'https://etherscan.io',
  },
  {
    label: 'Polygon',
    icon: <PolygonSvg />,
    id: 'polygon',
    selected: false,
    current: false,
    bridgeId: 3,
    chainId: +chainId === 1 ? 137 : 80001,
    symbol: 'MATIC',
    decimals: 18,
    rpcUrls: +chainId === 1 ? 'https://rpc-mainnet.maticvigil.com' : 'https://rpc-mainnet.matic.network',
    blockExplorerUrls: 'https://polygonscan.com/',
  },
  {
    label: 'Cronos',
    icon: <CronosSvg />,
    id: 'cronos',
    selected: false,
    current: false,
    bridgeId: 4,
    chainId: 338,
    symbol: 'tCRO',
    decimals: 18,
    rpcUrls: +chainId === 1 ? 'https://evm-cronos.crypto.org' : 'https://cronos-testnet.crypto.org:8545/',
    blockExplorerUrls: 'https://cronos.crypto.org/',
  },
];

export const BridgeTransactionsStatuses = [
  'New',
  'Sent to mint',
  'Minted',
  'Error',
  'Check not passed',
];

export const COPYRIGHT_TEXT =
  'Copyright © 2021 LotteryX LLC. All rights reserved';

export const NEXT_LOTTERY_PERIOD = 12;

export const socialLinks = [
  {
    id: 'Twitter',
    shareLink: 'http://twitter.com/' + '',
    link: 'https://twitter.com/LotteryXio',
  },
  {
    id: 'Facebook',
    shareLink: 'https://www.facebook.com/',
    link: '',
  },
  {
    id: 'Telegram',
    shareLink: 'https://t.me/' + '',
    link: '',
  },
  {
    id: 'Discord',
    shareLink: 'https://discord.gg/cjQmZVHGPG' + '',
    link: '',
  },
  {
    id: 'Instagram',
    shareLink: 'https://www.instagram.com/' + '',
    link: '',
  },
];

export const socialLinksIcons = {
  twitter: <Twitter />,
  facebook: <Facebook />,
  telegram: <Telegram />,
  discord: <Discord />,
  instagram: <Instagram />,
};

export const STORAGE_KEYS = {
  time: '_tmsq3',
};


//TODO Update functions/utils when 9 decimals token added!!!
const menuConfigAll = [
  {
    networkName: 'BSC',
    chainId: 56,
    networkImage: <BinanceSvg />,
    isForTest: false,
    tokens: [
      {
        route: 'swapp-bsc',
        tokenStatus: 'Hot! 🔥',
        tokenName: 'SWAPP',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenSymbol: 'SWAPP',
        tokenAddress: '0x0efE961C733FF46ce34C56a73eba0c6a0E18E0F5',
        lotteryAddress: '0x35febd0415a170a062b0d35a84fa46858397ccc1',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/swapplotteryx',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=swapp&vs_currencies=usd',
        tokenImgUrl: '',
        partner: 'https://swapp.ee',
        Icon: (props) => <Swapp {...props} />,
        info:
          'Swapp is a fairly-launched, community-driven DeFi protocol built on the Ethereum blockchain. The Swapp dev team consists of talented business professionals, experienced in blockchain infrastructure and finance. Swapp is proud to be democratizing the data industry and making complex financial products simple and accessible to anyone all.',
        decimals: 18,
      },
      // {
      //   route: 'vault-bsc',
      //   tokenName: 'VaultDeFi',
      //   tokenStatus: 'New',
      //   tokenSymbol: 'VAULT',
      //   tokenColor: '#795CED',
      //   tokenColorSecond: '#795ced33',
      //   tokenAddress: '0x92da405b6771c9caa7988a41dd969a73d10a3cc6',
      //   lotteryAddress: '0xDAF8DC9F437A58b01A560013B7B2fcaA130e599a',
      //   graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/vault',
      //   tokenPriceUrl:
      //     'https://api.coingecko.com/api/v3/simple/price?ids=vaultdefi&vs_currencies=usd',
      //   tokenImgUrl: '',
      //   partner: 'https://vaultdefi.com/',
      //   Icon: (props) => <VaultBTC {...props} />,
      //   info: '',
      // decimals: 9,
      // },
      {
        route: 'wbnb-bsc',
        tokenName: 'WBNB',
        tokenStatus: '',
        tokenSymbol: 'WBNB',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        lotteryAddress: '0x821d8039ada5199ff0f9eccce3675a5ac5c51617',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/wbnblottery',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <BinanceSvg {...props} />,
        info: '',
        decimals: 18,
      },
      // {
      //   route: 'floki-bsc',
      //   tokenName: 'FLOKI-INU',
      //   tokenStatus: 'New',
      //   tokenSymbol: 'FLOKI',
      //   tokenColor: '#795CED',
      //   tokenColorSecond: '#795ced33',
      //   tokenAddress: '0x2B3F34e9D4b127797CE6244Ea341a83733ddd6E4',
      //   lotteryAddress: '0xf620c2C01Abd4611ab5E8F60E8278dFd71F9Dd31',
      //   graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/flokibsc',
      //   tokenPriceUrl:
      //     'https://api.coingecko.com/api/v3/simple/price?ids=floki-inu&vs_currencies=usd',
      //   tokenImgUrl: '',
      //   partner: '',
      //   Icon: (props) => <Floki {...props} />,
      //   info: '',
      //   decimals: 9,
      // },
      {
        route: 'hibiki-bsc',
        tokenName: 'HIBIKI-FINANCE',
        tokenStatus: 'New',
        tokenSymbol: 'HIBIKI',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xa532cfaa916c465a094daf29fea07a13e41e5b36',
        lotteryAddress: '0x26cf123d3423957ee82c82914c8b8d7c474419c7',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/hibikibsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=hibiki-finance&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <Hibiki {...props} />,
        info: '',
        decimals: 18,
      },
      {
        route: 'anyswap-bsc',
        tokenName: 'ANYSWAP',
        tokenStatus: 'New',
        tokenSymbol: 'ANY',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xF68C9Df95a18B2A5a5fa1124d79EEEffBaD0B6Fa',
        lotteryAddress: '0xa3a6b5f2e4fc6eaf843148d1e276bd46f8e98e6c',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/anyswapbsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=anyswap&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <Anyswap {...props} />,
        info: '',
        decimals: 18,
      },
      {
        route: 'usdt-bsc',
        tokenName: 'Tether',
        tokenStatus: 'New',
        tokenSymbol: 'USDT',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
        lotteryAddress: '0xf085c6c612a4df5d1090a81a7a33b90d0614752f',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/usdtbsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <USDT {...props} />,
        info: '',
        decimals: 18,
      },
      {
        route: 'busd-bsc',
        tokenName: 'BUSD',
        tokenStatus: 'New',
        tokenSymbol: 'BUSD',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        lotteryAddress: '0x0b7fdfbb36776faf0abfc53b9f3839c4a9e410d8',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/busdbsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=busd&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <BUSD {...props} />,
        info: '',
        decimals: 18,
      },
      {
        route: 'apeswap-bsc',
        tokenName: 'APESWAP-FINANCE',
        tokenStatus: 'New',
        tokenSymbol: 'BANANA',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
        lotteryAddress: '0x46F7aCCc3141cAd73D022f9428B9470A456F88ae',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/apeswapbsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=apeswap-finance&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <BANANA {...props} />,
        info: '',
        decimals: 18,
      },
      {
        route: 'matic-bsc',
        tokenName: 'MATIC',
        tokenFullName: 'matic-network',
        tokenStatus: 'New',
        tokenSymbol: 'MATIC',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
        lotteryAddress: '0x52B7D3Ef0d4dAD3407d5358DB3339b36EEB7Ef74',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/polygonbsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <PolygonSvg {...props} />,
        info: '',
        decimals: 18,
      },
      {
        route: 'doge-bsc',
        tokenName: 'DOGE',
        tokenFullName: 'binance-peg-dogecoin',
        tokenStatus: 'New',
        tokenSymbol: 'DOGE',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
        lotteryAddress: '0xd51fd09e396eecaeb27d5c025f3fa71d31238872',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/dogebsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=binance-peg-dogecoin&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <DOGE {...props} />,
        info: '',
        decimals: 8,
      },
      {
        route: 'eth-bsc',
        tokenName: 'Ethereum',
        tokenStatus: 'New',
        tokenSymbol: 'ETH',
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        lotteryAddress: '0xf6bc2174d534D9972921853fF9cdD4c1C80D751C',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/degamble/ethbsc',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <EthereumSvg {...props} />,
        info: '',
        decimals: 18,
      },
    ],
  },
  {
    networkName: 'Ethereum',
    chainId: 1,
    networkImage: <EthereumSvg />,
    isForTest: false,
    tokens: [],
  },
  {
    networkName: 'Polygon',
    chainId: 137,
    networkImage: <PolygonSvg />,
    isForTest: false,
    tokens: [],
  },
  {
    networkName: 'Mumbai',
    chainId: 80001,
    networkImage: <PolygonSvg />,
    isForTest: true,
    tokens: [
      {
        route: 'swapp-polygon',
        tokenName: 'SWAPP',
        tokenFullName: 'swapp',
        tokenStatus: 'New',
        tokenSymbol: 'SWAPP',
        decimals: 18,
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0xBDEdd94EE87c54760a795be5cE858e853EF59aE7',
        lotteryAddress: '0x5D0fF5C3edC9B53edAC656C5b9365755e7Bb669d',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/kainarap/mumbaiswapp',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=swapp&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <Swapp {...props} />,
        info:
          'Swapp is a fairly-launched, community-driven DeFi protocol built on the Ethereum blockchain. The Swapp dev team consists of talented business professionals, experienced in blockchain infrastructure and finance. Swapp is proud to be democratizing the data industry and making complex financial products simple and accessible to anyone all.',
      },
      {
        route: 'link-polygon',
        tokenStatus: 'New',
        tokenName: 'CHAINLINK',
        tokenFullName: 'chainlink',
        tokenSymbol: 'LINK',
        decimals: 18,
        tokenColor: '#2ECDFF',
        tokenColorSecond: '#2ecdff33',
        tokenAddress: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
        lotteryAddress: '0x545b0ACC19097a3eE1Ee9589C7c013f6e0280aaB',
        graphUrl: 'https://api.thegraph.com/subgraphs/name/kainarap/mumbailink',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=chainlink&vs_currencies=usd',
        tokenImgUrl: '',
        partner: 'https://test.com',
        Icon: (props) => <ChainLink {...props} />,
        info: '',
      },
      // {
      //     route: 'vault-polygon',
      //     tokenName: 'VaultDeFi',
      //     tokenFullName: 'VaultDeFi',
      //     tokenStatus: 'New',
      //     tokenSymbol: 'VAULT',
      //     decimals: 18,
      //     tokenColor: '#795CED',
      //     tokenColorSecond: '#795ced33',
      //     tokenAddress: '0xFFD3F83783e4517bb58d24040A5c29eC6D069497',
      //     lotteryAddress: '0xaC0b5E19d8d7B4c1983aA13901a19Fc19150cfD3',
      //     graphUrl: 'https://api.thegraph.com/subgraphs/name/koinor/vaultmumbai',
      //     tokenPriceUrl:
      //       'https://api.coingecko.com/api/v3/simple/price?ids=vaultdefi&vs_currencies=usd',
      //     tokenImgUrl: '',
      //     partner: 'https://vaultdefi.com/',
      //     Icon: (props) => <VaultBTC {...props} />,
      //     info: '',
      //   },
    ],
  },
  {
    networkName: 'BSC Test',
    chainId: 97,
    networkImage: <BinanceSvg />,
    isForTest: true,
    tokens: [
      {
        route: 'swapp-bsc',
        tokenName: 'SWAPP',
        tokenFullName: 'SWAPP',
        tokenStatus: 'Hot! 🔥',
        tokenSymbol: 'SWAPP',
        decimals: 18,
        tokenColor: '#795CED',
        tokenColorSecond: '#795ced33',
        tokenAddress: '0x45f3354bb22d65bffddf65a069fa6f456f253c61',
        lotteryAddress: '0x89e7b3E896f14B6e667773B0D5d5387e55fDe826',
        graphUrl: '',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=swapp&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <Swapp {...props} />,
        info:
          'Swapp is a fairly-launched, community-driven DeFi protocol built on the Ethereum blockchain. The Swapp dev team consists of talented business professionals, experienced in blockchain infrastructure and finance. Swapp is proud to be democratizing the data industry and making complex financial products simple and accessible to anyone all.',
      },
      {
        route: 'link-bsc',
        tokenName: 'ChainLink',
        tokenFullName: 'ChainLink',
        tokenStatus: 'Hot! 🔥',
        tokenSymbol: 'LINK',
        decimals: 18,
        tokenColor: '#2ECDFF',
        tokenColorSecond: '#2ecdff33',
        tokenAddress: '0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06',
        lotteryAddress: '0x6Aa2218B3d2F02817Daab5f2B86B95AB43371c03',
        graphUrl: '',
        tokenPriceUrl:
          'https://api.coingecko.com/api/v3/simple/price?ids=chainlink&vs_currencies=usd',
        tokenImgUrl: '',
        partner: '',
        Icon: (props) => <ChainLink {...props} />,
        info: '',
      },
    ],
  },
  {
    networkName: 'Cronos',
    chainId: 338,
    networkImage: <CronosSvg />,
    isForTest: false,
    tokens: [],
  },
];

export const menuConfig = menuConfigAll.filter((config) => {
  return config.isForTest === isTest;
});
