import React from 'react';
import { ModalProps } from 'antd/lib/modal';

import { WalletConnector } from 'wallets/types';
import { useWallet, WalletConnectors } from 'wallets/wallet';

import LedgerDerivationPathModal from 'components/modals/ledger-deriviation-path-modal';

import s from './styles.module.css';
import ModalHeader from '../components/modal-header';
import { Button, Modal } from 'antd';

export type ConnectWalletModalProps = ModalProps & {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ModalFooter = ({ link = '#' }) => (
  <div className={s.modalFooter}>
    <p>Haven’t got a crypto wallet yet?</p>
    <a href={link} className={s.footerLink}>Learn How to Connect</a>
  </div>
);

const ConnectWalletModal: React.FunctionComponent<ConnectWalletModalProps> = (
  props,
) => {
  const { ...modalProps } = props;

  const wallet = useWallet();

  const [ledgerModal, setLedgerModal] = React.useState<boolean>(false);

  function handleConnectorSelect(connector: WalletConnector) {
    if (wallet.isActive) {
      return;
    }

    if (connector.id === 'ledger') {
      return setLedgerModal(true);
    }

    return wallet.connect(connector);
  }

  return (
    <>
      <Modal
        className={s.component}
        centered
        closable
        footer={null}
        {...modalProps}
      >
        <ModalHeader title={'Connect Wallet'} />

        <div className={s.connectorList}>
          {WalletConnectors.map((connector) => (
            <div className={s.connectorItem} key={connector.id}>
              <Button
                className={s.connectorBtn}
                type='ghost'
                onClick={() => handleConnectorSelect(connector)}
              >
                <img
                  src={connector.logo}
                  alt={connector.name}
                  className={s.connectorLogo}
                />
                <span className={s.connectorName}>{connector.name}</span>
              </Button>
            </div>
          ))}
        </div>
      </Modal>
      <LedgerDerivationPathModal
        visible={ledgerModal}
        onCancel={() => setLedgerModal(false)}
      />
    </>
  );
};

export default ConnectWalletModal;
