import React, { useCallback, useEffect, useState } from 'react';
import { useWallet } from 'wallets/wallet';

import k from './styles.module.css';
import { LotteriesWhere } from '../../../check-prizes/interfaces';
import { getAllTickets } from '../../../check-prizes/get-all-tickets';
import { LotteryTicketClaimData, RawTicketsResponse } from 'interfaces/lottery';
import { useLottery } from 'context/useLottery';
import HistoryWrapper from '../../../history-wrapper';
import LotteryInfo from '../lottery-info';
import ClaimPrizeModal from 'components/modals/claime-prize-modal';
import { useGetLotteryInfo } from 'hooks/useGetLotteryInfo';

export type WinningNumbersProps = {
  updateData: Function;
};

export type LotteryData = {
  finalNumber: string;
  events: RawTicketsResponse;
  winningTickets: LotteryTicketClaimData | null;
};

export type LotteriesInfo = {
  [id: string]: LotteryData | undefined;
};

const LotteryHistory: React.FunctionComponent<WinningNumbersProps> = ({ updateData }) => {
  const { account } = useWallet();
  const {
    token,
    contractLottery,
  } = useLottery();

  const { graphUrl } = token

  const getLotteryInfo = useGetLotteryInfo();

  const [userLotteries, setUserLotteries] = useState<any>([]);
  const [lotteriesInfo, setLotteriesInfo] = useState<LotteriesInfo>({});

  const [unclaimed, setUnclaimed] = useState<LotteryTicketClaimData[] | null>(
    null,
  );

  const getLotteriesHistory = useCallback(async () => {
    let first = 100;
    let skip = 0;
    let where: LotteriesWhere = {};
    let user;

    const blankUser = {
      account,
      totalCake: '',
      totalTickets: '',
      rounds: [],
    };

    try {
      if (graphUrl) {
        const { user: userRes } = await getAllTickets({
          account: account!.toLowerCase(),
          first,
          skip,
          where,
          url: graphUrl,
        });

        // If no user returned - return blank user
        if (!userRes) {
          user = blankUser;
        } else {
          user = {
            account: userRes.id,
            totalCake: userRes.totalCake,
            totalTickets: userRes.totalTickets,
            rounds: userRes.rounds.map((round: any) => {
              return {
                lotteryId: round?.lottery?.id,
                endTime: round?.lottery?.endTime,
                claimed: round?.claimed,
                totalTickets: round?.totalTickets,
                status: round?.lottery?.status.toLowerCase(),
              };
            }),
          };
        }
        user.rounds.sort((a: any, b: any) => {
          return b.lotteryId - a.lotteryId;
        });
        setLotteriesInfo({});
        setUserLotteries(user);
      }
    } catch (error) {
      setLotteriesInfo({});
      setUserLotteries({});
      console.log('error', error);
    }
  }, [graphUrl, account]);


  useEffect(() => {
    setUserLotteries({})
    if (account && contractLottery?.methods && token) getLotteriesHistory().catch();
  }, [account, getLotteriesHistory, token,contractLottery?.methods]);

  const getLotteryInfoOnPress = useCallback(
    async (id: string) => {
      try {
        const { finalNumber, events, winningTickets } = await getLotteryInfo({
          lotteryId: id,
        });

        setLotteriesInfo((prevState) => ({
          ...prevState,
          [id]: {
            finalNumber,
            events,
            winningTickets,
          },
        }));

      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    [getLotteryInfo],
  );

  const claimUnclaimedRewards = useCallback(
    (id: string) => {
      const winningTickets = lotteriesInfo[id]?.winningTickets ?? null;
      setUnclaimed(winningTickets ? [winningTickets] : null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lotteriesInfo, updateData],
  );

  const closeClaimModal = () => setUnclaimed(null);

  return (
    <>
      <HistoryWrapper>
        <HistoryWrapper.Header>
          <h4 className={k.title}>Your History</h4>
        </HistoryWrapper.Header>
        <HistoryWrapper.Body>
          <div>
            <table className={k.table}>
              <thead className={k.tableHead}>
              <tr>
                <th>
                  <small>Round</small>
                </th>
                <th>
                  <small>Date</small>
                </th>
                <th>
                  <small>Your tickets</small>
                </th>
              </tr>
              </thead>
            </table>

            {userLotteries.rounds?.map((lottery: any, index: number) => (
              <LotteryInfo
                preload={index === 0}
                key={lottery.endTime}
                lottery={lottery}
                unclaimed={unclaimed}
                lotteryData={lotteriesInfo[lottery.lotteryId]}
                getLotteryInfoOnPress={getLotteryInfoOnPress}
                claimUnclaimedRewards={claimUnclaimedRewards}
              />
            ))}
          </div>
        </HistoryWrapper.Body>
      </HistoryWrapper>

      <ClaimPrizeModal
        visible={!!unclaimed?.length}
        roundsToClaim={unclaimed ?? []}
        onSuccess={closeClaimModal}
        onCancel={closeClaimModal}
      />
    </>
  );
};

export default LotteryHistory;
