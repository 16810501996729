import k from './styles.module.css';
import { FC } from 'react';

const CardWrap: FC = ({ children }) => {
  return (
    <div className={k.cardBody}>
      {children}
    </div>
  );
};

export default CardWrap;
