import classes from './styles.module.css';
import { FC } from 'react';
import { socialLinksIcons } from '../../constants';
import cx from 'classnames';
import { SocialLink } from 'interfaces/lottery';

type LinkKey = keyof typeof socialLinksIcons;


export interface SocialLinksProps {
  className?: string;
  links: SocialLink[];
}


const SocialLinks: FC<SocialLinksProps> = ({ className = '', links = [] }) => {
  return (
    <div className={cx(classes.socials, className)}>
      {
        links.map(({ id, link }) => (
          /* temporarily disabled links w/o icon */
          link && socialLinksIcons[id.toLowerCase() as LinkKey] && (
            <a key={id} className={classes.socialLink} href={link} title={id}
               target='_blank' rel='noopener noreferrer nofollow'
            >
              {socialLinksIcons[id.toLowerCase() as LinkKey] ?? <></>}
            </a>
          )))
      }
    </div>
  );
};

export default SocialLinks;
