import React, { useCallback, useMemo, useState } from 'react';
import { ModalProps } from 'antd/lib/modal';

import s from './styles.module.css';
import { Modal } from 'antd';
import Button from 'components/themed-button';
import ModalHeader from '../components/modal-header';
import ModalBody from '../components/modal-body';
import { menuConfig } from '../../../constants';
import { switchChain } from 'functions/switch-chain';
import { useWallet } from 'wallets/wallet';

export type UnsupportedChainModalProps = ModalProps & {};

declare let window: any;

const UnsupportedChainModal: React.FunctionComponent<UnsupportedChainModalProps> =
  ({ visible, onCancel }) => {

    const wallet = useWallet();

    const [loading, setLoading] = useState(false);
    const networks = useMemo(() => menuConfig.filter(({ tokens }) => tokens.length), []);

    const changeProtocol = useCallback(async (chainId: number, e: React.MouseEvent<HTMLElement>) => {

      try {
        setLoading(true);

        await switchChain(chainId);
        const newChainId = await window.ethereum.request({ method: 'eth_chainId' });

        if (+newChainId === chainId) {
          if (typeof onCancel === 'function') onCancel(e);

          wallet.showWalletsModal();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet]);


    return (
      <Modal
        centered
        closable={false}
        footer={null}
        visible={visible}
        onCancel={onCancel}
      >

        <ModalHeader title={'Wrong network'} />
        <ModalBody>

          <h4
            className={s.text}>
            Please switch your wallet network to one of next networks:
          </h4>

          <div className={s.buttons}>
            {
              networks.map(({ chainId, networkName }) => (
                <Button
                  key={chainId + networkName}
                  primary
                  fullWidth
                  value={chainId}
                  onClick={(e) => changeProtocol(chainId, e)}
                  disabled={loading}
                >
                  {networkName}
                </Button>
              ))
            }
          </div>
        </ModalBody>
      </Modal>
    );
  };

export default UnsupportedChainModal;
