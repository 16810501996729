import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import HeroSection from './components/hero';
import WinningNumbers from './components/winning-numbers';
import CheckPrizesSection from './components/check-prizes';
import { useLottery } from 'context/useLottery';
import { LotteryViewParams } from 'hooks/useContractProps';
import LotteryHistory from './components/winning-numbers/components/history';
import InfoSection from 'components/info-section';
import Button from 'components/themed-button';

import classes from './styles.module.css';

import { ReactComponent as FLSvg } from 'resources/svg/static/first-left.svg';
import { ReactComponent as FRSvg } from 'resources/svg/static/first-right.svg';
import { ReactComponent as SRSvg } from 'resources/svg/static/second-right.svg';
import mobile from 'resources/images/mobile.png';
import appStoreSoon from 'resources/images/mobile/app soon.png';
import playSoon from 'resources/images/mobile/play soon.png';

import cx from 'classnames';
import links from 'config/links';
import paths from "../../constants/paths";

interface Props {
  topinfo?: boolean;
  history?: boolean;
  winner?: boolean;
  isInfo?: boolean;
}

const LotteryView: React.FunctionComponent<Props> = (props) => {
  const {
    topinfo = true,
    history = true,
    winner = true,
    isInfo = true }= props;
  const params = useParams<LotteryViewParams>();
  const [updateData, setUpdateData] = useState<boolean>(false);

  const { push } = useHistory();

  const {
    lotteryPaths,
    token: { tokenName, info },
  } = useLottery();
  const { lotteryPath } = params ?? {};

  // redirect back if random path was entered
  useEffect(() => {
    if (
      lotteryPaths &&
      lotteryPath &&
      !lotteryPaths.includes(`/${lotteryPath}`)
    ) {
      push(paths.DASHBOARD);
    }
  }, [lotteryPath, push, lotteryPaths]);

    const updateDataHandler = useCallback(() => setUpdateData(true), [])

    return (
    <>
      <div className={classes.root}>
        {topinfo && <WinningNumbers />}
        <HeroSection
          getUpdatedData={updateDataHandler}
          updatedData={updateData}
        />
        {history && <LotteryHistory updateData={updateDataHandler} />}
      </div>
      {winner && <CheckPrizesSection updateData={updateDataHandler} />}
      {tokenName && info && isInfo && (
        <>
          <InfoSection title={`About ${tokenName}`}>
            <InfoSection.ContainedContent>
              {Array.isArray(info) ? (
                info.map((i, ind) => (
                  <p key={`${ind}`} className={classes.info}>
                    {i}
                  </p>
                ))
              ) : (
                <p className={classes.info}>{info}</p>
              )}
            </InfoSection.ContainedContent>
            <InfoSection.ContainedContent>
              <div className={classes.infoLinks}>
                <Button
                  primary type={'link'}
                  href={links.joinWaitList}
                  target={'_blank'} rel={'nofollow noreferrer'}>
                  Join Waitlist
                </Button>
                <Button type={'link'} href={links.learnMore}
                        target={'_blank'} rel={'nofollow noreferrer'}>
                  Learn More
                </Button>
              </div>
            </InfoSection.ContainedContent>
          </InfoSection>
          <InfoSection
            title={
              <span>
                Download <span className={classes.themed}>Swapp ID</span> mobile
                app
              </span>
            }
          >
            <InfoSection.ContainedContent>
              <p className={classes.info}>
                The Swapp app plays a pivotal role within the Swapp ecosystem:
                (i.) It connects you directly to the companies that want to buy
                your data; (ii.) It allows you to protect your data if you don’t
                want it being sold; and (iii.) It allows you to sell/monetize
                your data to earn Swapp tokens;
              </p>
            </InfoSection.ContainedContent>
            <InfoSection.ContainedContent>
              <div className={classes.infoLinks}>
                <Button type={'link'} href={'#'} disabled>
                  <img className={classes.soon} loading={'lazy'} src={appStoreSoon} alt='app store coming soon' />
                </Button>
                <Button type={'link'} href={'#'} disabled>
                  <img className={classes.soon} loading={'lazy'} src={playSoon} alt='play market coming soon' />
                </Button>
              </div>
            </InfoSection.ContainedContent>

            <InfoSection.FlowContent>
              <div className={classes.imageBlock}>
                <div className={classes.left}>
                  <div>
                    <h3 className={classes.themed}>
                      Refer & Earn
                      <FLSvg aria-hidden className={classes.svg} />
                    </h3>
                    <small>
                      Invite your friends with a $5 gift and get 50 Swapps on
                      your wallet account
                    </small>
                  </div>
                </div>

                <img src={mobile} alt='mobile app sample' />

                <div className={classes.right}>
                  <div>
                    <h3>
                      Monetize
                      <FRSvg aria-hidden className={classes.svg} />
                    </h3>
                    <small>
                      The Swapp platform allows users to stake their Swapp
                      tokens, locking them up for a period of time, in order to
                      earn interest in the form of Swapp rewards.
                    </small>
                  </div>

                  <div>
                    <h3>
                      Protect
                      <SRSvg
                        aria-hidden
                        className={cx(classes.svg, classes.svgRev)}
                      />
                    </h3>
                    <small>
                      Our app connects directly to brands that want to buy your
                      data. We protect your data if you don’t want it to be
                      sold!
                    </small>
                  </div>
                </div>
              </div>
            </InfoSection.FlowContent>
          </InfoSection>
        </>
      )}
    </>
  );
};

export default LotteryView;
