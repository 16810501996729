import React, { useCallback, useMemo, useRef } from 'react';
import { useSessionStorage } from 'react-use-storage';
import {
  UnsupportedChainIdError,
  useWeb3React,
  Web3ReactProvider,
} from '@web3-react/core';
import { NoEthereumProviderError } from '@web3-react/injected-connector';
import { Web3Provider } from '@ethersproject/providers';

import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useRefState } from 'hooks/useRefState';

import { getNetworkName } from 'web3/utils';
import { WalletConnector } from 'wallets/types';
import { CoinbaseWalletConfig } from 'wallets/connectors/coinbase';
import { LedgerWalletConfig } from 'wallets/connectors/ledger';
import { MetaMaskWalletConfig } from 'wallets/connectors/metamask';
import { PortisWalletConfig } from 'wallets/connectors/portis';
import { TrezorWalletConfig } from 'wallets/connectors/trezor';
import { WalletConnectConfig } from 'wallets/connectors/wallet-connect';

import ConnectWalletModal from 'components/modals/connect-wallet-modal';
import InstallMetaMaskModal from 'components/modals/install-metamask-modal';
import UnsupportedChainModal from 'components/modals/unsupported-chain-modal';
import { menuConfig, refreshTokenInterval } from '../constants';
import { login, logout, getSignature, refreshToken as origRefreshToken } from '../services/auth.service';
import axios, { CancelTokenSource } from 'axios';
import { notification } from 'antd';

const WEB3_POLLING_INTERVAL = Number(
  process.env.REACT_APP_WEB3_POLLING_INTERVAL,
);

declare let window: any;

export const WalletConnectors: WalletConnector[] = [
  MetaMaskWalletConfig,
  WalletConnectConfig,
  // LedgerWalletConfig,
  // TrezorWalletConfig,
  // CoinbaseWalletConfig,
  // PortisWalletConfig,
];

type WalletData = {
  connecting?: WalletConnector;
  isActive: boolean;
  account?: string;
  networkId?: number;
  networkName?: string;
  connector?: WalletConnector;
  provider?: any;
};

export type Wallet = WalletData & {
  showWalletsModal: () => void;
  connect: (
    connector: WalletConnector,
    args?: Record<string, any>,
  ) => Promise<void>;
  disconnect: () => void;
};

const WalletContext = React.createContext<Wallet>({
  connecting: undefined,
  isActive: false,
  account: undefined,
  networkId: undefined,
  networkName: undefined,
  connector: undefined,
  provider: undefined,
  showWalletsModal: () => undefined,
  connect: () => Promise.reject(),
  disconnect: () => undefined,
});

export function useWallet(): Wallet {
  return React.useContext(WalletContext);
}

// const chainIds = ["1", "56", "137"];
const chainIdsTest = ['3', '97', '80001'];

let refreshTokenIntervalId: NodeJS.Timeout | null = null;

const WalletProvider: React.FunctionComponent = (props) => {
  const web3React = useWeb3React();

  const [sessionProvider, setSessionProvider, removeSessionProvider] =
    useSessionStorage<string | undefined>('wallet_provider');

  const [connecting, setConnecting, connectingRef] = useRefState<WalletConnector | undefined>(undefined);
  const [activeConnector, setActiveConnector] = React.useState<WalletConnector | undefined>(undefined);
  const [activeProvider, setActiveProvider] = React.useState<any | undefined>(
    undefined,
  );

  const chainIds = useMemo(() => menuConfig.map(({ chainId }) => chainId.toString()), []);

  const [walletsModal, setWalletsModal] = React.useState<boolean>(false);
  const [unsupportedChainModal, setUnsupportedChainModal] =
    React.useState<boolean>(false);
  const [installMetaMaskModal, setInstallMetaMaskModal] =
    React.useState<boolean>(false);

  const refreshCancelToken = useRef<CancelTokenSource>();
  const signatureCancelToken = useRef<CancelTokenSource>();

  const refreshToken = useCallback(() => {
    refreshCancelToken.current?.cancel();
    const source = axios.CancelToken.source();
    refreshCancelToken.current = source;
    return origRefreshToken(source.token);
  }, []);

  const checkChainIds = useCallback(async (walletConnector: WalletConnector) => {
    const currentId = (await window.ethereum)
      ? window.ethereum.networkVersion!
      : undefined;
    const WEB3_CHAIN_ID = Number(process.env.REACT_APP_WEB3_CHAIN_ID);
    const chainsArray = WEB3_CHAIN_ID === 1 ? chainIds : chainIdsTest;

    if (walletConnector.id === 'metamask') {
      return chainsArray.map(chain => +chain);
    } else {
      if (chainsArray.includes(currentId)) return +currentId;
      return Number(process.env.REACT_APP_WEB3_CHAIN_ID);
    }
  }, [chainIds]);

  const disconnect = React.useCallback(() => {
    web3React.deactivate();
    activeConnector?.onDisconnect?.(web3React.connector);
    setConnecting(undefined);
    setActiveConnector(undefined);
    setActiveProvider(undefined);
    removeSessionProvider();
    logout();
    if (refreshTokenIntervalId) {
      clearInterval(refreshTokenIntervalId);
    }
  }, [web3React, activeConnector, removeSessionProvider, setConnecting]);

  const connect = React.useCallback(
    async (
      walletConnector: WalletConnector,
      args?: Record<string, any>,
    ): Promise<void> => {
      if (connectingRef.current) {
        return;
      }

      connectingRef.current = walletConnector;
      setConnecting(walletConnector);
      setWalletsModal(false);

      const chainId = await checkChainIds(walletConnector);
      const connector = walletConnector.factory(chainId, args);

      function onError(error: Error) {
        console.error('Wallet::Connect().onError', { error });

        if (error instanceof NoEthereumProviderError) {
          setInstallMetaMaskModal(true);
          disconnect();
        } else if (error instanceof UnsupportedChainIdError) {
          setUnsupportedChainModal(true);
          disconnect();
        } else {
          const err = walletConnector.onError?.(error);

          if (err) {
            notification.error({
              message: err.message,
            });
          }
        }
      }

      function onSuccess() {

        if (!connectingRef.current) {
          return;
        }
        walletConnector.onConnect?.(connector, args);
        connector.getProvider().then(setActiveProvider);
        setActiveConnector(walletConnector);
        setSessionProvider(walletConnector.id);
      }

      await web3React
        .activate(connector, undefined, true)
        .then(onSuccess)
        .catch(e => {
          console.log(e);
          onError(e);
        });

      refreshTokenIntervalId = setInterval(async () => {
        await refreshToken();
        refreshCancelToken.current = undefined;
      }, refreshTokenInterval);
      setConnecting(undefined);
    },
    [checkChainIds, web3React, connectingRef, setConnecting, setSessionProvider, disconnect, refreshToken],
  );

  useAsyncEffect(async () => {
    if (sessionProvider) {
      const walletConnector = WalletConnectors.find(
        (c) => c.id === sessionProvider,
      );

      if (walletConnector) {
        await connect(walletConnector);
      }
    }

  }, []);

const showWalletsModal = useCallback(() => setWalletsModal(true), [])

  const value = React.useMemo<Wallet>(
    () => ({
      connecting,
      isActive: web3React.active,
      account: web3React.account ?? undefined,
      // account: '0x89cF63F79BB70ff8A3A2b874120B0Ebe69e57430',
      networkId: web3React.chainId,
      networkName: getNetworkName(web3React.chainId),
      connector: activeConnector,
      provider: activeProvider,
      showWalletsModal,
      connect,
      disconnect,
    }),
    [
      web3React,
      connecting,
      activeConnector,
      activeProvider,
      disconnect,
      connect,
      showWalletsModal
    ],
  );

  const web3login = async () => {
    if (web3React.account) {
      const user = localStorage.getItem('user');

      try {
        if (user && web3React.account.toLowerCase() !== JSON.parse(user).address.toLowerCase()) {
          await logout();
        }
      } catch (error) {
        console.log({ error });
        await logout();
      }
      let accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        try {
          const refreshTokenData = await refreshToken();
          if (!refreshTokenData) {
            accessToken = null;
          }
        } catch (error) {
          console.log({ error });
          await logout();
          accessToken = null;
        } finally {
          refreshCancelToken.current = undefined;
        }
      }
      let message = localStorage.getItem('signatureMessage');
      let expiredDate = localStorage.getItem('signatureMessageExpired');
      if (!(message && expiredDate && +expiredDate > Date.now())) {


        signatureCancelToken.current?.cancel();

        const source = axios.CancelToken.source();
        signatureCancelToken.current = source;

        const signatureData = await getSignature(web3React.account, source.token);
        // clear old token
        signatureCancelToken.current = undefined;

        message = signatureData.message;
        expiredDate = signatureData.expiredDate.toString();
      }

      if (!(accessToken && user)) {
        const signMessage = localStorage.getItem('signMessage');
        if (message && expiredDate && !signMessage) {
          localStorage.setItem('signMessage', '1');
          try {
            const signature = await web3React.library.send('personal_sign', [message, web3React.account]);
            await login(web3React.account, signature, +expiredDate);
          } catch (error) {
            console.log({ error });
            await logout();
            disconnect();
          }
          localStorage.removeItem('signMessage');
        }
      }
    }
  };

  web3login();

  const closeWalletModal = useCallback(() => setWalletsModal(false), [])
  const closeInstallMetaMaskModal = useCallback(() => setInstallMetaMaskModal(false), [])
  const closeUnsupportedChainModal = useCallback(() => setUnsupportedChainModal(false), [])

  return (
    <WalletContext.Provider value={value}>
      <ConnectWalletModal
        visible={walletsModal}
        onCancel={closeWalletModal}
      />
      <InstallMetaMaskModal
        visible={installMetaMaskModal}
        onCancel={closeInstallMetaMaskModal}
      />
      <UnsupportedChainModal
        visible={unsupportedChainModal}
        onCancel={closeUnsupportedChainModal}
      />

      {props.children}
    </WalletContext.Provider>
  );
};

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  library.pollingInterval = WEB3_POLLING_INTERVAL;
  return library;
}

const Web3WalletProvider: React.FunctionComponent = (props) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <WalletProvider>{props.children}</WalletProvider>
    </Web3ReactProvider>
  );
};

export default Web3WalletProvider;
