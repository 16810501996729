import { socialLinks } from '../../constants';
import SocialLinks, { SocialLinksProps } from './index';
import { FC } from 'react';


const SocialContacts:FC<Omit<SocialLinksProps, 'links'>> = (props) => (
  <SocialLinks links={socialLinks} {...props} />
);

export default SocialContacts;
