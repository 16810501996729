import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useWallet } from 'wallets/wallet';
import { FC } from 'react';


interface Props extends RouteProps {
  redirectLink?: string;
}

const ProtectedRoute: FC<Props> = ({ component: Component, redirectLink, ...rest }) => {

  const { account, connecting } = useWallet();


  if (!Component) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return !!account ? (
          <Component {...props} />
        ) : (
          connecting !== undefined ?
            <Redirect
              to={{
                pathname: redirectLink,
                /**
                 * For redirecting after login.
                 */
                state: { prevPath: props.location.pathname },
              }}
            />
            : null
        );
      }} />
  );
};

export default ProtectedRoute;
