import { SocialLink, Token } from '../interfaces/lottery';

export type TokenLinks = Record<Token['tokenName'], SocialLink[]>

export const tokenLinks: TokenLinks = {
  WBNB: [
    {
      id: 'Telegram',
      link: 'https://t.co/FBHrFld1tw?amp=1',
    },
    {
      id: 'Twitter',
      link: 'https://twitter.com/BinanceChain',
    },
    {
      id: 'Facebook',
      link: '',
    },
    {
      id: 'Instagram',
      link: '',
    },
    {
      id: 'Discord',
      link: 'https://discord.com/invite/YPDsUqcwR8',
    },
    {
      id: 'BSC scan',
      link: 'https://bscscan.com/token/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/wbnb',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/wbnb/',
    },
    {
      id: 'Website',
      link: 'binance.org',
    },
    {
      id: 'Blog',
      link: 'https://www.binance.org/en/blog/',
    },
  ],
  SWAPP: [
    {
      id: 'Telegram',
      link: 'https://t.me/SwappToken',
    },
    {
      id: 'Twitter',
      link: 'https://twitter.com/swappfi',
    },
    {
      id: 'Facebook',
      link: 'https://facebook.com/swappfi',
    },
    {
      id: 'Instagram',
      link: 'https://instagram.com/swappfi',
    },
    {
      id: 'Discord',
      link: 'https://discord.com/invite/e6cW4XAjUP',
    },
    {
      id: 'BSC scan',
      link: 'https://bscscan.com/token/0x0efE961C733FF46ce34C56a73eba0c6a0E18E0F5',
    },
    {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/swapp-protocol',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/swapp-protocol/',
    },
    {
      id: 'Website',
      link: 'https://swapp.ee',
    },
    {
      id: 'Blog',
      link: 'https://blog.swapp.ee/',
    },
  ],
  FLOKI: [
    {
      id: 'Telegram',
      link: 'https://t.me/FlokiInuToken',
    },
    {
      id: 'Twitter',
      link: 'https://twitter.com/realflokiinu',
    },
    {
      id: 'Facebook',
      link: '',
    },
    {
      id: 'Instagram',
      link: '',
    },
    {
      id: 'Discord',
      link: '',
    },
    {
      id: 'BSC scan',
      link: 'https://bscscan.com/token/0x2b3f34e9d4b127797ce6244ea341a83733ddd6e4',
    },
    {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/floki-inu',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/floki-inu/',
    },
    {
      id: 'Website',
      link: ' https://theflokiinu.com/',
    },
    {
      id: 'Blog',
      link: 'https://t.me/FlokiInuToken',
    },
  ],
  HIBIKI: [
    {
      id: 'Telegram',
      link: 'https://t.me/hibikifinance',
    },
    {
      id: 'Twitter',
      link: 'https://twitter.com/HibikiFinance',
    },
    {
      id: 'Facebook',
      link: '',
    },
    {
      id: 'Instagram',
      link: '',
    },
    {
      id: 'Discord',
      link: '',
    },
    {
      id: 'BSC scan',
      link: ' https://bscscan.com/token/0xa532cfaa916c465a094daf29fea07a13e41e5b36',
    },
    {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/hibiki-finance',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/hibiki-finance/',
    },
    {
      id: 'Website',
      link: 'https://hibiki.finance/',
    },
    {
      id: 'Blog',
      link: '',
    },
  ],
  VAULT: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0x92da405b6771c9Caa7988A41dd969a73d10A3cc6',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/vaultdefi',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/vaultdefi/',
    },
    {
      id: 'Website',
      link: 'https://vaultdefi.com/',
    },
    {
      id: 'Telegram',
      link: ' https://t.me/Vault_DeFi',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/VaultDeFi',
    }, {
      id: 'Blog',
      link: '',
    },
    {
      id: 'Discord',
      link: 'https://discord.gg/vaultdefi',
    },
  ],

  ETH: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/ethereum/',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/ethereum/',
    },
    {
      id: 'Website',
      link: 'https://ethereum.org/',
    },
    {
      id: 'Telegram',
      link: '',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/ethereum',
    }, {
      id: 'Blog',
      link: '',
    },
    {
      id: 'Discord',
      link: '',
    },
  ],

  USDT: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/tether/',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/tether/',
    },
    {
      id: 'Website',
      link: 'https://tether.to/',
    },
    {
      id: 'Telegram',
      link: '',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/Tether_to',
    }, {
      id: 'Blog',
      link: '',
    },
    {
      id: 'Discord',
      link: '',
    },
  ],

  BUSD: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/binance-usd/',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/binance-usd/',
    },
    {
      id: 'Website',
      link: 'https://www.paxos.com/busd/',
    },
    {
      id: 'Telegram',
      link: 'https://t.co/FBHrFld1tw?amp=11',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/BinanceChain',
    }, {
      id: 'Blog',
      link: 'https://www.binance.org/en/blog/',
    },
    {
      id: 'Discord',
      link: 'https://discord.com/invite/YPDsUqcwR8',
    },
  ],

  MATIC: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/matic-network/',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/polygon/',
    },
    {
      id: 'Website',
      link: 'https://polygon.technology/',
    },
    {
      id: 'Telegram',
      link: 'https://t.me/polygonofficial',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/0xPolygon',
    }, {
      id: 'Blog',
      link: '',
    },
    {
      id: 'Discord',
      link: '',
    },
  ],

  APESWAP: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/apeswap-finance ',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/apeswap-finance ',
    },
    {
      id: 'Website',
      link: 'apeswap.finance',
    },
    {
      id: 'Telegram',
      link: 'https://t.me/ape_swap',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/ape_swap',
    }, {
      id: 'Blog',
      link: 'https://ape-swap.medium.com/',
    },
    {
      id: 'Discord',
      link: '',
    },
  ],

  ANYSWAP: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/anyswap',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/anyswap/',
    },
    {
      id: 'Website',
      link: 'https://anyswap.exchange/',
    },
    {
      id: 'Telegram',
      link: 'https://t.me/anyswap',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/AnyswapNetwork',
    }, {
      id: 'Blog',
      link: 'https://medium.com/@anyswap',
    },
    {
      id: 'Discord',
      link: '',
    },
  ],

  DOGE: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0xba2ae424d960c26247dd6c32edc70b295c744c43',
    }, {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/binance-peg-dogecoin#markets',
    }, {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/dogecoin/',
    },
    {
      id: 'Website',
      link: 'https://dogecoin.com/',
    },
    {
      id: 'Telegram',
      link: '',
    }, {
      id: 'Twitter',
      link: 'https://twitter.com/dogecoin',
    }, {
      id: 'Blog',
      link: 'https://discuss.dogecoin.com/',
    },
    {
      id: 'Discord',
      link: '',
    },
  ],

  /*FIXME*/
  FarmerDoge: [
    {
      id: 'BSC Scan',
      link: '',
    },
    {
      id: 'Coingeko',
      link: '',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/farmerdoge/',
    },
    {
      id: 'Website',
      link: 'https://www.farmerdoge.net/',
    },
  ],
  BFK: [
    {
      id: 'BSC Scan',
      link: '',
    },
    {
      id: 'Coingeko',
      link: '',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/bfk-warzone/',
    },
    {
      id: 'Website',
      link: 'https://www.bfkwarzone.com/',
    },
  ],
  /*FIXME*/
  EverRise: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0xc7d43f2b51f44f09fbb8a691a0451e8ffcf36c0a',
    },
    {
      id: 'Coingeko',
      link: '',
    },
    {
      id: 'CMC',
      link: '',
    },
    {
      id: 'Website',
      link: '',
    },
  ],
  CKT: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
    },
    {
      id: 'Coingeko',
      link: '',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/certik/',
    },
    {
      id: 'Website',
      link: 'https://certik.org/',
    },
  ],
  SAFEMOON: [
    {
      id: 'BSC Scan',
      link: '',
    },
    {
      id: 'Coingeko',
      link: 'https://www.coingecko.com/en/coins/safemoon',
    },
    {
      id: 'CMC',
      link: 'https://coinmarketcap.com/currencies/safemoon/',
    },
    {
      id: 'Website',
      link: 'safemoon.net',
    },
  ],
  BTC: [
    {
      id: 'BSC Scan',
      link: 'https://bscscan.com/token/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    {
      id: 'Coingeko',
      link: '',
    },
    {
      id: 'CMC',
      link: '',
    },
    {
      id: 'Website',
      link: '',
    },
  ],
};


