import React, { FC } from 'react';
import { ModalProps } from "antd/lib/modal";

import { useWallet } from "wallets/wallet";
import { LedgerWalletConfig } from "wallets/connectors/ledger";

import Dropdown from "components/dropdown";

import s from "./styles.module.css";
import { Button, Modal } from 'antd';

const WEB3_LEDGER_DERIVATION_PATHS = [
  {
    value: `m/44'/60'/0'`,
    label: `Ethereum - m/44'/60'/0'`,
  },
  {
    value: `m/44'/60'/0'/0`,
    label: `Ethereum - Ledger Live - m/44'/60'/0'/0`,
  },
];

export type LedgerDerivationPathModalProps = ModalProps & {};

const LedgerDerivationPathModal: FC<LedgerDerivationPathModalProps> = ({ visible , onCancel}) => {

    const wallet = useWallet();

    const [derivationPath, setDerivationPath] = React.useState<string>(
      WEB3_LEDGER_DERIVATION_PATHS[0].value
    );

    function handleSelect(value: string | number) {
      setDerivationPath(String(value));
    }

    function handleConnect(ev: React.MouseEvent<HTMLElement>) {
      onCancel?.(ev);

      return wallet.connect(LedgerWalletConfig, {
        baseDerivationPath: derivationPath,
      });
    }

    return (
      <Modal className={s.component} centered footer={null} visible={visible} onCancel={onCancel}>
        <Dropdown
          className={s.dropdown}
          items={WEB3_LEDGER_DERIVATION_PATHS}
          selected={derivationPath}
          onSelect={handleSelect}
        />
        <Button
          type="primary"
          className={s.connectBtn}
          onClick={handleConnect}
        >
          Connect
        </Button>
      </Modal>
    );
  };

export default LedgerDerivationPathModal;
