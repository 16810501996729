import { getHumanValue } from '../web3/utils';
import BigNumber from 'bignumber.js';
import { Exchange } from '../context/useCurrency';
import { menuConfig } from '../constants';
import memoize from 'lodash/memoize';
import { UserDto } from '../interfaces/user.dto';

export const getTokenAddressesDecimals = memoize(() => Object.fromEntries(
  getTokensDecimals().map(({ lotteryAddress, decimals }) => [lotteryAddress, decimals]),
));
export const getTokenSymbolDecimals = memoize(() => Object.fromEntries(
  getTokensDecimals().map(({ tokenSymbol, decimals }) => [tokenSymbol, decimals]),
));

export const getTokensDecimals = memoize(() => menuConfig
  .filter(({ tokens }) => tokens.length)
  .map(({ tokens }) => tokens)
  .flat(),
);


export const convertToHumanValues = (valuesArray: any[], lotteryAddress: string) => {
  let values = 0;
  const tokenObj = getTokenAddressesDecimals();

  const decimals = tokenObj[lotteryAddress] ?? 18 /* default value */;

  valuesArray.forEach((value: any) => {
    values += +getHumanValue(new BigNumber(value), decimals)!;
  });
  return values;
};

export const getRandomNumberString = (length: number = 6, start: number = 10): string => {
  const end = start + length;
  return Math.random().toString(10).slice(start, end);
};

export const exchangeUsdValue = (exchange: Exchange, currency: string | undefined) => exchange?.[(currency ?? 0) as keyof Exchange]?.usd;

export const getDecimalsBySymbol = (symbol: string) => {

  const tokenSymbols = getTokenSymbolDecimals();
  return tokenSymbols[symbol];

};

export const nameInitials = (user: UserDto | null): string => {
  if (!user || !user.id) {
    return '';
  } else {
    return `${user.firstName?.[0] ?? ''}${user.lastName?.[0] ?? ''}` || 'UNK';
  }
};

export function noop() {
}
