export const steps = [
  {
    id: 1,
    title: 'Buy tickets',
    description:
      'Prices are set when the round starts, equal to 5 USD in Tokens per ticket.',
  },
  {
    id: 2,
    title: 'Wait for the Draw',
    description: 'There are two draws every day: one every 12 hours.',
  },
  {
    id: 3,
    title: 'Check for Prizes',
    description:
      'Once the round’s over, come back to the page and check to see if you’ve won!',
  },
];

export const prizeFunds = [
  {
    id: 1,
    title: 'Ticket Purchases',
    description: (link = '#') => (
      <>
        80% of the Tokens paid by people buying tickets that round goes back into the prize pools.
      </>
    ),
  },
  {
    id: 2,
    title: 'Rollover Prizes',
    description: (link = '#') => (
      <>
        After every round, if nobody wins in one of the prize brackets, the unclaimed Tokens for that bracket rolls over
        into the next round and are redistributed among the prize pools.
      </>
    ),
  },
  // {
  //   id: 3,
  //   title: 'Token Injections',
  //   description: (link = '#') => (
  //     <>
  //       An average total of 35,000 Tokens from the treasury is added to lottery rounds over the course of a week. This
  //       Tokens
  //       is of course also included in rollovers!
  //     </>
  //   ),
  // },
];

export const prizeFundsLinks = [undefined, undefined, '#'];

export const stats = [
  {
    title: 'Matches first 1',
    color: '#FFE362',
    value: 2,
  }, {
    title: 'Matches first 2',
    color: '#85C54E',
    value: 3,
  }, {
    title: 'Matches first 3',
    color: '#028E75',
    value: 5,
  }, {
    title: 'Matches first 4',
    color: '#36E8F5',
    value: 10,
  }, {
    title: 'Matches first 5',
    color: '#A881FC',
    value: 20,
  }, {
    title: 'Matches all 6',
    color: '#D750B2',
    value: 40,
  }, {
    title: 'Treasury',
    color: '#BDC2C4',
    value: 20,
  },
];

export const staticNumbers = [9, 1, 3, 6, 6, 2];
