import { useCallback } from 'react';
import { switchChain, toHexString } from '../functions/switch-chain';
import { sleep } from '../functions/helpers';
import { useWeb3React } from '@web3-react/core';

export function useSwitchChainForClaim() {

  const getWeb3 = useWeb3React();

  /***
   *
   * @param chainId number
   * @param iterations default is 0
   * @param limit can be increased for repeated network change requests
   */
  const changeNetworkRequest = useCallback(async (chainId: number, iterations: number = 0, limit: number = 1): Promise<Promise<boolean> | void> => {
    while (iterations < limit) {
      iterations++;
      await switchChain(chainId);
      const t = await getWeb3.connector?.getChainId();

      if (t === toHexString(chainId)) return;
      await sleep(500);
    }
    if (iterations >= limit) throw new Error('User canceled switch');
    return new Promise(() => changeNetworkRequest(chainId, iterations));
  }, [getWeb3]);


  return changeNetworkRequest;
}
