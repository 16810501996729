import classes from './styles.module.css';
import ProfileHeader from './components/header';
import ProfileBody from './components/body';
import { useProfile } from 'context/useProfile';
import flags from '../../config/flags';

const ProfileView = () => {

  const { user, userFetching } = useProfile();

  if (!user && userFetching) return <div className={classes.loaderContainer}>
    <h3 style={{margin: 'auto'}}>Loading...</h3>
  </div>;

  return (
    <div className={classes.root}>
      {
        flags.profileHeader &&
        <ProfileHeader />
      }
      <ProfileBody />
    </div>
  );
};

export default ProfileView;
