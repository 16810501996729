import React, { useCallback, useMemo, useState } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { useWallet } from 'wallets/wallet';

import s from './styles.module.css';
import { ExtendContractProps, LotteryTicketClaimData } from 'interfaces/lottery';
import { useCurrency } from 'context/useCurrency';
import ParagraphSkeleton from 'components/skeletons/paragraph-skeleton';
import { useLottery } from 'context/useLottery';
import { exchangeUsdValue } from 'functions/utils';
import ModalHeader from '../components/modal-header';
import ModalBody from '../components/modal-body';
import Button from '../../themed-button';

import { ReactComponent as BagSvg } from 'resources/svg/bag.svg';
import { Contract } from 'web3-eth-contract';
import { Modal } from 'antd';

export type ClaimPrizeModalProps = ModalProps & {
  roundsToClaim: LotteryTicketClaimData[];
  onSuccess: () => void;
  contract?: (Contract & ExtendContractProps)
};

const ClaimPrizeModal: React.FunctionComponent<ClaimPrizeModalProps> = (
  { roundsToClaim = [], onSuccess, contract, ...props }) => {
  const { ...modalProps } = props;
  const wallet = useWallet();
  const { currencyFetching, exchange } = useCurrency();
  const {
    contractLottery,
    token: { tokenSymbol, tokenFullName },
  } = useLottery();


  const [loading, setLoading] = useState<boolean>(false);

  const [activeClaimIndex, setActiveClaimIndex] = useState(0);

  // const [pendingBatchClaims, setPendingBatchClaims] = useState(
  //   Math.ceil(
  //     roundsToClaim[activeClaimIndex].ticketsWithUnclaimedRewards?.length || 1,
  //   ),
  // );

  const activeClaimData = useMemo(() => roundsToClaim[activeClaimIndex] ?? {}, [
    roundsToClaim,
    activeClaimIndex,
  ]);

  const cakeReward = activeClaimData.cakeTotal;
  const dollarReward =
    (activeClaimData.cakeTotal as number) * (exchangeUsdValue(exchange, tokenFullName?.toLocaleLowerCase()) ?? 0);

  const handleProgressToNextClaim = useCallback(() => {
    if (roundsToClaim.length > activeClaimIndex + 1) {
      // If there are still rounds to claim, move onto the next claim
      setActiveClaimIndex((prevState) => prevState + 1);
    } else {
      if (typeof onSuccess === 'function') onSuccess();
    }
  }, [onSuccess, roundsToClaim.length, activeClaimIndex]);

  const handleClaim = useCallback(async () => {
    const ticketsId: string[] = [];
    const brackets: any = [];

    setLoading(true);

    activeClaimData.ticketsWithUnclaimedRewards?.forEach((ticket) => {
      ticketsId.push(ticket.id);
      brackets.push(ticket.rewardBracket);
    });

    try {

      await (contract ?? contractLottery)?.setProvider(wallet.provider);
      await (contract ?? contractLottery)?.methods
        .claimTickets(activeClaimData.roundId, ticketsId, brackets)
        .send({ from: wallet.account });

      handleProgressToNextClaim();
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClaimData, wallet.account, handleProgressToNextClaim,
    contractLottery?.setProvider, contractLottery?.methods, wallet.provider, contract]);


  return (
    <Modal
      className={s.component}
      centered
      closable={true}
      footer={null}
      {...modalProps}
    >
      <ModalHeader title={'Collect winnings!'} />

      <ModalBody>
        <div className={s.prizes}>
          <div>
            <span>Prize Pot</span>
          </div>
          {cakeReward !== undefined && (
            <div>
              <p className={s.prize}>
                {currencyFetching ? (
                  <ParagraphSkeleton />
                ) : (
                  `~$${dollarReward?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}`
                )}
              </p>
              <p className={s.small}>
                {cakeReward?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{' '}
                {tokenSymbol ?? ''}
              </p>
            </div>
          )}
        </div>
        <p className={s.round}>Round {activeClaimData.roundId}</p>

        <div className={s.buttonContainer}>
          <Button
            primary
            className={s.button}
            loading={loading || currencyFetching}
            onClick={handleClaim}
          >
            <span className={s.buttonTitle}>
              <BagSvg aria-hidden />
              <span>Claim</span>
            </span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ClaimPrizeModal;
