import React from 'react';
import { ModalProps } from 'antd/lib/modal';

import s from './styles.module.css';
import { Button, Modal } from 'antd';

export type ShowTicketModalProps = ModalProps & {
  setVisibility: () => void;
  lotteryId: string;
  ticketData: any;
};

const ShowTicketModal: React.FunctionComponent<ShowTicketModalProps> = (
  { visible, onCancel, lotteryId, setVisibility, ticketData },
) => {

  const getNumbers = (data: string) => {
    const newData: string = data.substring(1);
    return newData.split('').reverse();
  };

  return (
    <Modal
      className={s.component}
      centered
      closable={true}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <div className={s.headerLabel}>Round {lotteryId}</div>
      <div className={s.infoItem}>Your Tickets</div>

      <div className={s.numbersBlock}>
        {ticketData.number &&
          ticketData.number.map((number: any, index: number) => {
            const data = getNumbers(number);
            return (
              <div key={index}>
                <div className={s.rowTitle}>#{index + 1}</div>
                <div className={s.row}>
                  {data.map((number: string, indexY: number) => (
                    <div key={indexY}>
                      <span className={s.sindleDigit}>{number}</span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
      <Button
        type='ghost'
        className={s.backBtn}
        onClick={setVisibility}>
        Buy Tickets
      </Button>
    </Modal>
  );
};

export default ShowTicketModal;
