import { FC } from 'react';

export const BuildProviderTree = (providers: any[]): FC<any> => ({ children }) => {
  const lastIndex = providers.length - 1;
  let activeChildren = children;

  for (let i = lastIndex; i >= 0; i--) {
    const Provider = providers[i];
    activeChildren = <Provider>{activeChildren}</Provider>;
  }

  return activeChildren;
};
