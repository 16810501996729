import { useCallback, useEffect, useMemo, useState } from 'react';
import { CurrencyProviderProps, Exchange } from '../context/useCurrency';
import { useNetworks } from './useNetworks';


export const useFetchCurrency = (): CurrencyProviderProps => {
  const [fetching, setFetching] = useState(false);
  const [exchange, setExchange] = useState<Exchange>({});

  const networks = useNetworks();

  const links = useMemo(() => networks.map(({ tokens }) => tokens)
      .flat()
      .map(({ tokenName, tokenFullName, tokenPriceUrl }) =>
        ({ tokenName: tokenFullName || tokenName, tokenFullName, tokenPriceUrl })),
    [networks]);


  const update = useCallback(async () => {

    try {
      setFetching(true);

      const t = Array.from(new Set(
        links.map(({
                     tokenPriceUrl,
                     tokenFullName,
                   }) => (tokenPriceUrl?.split('ids=')[1] ?? tokenFullName?.toLowerCase())?.split('&')[0]),
      ));


      const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${t.toString()}&vs_currencies=usd`);


      const data = await response.json();
      setExchange(prevState => ({
        ...prevState,
        ...data,
      }));

      // await Promise.all(
      //   links.map(({ tokenPriceUrl }) => {
      //     return fetch(tokenPriceUrl)
      //       .then(res => res.json())
      //       .catch()
      //       .then(i => setExchange(prevState => ({
      //         ...prevState,
      //         ...i,
      //       })));
      //   }));

    } catch (e) {
      console.warn(e);
    } finally {
      setFetching(false);
    }
  }, [links]);

  useEffect(() => {
    update().catch();
  }, [update]);

  return {
    currencyFetching: fetching, exchange, update,
  };
};

