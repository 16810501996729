import { createContext, FC, useContext } from 'react';
import { useFetchCurrency } from '../hooks/useFetchCurrency';

enum Currencies {
  USD = 'usd'
}

export type Exchange = Partial<Record<string, Partial<Record<Currencies, number>>>> | undefined

export type CurrencyProviderProps = {
  currencyFetching: boolean
  exchange: Exchange
  update: () => Promise<void>
}

const CurrencyContext = createContext<CurrencyProviderProps>({
  currencyFetching: false,
  exchange: {},
  update: async () => undefined,
});

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider: FC = ({ children }) => {

  const { currencyFetching, exchange, update } = useFetchCurrency();

  return (
    <CurrencyContext.Provider value={{ currencyFetching, exchange, update }}>
      {children}
    </CurrencyContext.Provider>

  );
};

