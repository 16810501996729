import k from './styles.module.css';


import { ReactComponent as CriteriaTop } from 'resources/svg/static/criteria-top.svg';
// import { ReactComponent as CriteriaBottom } from 'resources/svg/static/criteria-bottom.svg';
import { ReactComponent as Background } from 'resources/svg/static/title-backdrop.svg';

import CardWrap from '../card-wrapper';

const CriteriaSection = () => {
  return (
    <div className={k.root}>
      <div className={k.info}>
        <h2 className={k.title}>
          Winning Criteria
          <Background className={k.titleBackground} />
        </h2>
        <span>
          The digits on your ticket must match in the correct order to win.
        </span>
        <p>Here’s an example lottery draw, with two tickets, A and B.</p>
        <ul>
          <li>
            <span>Ticket A:</span> The first 3 digits and the last 2 digits
            match, but the 4th digit is wrong, so this ticket only wins a “Match
            first 3” prize.
          </li>
          <li>
            <span>Ticket B:</span> Even though the last 5 digits match, the
            first digit is wrong, so this ticket doesn’t win a prize.
          </li>
        </ul>
        <p>
          Prize brackets don’t ‘stack’: if you match the first 3 digits in
          order, you’ll only win prizes from the ‘Match 3’ bracket, and not from
          ‘Match 1’ and ‘Match 2’.
        </p>
      </div>
      <aside className={k.sample}>
        <CardWrap>
          <div className={k.card}>
            <div />
            <div className={k.row}>
              <CriteriaTop />
            </div>
          </div>
        </CardWrap>
      </aside>
    </div>
  );
};

export default CriteriaSection;
