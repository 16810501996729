import React, { FC } from 'react';
import Button from 'components/themed-button';
import JackpotTitle from 'resources/images/lottery/jackpotTitle.png';

import s from './styles.module.css';
import flags from 'config/flags';
import { useHistory } from 'react-router-dom';
import {UserDto} from "../../../../interfaces/user.dto";
import {nameInitials} from "../../../../functions/utils";
import {useUsersList} from "../../../../context/useUsersList";

interface JackpotProps {
  lotteryList: any;
}

const Jackpot: React.FC<JackpotProps> = ({ lotteryList }) => {

  const { push } = useHistory();
  const { usersList } = useUsersList();
  const redirectToLottery = () => {
    if (!lotteryList?.route) return;

    push(`/${lotteryList.route}`);
  };

  return (
    <div className={s.section}>
      <div className={s.info}>
        <img src={JackpotTitle} alt='jackpot' />
        <h3 className={s.sum}><Numbers nums={`${lotteryList?.exchange ?? ''}$`} /></h3>
        <p className={s.description}>
          You can’t win the lottery if you don’t play
        </p>
        {
          flags.newUsers && usersList.todayUsers.length>0 &&
          (
            <div className={s.partipians}>
              {(usersList.todayUsers as UserDto[]).map((item: UserDto ) => {
                const avatarName = !item.avatar || item.avatar === '' ? nameInitials(item) : '';
                const avatarUrl = item.avatar && item.avatar.url ? `https://${item.avatar.url}` : '';
                return (
                  <div key={item.id} className={s.person}>
                    {avatarUrl !== '' && (
                      <img src={avatarUrl} alt={avatarName} />
                    )}
                    {avatarUrl === '' && (
                      <span className={s.noImage}>{avatarName}</span>
                    )}
                  </div>
                );
              })}
              <div className={s.counter}>+{usersList.todayUsers.length}</div>
            </div>
          )
        }
        <Button
          className={s.playButton}
          onClick={redirectToLottery}
          primary
          size={'large'}
        >
          Play Now
        </Button>
      </div>
    </div>
  );
};

interface NumbersProps {
  nums: string;
  length?: number;
}

const Numbers: FC<NumbersProps> = ({ nums, length = 7 }) => (
  <>
    {
      nums.padStart(length, ' ').split('').map((char, i) => (
        <span key={i} className={s.jackpotChar}>{char}</span>
      ))
    }
  </>
);


export default Jackpot;
