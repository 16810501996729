import { Contract } from 'web3-eth-contract';

export interface GetReferrersReward {
  round: number;
  contract: Contract | undefined;
  account: string;
}

export const getReferrersReward = async ({ round, contract, account }: GetReferrersReward) => {
  try {
    return contract?.methods
      .referrersRewards(account, round)
      .call();
  } catch (e) {
    console.error('getReferrersReward error', e);
  }
};
