import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { BigNumber } from 'bignumber.js';

import { getHumanValue } from 'web3/utils';

import k from './styles.module.css';
import { useLottery } from 'context/useLottery';
import { useCurrency } from 'context/useCurrency';
import { getDecimalsBySymbol } from 'functions/utils';
import { PriceWithIcon, UsdPrice } from './shared';
import RoundRow from './RoundRow';

export type PrizesSectionProps = {
  lotteryInfo: any;
};

const PrizesSection: React.FunctionComponent<PrizesSectionProps> = (
  { lotteryInfo },
) => {

  const { token: { tokenSymbol } } = useLottery();
  const { currencyFetching } = useCurrency();

  const [burnValue, setBurnValue] = useState<number>(0);
  const [prizeArray, setPrizeArray] = useState<any>([]);


  const getRaundsInfo = useCallback((data: any) => {
    if (data.amountCollectedInTokens && !currencyFetching) {
      const value = +getHumanValue(
        new BigNumber(data.amountCollectedInTokens),
        getDecimalsBySymbol(tokenSymbol as string),
      )!;

      let sum = +value;

      const burnValue = (+sum * +data.treasuryFee) / 10000;
      setBurnValue(burnValue);

      sum -= burnValue;

      const newValues: any = [];
      data.rewardsBreakdown.map((item: any) => {
        return newValues.push((sum * item) / 10000);
      });
      setPrizeArray(newValues);
    }
  }, [currencyFetching, tokenSymbol]);


  useEffect(() => {
    getRaundsInfo(lotteryInfo);
  }, [lotteryInfo, getRaundsInfo]);

  return (
    <>
      <table className={k.table}>
        <thead className={k.tableHead}>
        <tr>
          <th><small>Match</small></th>
          <th><small>Prize Pot</small></th>
          <th><small>Winners</small></th>
        </tr>
        </thead>
        <tbody>
        {
          Array.isArray(lotteryInfo.countWinnersPerBracket) &&
          lotteryInfo.countWinnersPerBracket.map((count: string, i: number) => (
            <RoundRow key={(count + i).toString()}
                      index={i}
                      prize={prizeArray[i]} count={count}
            />
          ))
        }
        {
          lotteryInfo.countWinnersPerBracket &&
          <tr
            className={cx(k.tableRow, k.burnRow)}>
            <td className={cx(k.prizeTitle, k.purple)}><h6>Treasury</h6></td>
            <td className={k.prizes}>
              <span>
                <PriceWithIcon value={burnValue} />
              </span>
              <UsdPrice value={burnValue} prefix={'~$'} />
            </td>
            <td>
            </td>
          </tr>
        }
        </tbody>
      </table>
    </>
  );
};

export default PrizesSection;
