import Web3 from 'web3';
import { getHttpsRpcUrl, getWSRpcUrl } from 'web3/utils';
import { useCallback } from 'react';
import { switchChain as chainSwitch } from 'functions/switch-chain';
import { UseLotteryContract } from 'context/useLottery';
import { Contract } from 'web3-eth-contract';
import { ExtendContractProps } from 'interfaces/lottery';

export interface LotteryContractProps {
  chainId: number | undefined;
  lotteryAddress: string | undefined;
  tokenAddress?: string;
  switchChain?: boolean;
}

export const useLotteryContract = () => {
  return useCallback(
    async ({
             chainId, lotteryAddress, tokenAddress, switchChain = true,
           }: LotteryContractProps): Promise<UseLotteryContract> => {
      const HTTP_PROVIDER = new Web3.providers.HttpProvider(
        getHttpsRpcUrl(chainId),
      );
      const WS_PROVIDER = new Web3.providers.WebsocketProvider(
        getWSRpcUrl(chainId),
      );

      let contractLottery;
      let swapp;
      let socketProvider;

      if (chainId) {

        if (switchChain) await chainSwitch(chainId);
        if (lotteryAddress) {


          const web3 = new Web3(HTTP_PROVIDER);
          contractLottery = new web3.eth.Contract(
            require('web3/abi/lottery.json'),
            lotteryAddress,
          );

          if (tokenAddress) {
            swapp = new web3.eth.Contract(
              require('web3/abi/swapp.json'),
              tokenAddress,
            );
          }
        }

        // socket provider for some cases
        socketProvider = new Web3(WS_PROVIDER);
      }
      return {
        contractLottery: contractLottery as (Contract & ExtendContractProps) | undefined,
        swappContract: swapp as (Contract & ExtendContractProps) | undefined,
        socketProvider: socketProvider as Web3 | undefined,
      };
    },
    [],
  );
};


export const useHTTPContract = () => {
  return useCallback(
    async ({ chainId, lotteryAddress }: Omit<LotteryContractProps, 'switchChain' | 'tokenAddress'>) => {
      const HTTP_PROVIDER = new Web3.providers.HttpProvider(
        getHttpsRpcUrl(chainId),
      );
      let contract;

      if (chainId && lotteryAddress) {
        const web3 = new Web3(HTTP_PROVIDER);
        contract = new web3.eth.Contract(
          require('web3/abi/lottery.json'),
          lotteryAddress,
        );

      }
      return {
        contract,
      };
    },
    [],
  );
};
