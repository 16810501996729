import React  from "react";
import cx from "classnames";

import { useWallet } from "wallets/wallet";

import ConnectedWallet from "components/connected-wallet";

import s from "./styles.module.css";

const WalletConnect: React.FunctionComponent = () => {
  const wallet = useWallet();

  return (
    <div className={s.component}>
        <div className={cx(s.wallet, !wallet.isActive && s.rightAlign)}>
          <ConnectedWallet />
      </div>
    </div>
  );
};

export default WalletConnect;
