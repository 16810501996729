import { UserDto } from '../interfaces/user.dto';
import { getAvatar, getUser } from '../services/user.service';
import { CancelToken } from 'axios';

export const getUserInfo = async (cancelToken?: CancelToken): Promise<UserDto> => {
  return getUser(cancelToken);/*
  return {
    firstName: 'Adam',
    lastName: 'Morgan',
    email: 'test@test.com',
    links: {
      facebook: {
        value: 'facebook',
        connected: true,
      },
      discord: {
        connected: true,
        value: 'discord',
      },
      telegram: {
        value: 'telegram',
        connected: false,
      },
    },
  };*/
};


export const updateProfile = async (): Promise<UserDto> => {
  return getUser();
};


export const getUserAvatar = async (): Promise<string> => {
  return getAvatar();
};
