import React, { FC } from 'react';
import k from './styles.module.css';
import { PriceWithIcon, UsdPrice } from './shared';

interface RoundRowProps {
  prize: number;
  count: string;
  index: number
}


const RoundRow: FC<RoundRowProps> = ({ prize, count, index }) => {

  return (
    <tr
      className={k.tableRow}>
      <td className={k.prizeTitle}><h6>First {index + 1}</h6></td>
      <td className={k.prizes}>
        <span>
        <PriceWithIcon value={prize} />
        </span>
        <span>
        <UsdPrice value={prize} prefix={'~$'} />
        </span>
      </td>
      <td>
          <span className={k.prizeWinners}>
            {count}
          </span>
      </td>
    </tr>
  );
};


export default RoundRow;
