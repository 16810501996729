import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowRight } from 'resources/svg/icons/arrow-right-normal.svg';
import { ReactComponent as ArrowLeft } from 'resources/svg/icons/arrow-left-normal.svg';

import { winningNumbersArray } from '../../constants';
import { parseRetrievedNumber } from 'functions/helpers';

import Prizes from './components/prizes';

import k from './styles.module.css';
import { getLotteryInfo } from 'functions/get-lottery-data';
import { useLottery } from 'context/useLottery';
import HistoryWrapper from '../history-wrapper';
import cx from 'classnames';
import { Button } from 'antd';

export type NumbersProps = {
  id: number;
  number: number;
  color: string;
};

export type WinningNumbersProps = {};

const WinningNumbers: React.FunctionComponent<WinningNumbersProps> = () => {


  const { lotteryId: currentLotteryId, contractLottery } = useLottery();

  const [numbers, setNumbers] = useState([]);
  const [lotteryInfo, setLotteryInfo]: any = useState({});
  const [lotteryId, setLotteryId] = useState('');

  useEffect(() => {
    if (currentLotteryId) setLotteryId((+currentLotteryId - 1).toString());
  }, [currentLotteryId]);

  useEffect(() => {
    if (lotteryId && contractLottery) {
      getLotteryInfo({ contractLottery, lotteryId })
        .then(info => setLotteryInfo(info)).catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryId, contractLottery]);

  useEffect(() => {
    if (!lotteryInfo.finalNumber) return;

    const numAsArray = parseRetrievedNumber(lotteryInfo.finalNumber).split('');

    const newArray: any = [...winningNumbersArray];
    newArray.forEach(
      (item: any, index: number) => (item.number = numAsArray[index]),
    );
    setNumbers(newArray);
  }, [lotteryInfo.finalNumber]);

  return (
    <>
      <HistoryWrapper>

        <HistoryWrapper.Header>
          <div className={k.topInfo}>
            <div className={k.round}>
              <h4 className={k.title}>Round </h4>
              <input
                value={lotteryId}
                className={k.setNumber}
                onChange={(e) => setLotteryId(e.target.value)}
              />
            </div>
            <div className={k.controls}>
              <Button
                disabled={!lotteryId || +lotteryId <= 1}
                onClick={() => setLotteryId((+lotteryId - 1).toString())}>
                <ArrowLeft />
              </Button>
              <Button
                disabled={+lotteryId >= +currentLotteryId - 1}
                onClick={() => setLotteryId((+lotteryId + 1).toString())}>
                <ArrowRight />
              </Button>
            </div>
          </div>

          <div className={k.winNumbers}>
            <h4>Winning numbers</h4>
            <div className={k.numbers}>
              {numbers &&
                numbers.map((item: NumbersProps, ind) => (
                  <span
                    key={item.id}
                    className={cx(k.row, k[`_${ind + 1}`] ?? '')}>
                    {item.number}
                  </span>
                ))}
            </div>
          </div>
        </HistoryWrapper.Header>

        <HistoryWrapper.Body>
          <Prizes lotteryInfo={lotteryInfo} />
        </HistoryWrapper.Body>

      </HistoryWrapper>
    </>
  );
};

export default WinningNumbers;
