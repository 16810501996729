import { useCallback } from 'react';
import { Contract } from 'web3-eth-contract';
import { useLottery } from '../context/useLottery';
import { LotteryContractProps } from './useLotteryContract';
import { getLotteryEvents, getLotteryInfo, getWinningTickets } from '../functions/get-lottery-data';
import { useWallet } from '../wallets/wallet';
import { LotteryData } from '../views/lottery/components/winning-numbers/components/history';

type ContractProp = (
  { chainId, lotteryAddress }: Pick<LotteryContractProps, 'chainId' | 'lotteryAddress'>,
) => Promise<{ contract: Contract | undefined }>

interface PropsBase {
  lotteryId: string;
}

interface LotteryProps extends PropsBase {
  chainId: number;
  lotteryAddress: string;
  contract: ContractProp;
}

interface CurrentLotteryProps extends PropsBase {
  chainId?: never;
  lotteryAddress?: never;
  contract?: never;
}

type UseGetLotteryInfoProps = (props: LotteryProps | CurrentLotteryProps) => Promise<LotteryData>

export function useGetLotteryInfo(): UseGetLotteryInfoProps {
  const { account } = useWallet();
  const { contractLottery } = useLottery();

  return useCallback(
    async ({ lotteryId, chainId, lotteryAddress, contract }) => {

      let lotteryContract: Contract | undefined;

      if (contract !== undefined && lotteryAddress !== undefined && chainId !== undefined) {
        const { contract: lc } = await contract({
          chainId, lotteryAddress,
        });
        lotteryContract = lc;
      } else {
        lotteryContract = contractLottery;
      }

      const lotteryInfo = await getLotteryInfo({
        lotteryId,
        contractLottery: lotteryContract ,
      });

      const events = await getLotteryEvents(account!, lotteryId, lotteryContract as any);

      const winningTickets = await getWinningTickets(
        {
          roundId: lotteryId,
          userTickets: events,
          finalNumber: lotteryInfo.finalNumber,
        },
        lotteryContract,
        account!,
      );

      return {
        finalNumber: lotteryInfo.finalNumber,
        events,
        winningTickets,
      };
    }, [account, contractLottery]);
}
