export const numberWithCommas = (value) =>
	value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const checkWalletNetwork = (wallet) => {
	return wallet.account && (wallet.networkId === 1 || wallet.networkId === 3);
};

export const parseRetrievedNumber = (number) => {
	const numberAsArray = number.split('');
	numberAsArray.splice(0, 1);
	numberAsArray.reverse();
	return numberAsArray.join('');
};

export const sleep = async (time = 1000) => new Promise(resolve => {
		const timer = setTimeout(() => {
			resolve();
			return () => clearTimeout(timer);
		}, time);
	},
);
