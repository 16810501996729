import c from './styles.module.css';
import { ReactComponent as TicketStar } from 'resources/svg/icons/ticket-star.svg';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useWallet } from 'wallets/wallet';
import winL from 'resources/images/lottery/win-l.png';
import winR from 'resources/images/lottery/win-r.png';
// import ticketR from 'resources/images/lottery/ticket-r.png';
// import tornTicketL from 'resources/images/lottery/torn-ticket-l.png';
// import tornTicketR from 'resources/images/lottery/torn-ticket-r.png';
import useGetUnclaimedRewards from 'hooks/useGetUnclaimedRewards';
import ClaimPrizeModal from 'components/modals/claime-prize-modal';
import { useLottery } from 'context/useLottery';
import Button from 'components/themed-button';

export type CheckPrizeProps = {
  updateData: Function;
};

interface BodyProps extends CheckTextProps {
  account: string | undefined;
  handleWalletConnect: () => void;
  checkWin: () => Promise<void>;
  hasCheckedForRewards: boolean;
}

interface CheckTextProps {
  hasRewardsToClaim: boolean;
  claimModalVisible: boolean;
  isFetchingRewards: boolean;
}

const CheckPrizesSection: FC<CheckPrizeProps> = ({ updateData }) => {
  const { account, showWalletsModal } = useWallet();
  const { lotteryId, isTransitioning } = useLottery();

  const [isFetchingRewards, setIsFetchingRewards] = useState(false);
  const [hasCheckedForRewards, setHasCheckedForRewards] = useState(false);
  const [hasRewardsToClaim, setHasRewardsToClaim] = useState(false);

  const [claimModalVisible, setClaimModalVisible] = useState(false);

  // Clear local state on account change, or when lottery state changes
  useEffect(() => {
    setIsFetchingRewards(false);
    setHasCheckedForRewards(false);
    setHasRewardsToClaim(false);
  }, [account, lotteryId, isTransitioning]);

  const {
    fetching,
    fetchAllRewards,
    unclaimedRewards,
  } = useGetUnclaimedRewards({});

  useEffect(() => {
    if (!fetching && hasCheckedForRewards && unclaimedRewards !== undefined) {
      // Manage showing unclaimed rewards modal once per page load / once per lottery state change
      if (unclaimedRewards.length > 0) {
        setHasRewardsToClaim(true);
        setClaimModalVisible(true);
      }

      if (unclaimedRewards.length === 0) {
        setHasRewardsToClaim(false);
      }
    }
  }, [
    unclaimedRewards,
    hasCheckedForRewards,
    fetching /*onPresentClaimModal*/,
  ]);

  const checkWin = useCallback(async () => {
    try {
      setIsFetchingRewards(true);
      await fetchAllRewards();
      setHasCheckedForRewards(true);
    } catch (error) {
      setHasCheckedForRewards(false);
    } finally {
      setIsFetchingRewards(false);
    }
  }, [fetchAllRewards]);


  const changeVisibility = useCallback(() => {
    setClaimModalVisible(false);
    updateData();
  }, [updateData]);


  return (
    <section className={c.section}>
      <div className={c.check}>
        <Body
          claimModalVisible={claimModalVisible}
          isFetchingRewards={isFetchingRewards}
          hasRewardsToClaim={hasRewardsToClaim}
          checkWin={checkWin}
          account={account}
          handleWalletConnect={showWalletsModal}
          hasCheckedForRewards={hasCheckedForRewards}
        />

      </div>
      <ClaimPrizeModal
        visible={claimModalVisible}
        onCancel={changeVisibility}
        roundsToClaim={unclaimedRewards ?? []}
        onSuccess={changeVisibility}
      />
    </section>
  );
};

const Body: FC<BodyProps> = ({
                               account, handleWalletConnect, hasCheckedForRewards,
                               hasRewardsToClaim, isFetchingRewards, checkWin, claimModalVisible,
                             }) => {
  if (!account) {
    return (
      <>
        <img src={winL} alt={'pocket with money'} />
        <div className={c.content}>
          <h3 className={c.title}>Are you a winner?</h3>
          <p className={c.subTitle}>
            Connect your wallet to check if you've won!
          </p>
          <Button className={c.button} onClick={handleWalletConnect}>
              <span className={c.buttonTitle}>
                <TicketStar aria-hidden />
                Connect Wallet
              </span>
          </Button>
        </div>
        <img src={winR} alt={'lottery ticket'} />
      </>
    );
  }

  if (hasCheckedForRewards && !hasRewardsToClaim) {
    return (
      <>
        {/*<img src={tornTicketL} alt='torn lottery ticket' />*/}
        <div className={c.content}>
          <h2 className={c.title}>No prizes to collect...</h2>
          <h2 className={c.title}>Better luck next time!</h2>
        </div>
        {/*<img src={tornTicketR} alt='torn lottery ticket' />*/}
      </>
    );
  }

  if (hasCheckedForRewards && hasRewardsToClaim) {
    return (
      <>
        {/*<img src={ticketL} alt={'lottery ticket'} />*/}
        <div className={c.content}>
          <h2 className={c.title}>Congratulations!</h2>
          <h2 className={c.title}>Why not play again</h2>
        </div>
        {/*<img src={ticketR} alt={'lottery ticket'} />*/}
      </>
    );
  }

  return (
    <>
      <img src={winL} alt={'pocket with money'} />
      <div className={c.content}>
        <h3 className={c.title}>Are you a winner?</h3>
        <Button
          className={c.button}
          disabled={isFetchingRewards}
          onClick={checkWin}
        >
            <span className={c.buttonTitle}>
              <TicketStar aria-hidden />
              {checkNowText({ isFetchingRewards, claimModalVisible, hasRewardsToClaim })}
            </span>
        </Button>
      </div>
      <img src={winR} alt={'lottery ticket'} />
    </>
  );
};

function checkNowText({ isFetchingRewards, hasRewardsToClaim, claimModalVisible }: CheckTextProps): string {
  if (hasRewardsToClaim && claimModalVisible) {
    return 'Calculating rewards ...';
  }
  if (isFetchingRewards) {
    return 'Checking';
  }
  return 'Check now';
};


export default CheckPrizesSection;
