import React, { FC } from 'react';
import classes from './styles.module.css';

interface ModalHeaderProps {
  title: string;
}


const ModalHeader: FC<ModalHeaderProps> = ({ title }) => {
  return (
    <div className={classes.header}>
      <h2 className={classes.headerLabel}>{title}</h2>
    </div>
  );
};

export default ModalHeader;
