import classes from './styles.module.css';
import { Avatar } from 'antd';
import cx from 'classnames';
import { useWallet } from 'wallets/wallet';
import { useProfile } from 'context/useProfile';
import { nameInitials } from 'functions/utils';

const ProfileHeader = () => {

  const { account } = useWallet();
  const { user, avatar } = useProfile();

  const { lastName = '', firstName = '', moneyWon = 0, totalTicketsPurchased = 0, ticketsWon = 0 } = user ?? {};

  const userNameTitle = firstName || lastName ? `${firstName} ${lastName}` : 'Unknown';

  const avatarName = (!avatar || avatar === '') ? nameInitials(user) : '';

  if (!account) return null; // TODO: remove

  const withStyle = (className: string = '', ...rest: string[]) => cx(classes.info, className, rest);

  return (
    <header className={classes.root}>

      <div className={classes.content}>
        <div>
          <Avatar src={avatar} className={classes.avatar} alt={'avatar'} size={64}>{avatarName}</Avatar>
        </div>
        <div className={classes.name}>
          <h4>{userNameTitle}</h4>
          <p>{account}</p>
        </div>
      </div>

      <div className={classes.content}>
        <div className={withStyle(classes.money)}>
          <small>Money won</small>
          <h3>{`$ ${moneyWon}`}</h3>
        </div>
        <Divider />
        <div className={withStyle()}>
          <small>Total tickets purchased</small>
          <h3>{totalTicketsPurchased}</h3>
        </div>
        <Divider />
        <div className={withStyle(classes.wonTickets)}>
          <small>Tickets won</small>
          <h3>{ticketsWon}</h3>
        </div>
      </div>
    </header>
  );
};


const Divider = () => (
  <div className={classes.divider} />
);

export default ProfileHeader;
