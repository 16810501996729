import { TICKET_LIMIT_PER_REQUEST } from '../views/lottery/constants';
import {
  ExtendContractProps,
  GetClaimRewards,
  GetLotteriesInfo,
  GetLotteryInfo,
  LotteryTicketClaimData,
  RawTicketsResponse,
  RoundDataAndUserTickets,
} from '../interfaces/lottery';
import { LotteryTicket } from '../views/lottery/components/check-prizes/interfaces';
import { getRewardBracketByNumber } from '../hooks/useGetUnclaimedRewards';
import BigNumber from 'bignumber.js';
import { convertToHumanValues } from './utils';
import { Contract } from 'web3-eth-contract';

export const getLotteryInfo: GetLotteryInfo = async ({ contractLottery, lotteryId },
) => contractLottery?.methods?.viewLottery(lotteryId).call();

export const processRawToTicketsResponse = (
  ticketsResponse: any,
  existingData: any,
): RawTicketsResponse => ({
  id: existingData.id
    ? [...existingData.id, ...ticketsResponse[0]]
    : ticketsResponse[0],
  number: existingData.number
    ? [...existingData.number, ...ticketsResponse[1]]
    : ticketsResponse[1],
  status: existingData.status
    ? [...existingData.status, ...ticketsResponse[2]]
    : ticketsResponse[2],
});

export const getLotteryEvents = async (
  account: string,
  lotteryId: string,
  contractLottery: (Contract & ExtendContractProps) | undefined,
  cursor: number = 0,
  numReturned: number = TICKET_LIMIT_PER_REQUEST,
): Promise<any> => {
  let data = {};

  if (contractLottery?.methods && account) {
    while (numReturned === TICKET_LIMIT_PER_REQUEST) {
      const response = await contractLottery?.methods
        .viewUserInfoForLotteryId(
          account,
          lotteryId,
          cursor,
          TICKET_LIMIT_PER_REQUEST,
        )
        .call();
      cursor += TICKET_LIMIT_PER_REQUEST;
      numReturned = response[0].length;
      data = processRawToTicketsResponse(response, data);
    }
  }
  return data;
};

export const getLotteriesInfoObjects: GetLotteriesInfo = async (
  { lotteries, contractLottery }) => {
  return Promise.all(
    lotteries.map(async ({ roundId }) =>
      getLotteryInfo({ lotteryId: roundId, contractLottery }),
    ),
  ).then((res) => res.map((x, i) => ({ ...x, id: lotteries[i].roundId })));
};

export const getClaimRewards: GetClaimRewards = async (
  { contractLottery, winningTickets = [], account }) => {

  return Promise.all(
    winningTickets.map((ticket) => {
      return contractLottery.methods
        ?.viewRewardsForTicketId(
          ticket.roundId,
          ticket.id,
          ticket.rewardBracket,
        )
        .call({ from: account });
    }),
  );
};

export const fetchCakeRewardsForTickets = async (
  winningTickets: LotteryTicket[],
  contractLottery: any,
  account: string,
): Promise<{
  ticketsWithUnclaimedRewards: LotteryTicket[] | null;
  cakeTotal: BigNumber | number | null;
}> => {
  try {
    const cakeRewards = await getClaimRewards({
      winningTickets,
      account,
      contractLottery,
    });
    const cakeTotal = convertToHumanValues(cakeRewards, contractLottery._address);

    const ticketsWithUnclaimedRewards = winningTickets.map(
      (winningTicket, index) => {
        return { ...winningTicket, cakeReward: cakeRewards[index] };
      },
    );
    return { ticketsWithUnclaimedRewards, cakeTotal };
  } catch (error) {
    console.error(error);
    return { ticketsWithUnclaimedRewards: null, cakeTotal: null };
  }
};

export const getWinningTickets = async (
  roundDataAndUserTickets: RoundDataAndUserTickets,
  contractLottery: any,
  account: string,
): Promise<LotteryTicketClaimData | null> => {
  const { roundId, userTickets, finalNumber } = roundDataAndUserTickets;

  const userTicketsArray: LotteryTicket[] = userTickets.id.map((id, index) => ({
    id,
    number: userTickets.number[index],
    status: userTickets.status[index],
  }));

  const ticketsWithRewardBrackets = userTicketsArray.map((ticket) => {
    return {
      roundId,
      id: ticket.id,
      number: ticket.number,
      status: ticket.status,
      rewardBracket: getRewardBracketByNumber(ticket.number, finalNumber),
    };
  });

  // A rewardBracket of -1 means no matches. 0 and above means there has been a match
  const allWinningTickets = ticketsWithRewardBrackets.filter((ticket) => {
    return ticket.rewardBracket >= 0;
  });

  // If ticket.status is true, the ticket has already been claimed
  const unclaimedWinningTickets = allWinningTickets.filter((ticket) => {
    return !ticket.status;
  });

  if (unclaimedWinningTickets.length > 0) {
    const {
      cakeTotal,
      ticketsWithUnclaimedRewards,
    } = await fetchCakeRewardsForTickets(
      unclaimedWinningTickets,
      contractLottery,
      account,
    );

    return {
      ticketsWithUnclaimedRewards,
      allWinningTickets,
      cakeTotal,
      roundId,
    };
  }

  if (allWinningTickets.length > 0) {
    return {
      ticketsWithUnclaimedRewards: null,
      allWinningTickets,
      cakeTotal: null,
      roundId,
    };
  }

  return null;
};

export const getAdditionalLotteryInfo = async (chainId: number, address: string, contract: any) => {

  const { contract: contractLottery } = await contract({
    chainId, lotteryAddress: address,
  });

  if (contractLottery?.methods) {
    const lotteryId = await contractLottery.methods.currentLotteryId().call();
    const lotteryInfo = await contractLottery.methods
      .viewLottery(lotteryId)
      .call();

    return { lotteryId, lotteryInfo };
  }
};
