import { TABS } from '../body';
import { FC } from 'react';
import SettingsTab from './components/settings';
import HistoryTab from './components/history';
import ReferralTab from './components/referral';
import WalletTab from './components/wallet';

interface TabsContentProps {
  name: TABS;
}

const TabsContent: FC<TabsContentProps> = ({ name }) => {
  switch (name) {
    case (TABS.HISTORY):
      return <HistoryTab />;

    case (TABS.REFERRAL):
      return <ReferralTab />;

    case (TABS.WALLET):
      return <WalletTab />;

    case (TABS.SETTINGS):
      return <SettingsTab />;

    default:
      return null;
  }
};

export default TabsContent;
