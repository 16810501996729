import { createContext, FC, useCallback, useContext, useRef, useState } from 'react';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import { getUserList } from '../services/user.service';
import { UsersListDto } from '../interfaces/usersList.dto';
import axios, { CancelTokenSource } from 'axios';

interface UsersListProviderData {
  usersList: UsersListDto;
  usersListFetching: boolean;
  restart: () => void;
  stop: () => void;
  hasStopped: boolean;
}

const initialUsersList = { winners: [], todayUsers: [], referrals: [] };

const UsersListContext = createContext<UsersListProviderData>({
  usersList: initialUsersList,
  usersListFetching: false,
  restart: () => undefined,
  stop: () => undefined,
  hasStopped: false,
});

export const useUsersList = () => useContext(UsersListContext);
const UsersListProvider: FC = ({ children }) => {

  const cancelToken = useRef<CancelTokenSource | null>(null);
  const interval = useRef<NodeJS.Timer>();
  const hasStopped = useRef(false);

  const [usersListFetching, setUsersListFetching] = useState(false);
  const [usersList, setUsersList] = useState<UsersListDto>(initialUsersList);
  const [forceRestart, setForceRestart] = useState<Object>({}); /* use object to restart requests because {} !== {} */

  useAsyncEffect(async () => {

    const getData = async () => {

      if (cancelToken.current) {
        cancelToken.current.cancel();
      }
      const source = axios.CancelToken.source();
      cancelToken.current = source;

      const [winners, todayUsers, referrals] = await Promise.all([
        getUserList(null, 'lastWinTimestamp', undefined, 'WINNERS', source.token),
        getUserList(1000, null, undefined, 'TODAY', source.token),
        getUserList(1000, 'moneyReward', undefined, 'REFERRALS', source.token),
      ]).catch();

      cancelToken.current = null;

      return { winners, todayUsers, referrals };
    };


    try {
      setUsersListFetching(true);
      setUsersList(await getData());
      interval.current = setInterval(async () => {
        setUsersList(await getData());
      }, 60000);
    } catch {
    } finally {
      setUsersListFetching(false);
    }

    return () => {
      if (interval.current !== undefined) clearInterval(interval.current);
    };
  }, [forceRestart]);

  const stop = useCallback(() => {
    hasStopped.current = true;
    cancelToken.current?.cancel();
    if (interval.current !== undefined) clearInterval(interval.current);
  }, []);

  const restart = useCallback(() => {
    stop();
    hasStopped.current = false;
    setForceRestart({});
  }, [stop]);

  return (
    <UsersListContext.Provider value={{ usersList, usersListFetching, stop, restart, hasStopped: hasStopped.current }}>
      {children}
    </UsersListContext.Provider>
  );
};

export default UsersListProvider;
