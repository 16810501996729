import classes from './styles.module.css';
import { Tabs } from 'antd';
import React, { FC, useState } from 'react';
import TabsContent from '../tabs';
import HighLight from 'components/highlight';

const { TabPane } = Tabs;

interface ProfileBodyProps {
}

export enum TABS {
  SETTINGS = 'SETTINGS',
  HISTORY = 'HISTORY',
  WALLET = 'WALLET',
  REFERRAL = 'REFERRAL'
}

const TabNames: Partial<Record<TABS, string>> = {
  [TABS.SETTINGS]: 'Account Settings',
  [TABS.HISTORY]: 'History',
  // [TABS.WALLET]: 'Wallet',
  [TABS.REFERRAL]: 'Referral program',
};

const defaultTab = Object.keys(TabNames)[0] as TABS;
const tabs: Array<keyof typeof TabNames> = Object.keys(TabNames) as Array<keyof typeof TabNames>;

const ProfileBody: FC<ProfileBodyProps> = () => {

  const [activeTab, setActiveTab] = useState<TABS>(defaultTab);

  return (
    <div className={classes.root}>
      <HighLight position={{ right: '50%' }} size={117} blur={118} />
      <Tabs defaultActiveKey={defaultTab} onChange={setActiveTab as any}>
        {
          tabs.map((key) => (
              <TabPane tab={TabNames[key]} key={key} className={classes.tabContent}>
                <TabsContent name={activeTab} />
              </TabPane>
            ),
          )
        }
      </Tabs>
    </div>
  );
};

export default ProfileBody;
