import { FC, memo } from 'react';
import { ButtonProps } from 'antd/lib/button/button';
import { Button as AButton } from 'antd';
import cx from 'classnames';
import classes from './styles.module.css';

interface ButtonExtendedProps {
  success?: boolean;
  primary?: boolean;
  ghost?: boolean;
  muted?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'large';
}

const Button: FC<ButtonProps & ButtonExtendedProps> = memo((
  {
    children,
    size = '',
    primary, success, ghost,
    muted,
    fullWidth,
    className, ...props
  },
) => {

  const styles = cx(classes.root, {
    [classes.default]: primary,
    [classes.success]: success,
    [classes.ghost]: ghost,
    [classes.ghost]: ghost,
    [classes.muted]: muted,
    [classes.fullWidth]: fullWidth,
    [classes[size]]: !!size,
  }, className);


  return (
    <AButton className={styles} {...props}>{children}</AButton>
  );
});

export default Button;
