import k from './styles.module.css';
import { prizeFunds, prizeFundsLinks, stats } from '../../constants';
import { Fragment } from 'react';
import CardWrap from '../card-wrapper';

import { ReactComponent as Diagram } from 'resources/svg/static/diagram.svg';
import { ReactComponent as Background } from 'resources/svg/static/title-backdrop.svg';

const FundsSection = () => {
  return (
    <div className={k.root}>
      <div className={k.info}>
        <h2 className={k.title}>
          Prize Funds
          <Background className={k.titleBackground} />
        </h2>
        <span>The prizes for each lottery round come from three sources:</span>
        {(prizeFunds ?? [])?.map(({ id, title, description }, ind) => (
          <Fragment key={id}>
            <h3 className={k.subtitle}>{title}</h3>
            <ul>
              <li>{description(prizeFundsLinks?.[ind])}</li>
            </ul>
          </Fragment>
        ))}
      </div>

      <aside className={k.sample}>
        <CardWrap>
          <div className={k.card}>
            <div className={k.graph}>
              <Diagram aria-hidden />
            </div>
            <div className={k.columnTitle}>
              <small>Digits Mathed</small>
              <small>Prize Pool Allocation</small>
            </div>
            <div className={k.stats}>
              {(stats ?? []).map(({ color, title, value }) => (
                <Fragment key={title}>
                  <div className={k.row}>
                    <div
                      className={k.circle}
                      style={{ backgroundColor: color }}
                    />
                    <span>{title}</span>
                  </div>
                  <span className={k.value}>{value}%</span>
                </Fragment>
              ))}
            </div>
          </div>
        </CardWrap>
      </aside>
    </div>
  );
};

export default FundsSection;
