export enum LotteryStatus {
  PENDING = 'pending',
  OPEN = 'open',
  CLOSE = 'close',
  CLAIMABLE = 'claimable',
}

export interface GetAllTicketsProps {
  first: number;
  skip: number;
  where: { lottery_in?: string[] };
  account: string;
  url: string;
  signal?: AbortSignal
}

export type Lottery = {
  endTime: string
  id: string
  status: LotteryStatus
}

export type Round = {
  claimed: boolean
  id: string
  lottery: Lottery
  totalTickets: string
}

export type User = {
  id: string
  rounds: Round []
  totalCake: string
  totalTickets: string
} | null

export type GetAllTicketsReturnType = {
  user: User
}

export interface LotteryTicket {
  id: string;
  number: string;
  status: boolean;
  rewardBracket?: number;
  roundId?: string;
  cakeReward?: string;
}

export type LotteriesWhere = { lottery_in?: string[] };

export interface LotteryRoundEntity extends Record<string, any> {
  id: string;
  totalTickets: string;
  winningTickets: string;
  status: LotteryStatus;
  finalNumber: string;
  startTime: string;
  endTime: string;
  ticketPrice: string;
}
