import tClasses from './../table.module.css';
import classes from './styles.module.css';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Button from 'components/themed-button';

import { RightOutlined } from '@ant-design/icons';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { LotteryContractProps, useHTTPContract, useLotteryContract } from 'hooks/useLotteryContract';
import { getAllTickets } from 'views/lottery/components/check-prizes/get-all-tickets';
import { useWallet } from 'wallets/wallet';
import { Round } from 'views/lottery/components/check-prizes/interfaces';
import { format } from 'date-fns';
import { ExtendContractProps, Network, Token } from 'interfaces/lottery';
import LotteryInfoModal from 'components/modals/lottery-info-modal';
import { useGetLotteryInfo } from 'hooks/useGetLotteryInfo';
import { LotteryData } from 'views/lottery/components/winning-numbers/components/history';
import { useNetworks } from 'hooks/useNetworks';
import { Contract } from 'web3-eth-contract';
import { useSwitchChainForClaim } from 'hooks/useSwitchChainForClaim';

interface HistoryRounds extends Round {
  token: Token;
  network: Omit<Network, 'tokens'>;
  totalCake: string | undefined;
  totalTickets: string;
}

interface GetRoundDetailsProps {
  lotteryId: string;
  chainId: number;
  lotteryAddress: string;
  tokenAddress: string;

  totalCake: string;
  totalTickets: string;
}

type GetRoundDetails = (props: GetRoundDetailsProps) => Promise<void>

interface TableRowProps {
  round: HistoryRounds;
  onDetails: GetRoundDetails;
}


export interface OnClaimResponse {
  contract: Contract & ExtendContractProps | undefined;
  success: boolean;
}

const HistoryTab = () => {

  const { account } = useWallet();

  const abortController = useRef<AbortController>();

  const [/*loading*/, setLoading] = useState(false);

  const [rounds, setRounds] = useState<HistoryRounds[]>([]);
  const [lotteryData, setLotteryData] = useState<LotteryData | null>(null);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [contractProps, setContractProps] = useState<LotteryContractProps | null>(null);

  const contract = useHTTPContract();
  const loadLotteryInfo = useGetLotteryInfo();
  const networks = useNetworks();
  const getContract = useLotteryContract();

  const switchChainForClaim = useSwitchChainForClaim();

  const showLotteryInfo = useCallback<GetRoundDetails>(
    async ({ lotteryId, chainId, lotteryAddress, tokenAddress }) => {
      try {
        setCurrentId(lotteryId);
        const lotteryInfo = await loadLotteryInfo({
          lotteryId,
          chainId, lotteryAddress, contract: contract as any,
        });
        setContractProps({
          chainId, lotteryAddress, tokenAddress,
        });
        setLotteryData(lotteryInfo);
      } catch (e) {
        console.log(showLotteryInfo.name, e);
      }

    }, [contract, loadLotteryInfo]);

  useAsyncEffect(async () => {
    if (account) {

      try {
        setLoading(true);

        const controller = new AbortController();
        abortController.current?.abort();
        abortController.current = controller;
        let data = (await Promise.all(
          networks.map((item) => {
            return Promise.all(
              item.tokens.map(async (token) => {

                // const {
                //   contract: contractLottery,
                // } = await contract({
                //   chainId: +item.chainId,
                //   lotteryAddress: token.lotteryAddress,
                // });
                // // FIXME: add skip from last if needed
                // const lotteryId = await contractLottery?.methods.currentLotteryId().call();

                let options = {
                  skip: 0,
                  first: 50,
                  where: {},
                };

                const { user: tickets } = await getAllTickets({
                  url: token.graphUrl, account, ...options, signal: controller.signal,
                });

                const { tokens, ...network } = item;
                return { tickets, token, network };
              }));

          })))
          .flat()
          .filter(({ tickets }) => !!tickets);

        abortController.current = undefined;

        const _rounds: HistoryRounds[] = [];
        data.forEach(({ tickets, token, network }) => {

          const { totalCake } = tickets ?? {};
          tickets?.rounds.forEach(x => {
            _rounds.push({
              ...x, token, network, totalCake,
            } as any);
          });
        });

        setRounds(_rounds.sort(
          ({ lottery: { endTime: endTimeA } }, { lottery: { endTime: endTimeB } }) => {
            return +endTimeB - +endTimeA;
          }));

      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }, [account, networks]);

  useEffect(() => {
    return () => {
      abortController.current?.abort();
    };
  }, []);

  const onClaim = useCallback(async (): Promise<OnClaimResponse> => {

    let contract: Contract & ExtendContractProps | undefined;
    let success = false;
    try {

      if (contractProps) {
        await switchChainForClaim(contractProps.chainId!);

        contract = (await getContract(contractProps)).contractLottery as Contract & ExtendContractProps | undefined;
        success = true;
      }
      setContractProps(null);
    } catch (e) {
      console.error('Profile history onClaim error', e);
    }
    return { contract, success };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContract, contractProps]);

  const closeInfoModal = useCallback(() => setLotteryData(null), []);
  return (
    <div className={tClasses.root}>
      <h3>Rounds</h3>
      <div className={tClasses.tableContainer}>
        <table className={tClasses.table}>
          <thead className={tClasses.tableHead}>
          <tr>
            <th>ROUND #</th>
            <th>DATE</th>
            <th>NETWORK</th>
            <th>CURRENCY</th>
            <th>YOUR TICKETS</th>
            <th>DETAILS</th>
          </tr>
          </thead>

          <tbody>
          {
            rounds.map((round, idx) => {
              return (
                <TableRow key={round.id + idx} round={round} onDetails={showLotteryInfo} />
              );
            })
          }
          </tbody>
        </table>


        <LotteryInfoModal
          lotteryData={lotteryData}
          visible={!!lotteryData}
          lotteryId={currentId ?? ''}
          onCancel={closeInfoModal}
          onClaim={onClaim}
        />
      </div>
    </div>
  );
};


const TableRow: FC<TableRowProps> = ({ round, onDetails }) => {

  const [loading, setLoading] = useState(false);

  const {
    lottery, totalTickets, network, token: { Icon, tokenName, lotteryAddress, tokenAddress },
  } = round;

  const date = new Date(+lottery.endTime * 1000);

  const onDetailsClick = async () => {
    try {
      setLoading(true);
      await onDetails({
        chainId: network.chainId,
        lotteryAddress,
        lotteryId: lottery.id,
        totalCake: round.totalCake ?? '',
        totalTickets,
        tokenAddress,
      });
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <tr className={tClasses.tableRow}>
      <td>{lottery.id}</td>
      <td className={classes.date}><small>{format(date, 'dd MMM yyyy')} <span>{format(date, 'HH:mm')}</span></small>
      </td>
      <td>
        <span className={tClasses.network}>
          <span className={tClasses.icon}>{network.networkImage}</span>
          <span>{network.networkName}</span>
      </span>
      </td>
      <td>
        <span className={tClasses.network}>
          <span className={tClasses.icon}>{Icon && <Icon />}</span>
          <span>{tokenName}</span>
        </span>
      </td>
      <td>{totalTickets}</td>
      <td>
        <div className={classes.detailsCol}>
          <Button size={'small'} ghost className={classes.details}
                  disabled={loading}
                  onClick={onDetailsClick}
          >Show more<RightOutlined /></Button>
        </div>
      </td>
    </tr>
  );
};

export default HistoryTab;
