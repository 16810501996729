import { useMemo } from 'react';
import { menuConfig } from '../constants';
import { useLocation } from 'react-router-dom';
import { ContractProps } from '../context/useLottery';
import { Token } from '../interfaces/lottery';
import paths from "../constants/paths";
import {useQuery} from "./useQuery";

export type LotteryViewParams = {
  lotteryPath: string | undefined;
};

export const useContractProps = (): ContractProps => {
  const { pathname } = useLocation();
  const query = useQuery();
  const tokenName = query.get('token') ?? undefined;
  // FIXME: change contract from outside

  return useMemo(() => {
    let lotteryPath: string;
    if (pathname===paths.IFRAME && tokenName){
      lotteryPath = tokenName;
    } else {
      lotteryPath = pathname?.replace('/', '');
    }

    const { tokens, chainId, networkName } =
      menuConfig?.find(({ tokens }) =>
        tokens.some(({ route }) => route === lotteryPath)
      ) ?? {};

    const token = tokens?.find(({ route }) => lotteryPath === route);

    return {
      networkName,
      chainId,
      ...((token ?? {}) as Token),
    };
  }, [pathname,tokenName]);
};
