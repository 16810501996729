import classes from './styles.module.css';
import { Skeleton } from 'antd';

const ParagraphSkeleton = ({ rows = 1 }) => (
  <Skeleton
    className={classes.paragraph}
    loading
    title={false}
    paragraph={{ rows }}
  />);

export default ParagraphSkeleton;
