import classes from './styles.module.css';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'components/themed-button';

import { ReactComponent as TicketStar } from 'resources/svg/icons/ticket-star.svg';
import { ReactComponent as Trophy } from 'resources/svg/icons/trophy.svg';
import { parseRetrievedNumber } from 'functions/helpers';
import HistoryNumbers from '../history-numbers';
import { LotteryData } from '../history';
import { getUserTicketData } from 'hooks/useGetUnclaimedRewards';
import { useWallet } from 'wallets/wallet';
import { useLottery } from 'context/useLottery';
import { LotteryTicketClaimData, TicketNumber } from 'interfaces/lottery';

interface LotteryInfoProps {
  preload: boolean;
  unclaimed: LotteryTicketClaimData[] | null;
  lottery: any;
  getLotteryInfoOnPress: (id: string) => void;
  lotteryData: LotteryData | undefined;
  claimUnclaimedRewards: (id: string) => void;
}


const LotteryInfo: FC<LotteryInfoProps> = (
  {
    preload, unclaimed,
    lottery, getLotteryInfoOnPress, lotteryData, claimUnclaimedRewards,
  },
) => {
  const { account } = useWallet();

  const { contractLottery } = useLottery();

  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numbers, setNumbers] = useState<TicketNumber[]>([]);
  const [winningTicketsQty, setWinningTicketsQty] = useState(0);
  const [hasUnclaimedRewards, setHasUnclaimedRewards] = useState(false);

  const [ticketsToCheck, setTicketsToCheck] = useState<string[]>([]);

  const winningNumbers = useMemo(() => {
    if (lotteryData?.finalNumber === '0' || !lotteryData?.finalNumber)
      return null;

    return parseRetrievedNumber(lotteryData.finalNumber);
  }, [lotteryData?.finalNumber]);

  useEffect(() => {
    if (winningNumbers) {
      const result =
        lotteryData?.events.id.map((id, ind) => {
          const ticket: string = parseRetrievedNumber(
            lotteryData.events.number[ind],
          );

          let lastIndex = -1;
          for (let i = 0; i < ticket.length; i++) {
            if (winningNumbers[i] === ticket[i]) {
              lastIndex++;
            } else {
              i = ticket.length;
            }
          }

          if (lastIndex >= 0) {
            setTicketsToCheck((prevState) => [...prevState, id]);
          }

          return {
            id,
            win: lastIndex.toString(),
            number: ticket,
          };
        }) ?? [];

      const counter = result.filter((item) => +item.win >= 0).length;
      setWinningTicketsQty(counter);
      setNumbers(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winningNumbers, lotteryData?.events]);

  const getLotteryData = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        await getLotteryInfoOnPress(id);

        setExpanded(true);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [getLotteryInfoOnPress],
  );

  const expandLotteryData = async () => {
    try {
      setIsLoading(true);
      await getLotteryData(lottery.lotteryId);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const hideLotteryData = () => {
    setWinningTicketsQty(0);
    setExpanded(false);
  };

  const claimRewards = () => claimUnclaimedRewards(lottery.lotteryId);

  useEffect(() => {
    if (preload) {
      setIsLoading(true);
      getLotteryData(lottery.lotteryId)
        .catch(console.log)
        .finally(() => setIsLoading(false));
    }
  }, [preload, lottery.lotteryId, getLotteryData]);

  // FIXME: remove?
  //  need to check the input props
  //  if this data is equal to lotteryData.winningTickets - can be removed

  useEffect(() => {
    if (account && contractLottery) {
      setIsLoading(true);
      getUserTicketData({
        contractLottery,
        idsToCheck: [lottery.lotteryId],
        account,
      })
        .then((res) => {
          setHasUnclaimedRewards(
            !res[0]?.userTickets?.status?.some((status: any) => status),
          );
        })
        .catch(console.log)
        .finally(() => setIsLoading(false));
    }
  }, [contractLottery, account, ticketsToCheck, lottery.lotteryId, unclaimed]);

  if (lottery.status === 'open') {
    return null;
  }

  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <tbody>
        <tr className={classes.head}>
          <td>{lottery.lotteryId}</td>
          <td>{format(new Date(lottery.endTime * 1000), 'd MMM. yyyy')}</td>
          <td>{lottery.totalTickets}</td>
        </tr>
        </tbody>
      </table>

      {expanded && (
        <>
          <div className={classes.info}>
            <p className={classes.infoRow}>
              <span className={classes.icon}>
                <TicketStar aria-hidden />
              </span>
              <span className={classes.text}>Total Tickets:</span>
              <span>{lottery?.totalTickets ?? 0}</span>
            </p>
            <p className={classes.infoRow}>
              <span className={classes.icon}>
                <Trophy aria-hidden />
              </span>
              <span className={classes.text}>Winner Tickets:</span>
              <span>{winningTicketsQty}</span>
            </p>
          </div>
        </>
      )}

      <div className={classes.content}>
        {expanded && winningNumbers && (
          <HistoryNumbers
            title={'Winning Number'}
            numbers={{ win: winningNumbers }}
            winning
          />
        )}
        {expanded &&
        numbers.map(({ id, win, number }) => (
          <HistoryNumbers
            key={id}
            title={`#${id}`}
            numbers={{ win, ticket: number }}
          />
        ))}

        <div>
          <div className={classes.buttons}>
            {lotteryData?.winningTickets && hasUnclaimedRewards && (
              <Button
                size={'small'}
                success
                onClick={claimRewards}
                disabled={isLoading}
              >
                Claim
              </Button>
            )}
            {!expanded ? (
              <Button
                ghost
                size={'small'}
                onClick={expandLotteryData}
                disabled={isLoading}
              >
                Show more
              </Button>
            ) : (
              <Button
                ghost
                muted
                size={'small'}
                onClick={hideLotteryData}
                disabled={isLoading}
              >
                Hide
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LotteryInfo;
