import { useState, useEffect, ChangeEvent, useCallback, useMemo, FC } from 'react';
import BigNumber from 'bignumber.js';
import Info from 'resources/svg/icons/info-question.svg';
import { ReactComponent as LotteryTicket } from 'resources/svg/icons/ticket.svg';
import ticketStar from 'resources/svg/icons/ticketStar.svg';
import { ModalProps } from 'antd/lib/modal';
import { useWallet } from 'wallets/wallet';

import { numberWithCommas } from 'functions/helpers';

import { MAX_UINT_256, getHumanValue, ZERO_BIG_NUMBER } from 'web3/utils';

import EditTicketModal from '../edit-ticket-numbers-modal';

import s from './styles.module.css';
import { useLottery } from 'context/useLottery';
import { getRandomNumberString } from 'functions/utils';
import ModalHeader from '../components/modal-header';
import ModalBody from '../components/modal-body';
import Button from '../../themed-button';
import cx from 'classnames';
import { Modal } from 'antd';
// import { getDecimalsByLotteryAddress } from '../../../functions/utils';

export type BuyTicketModalProps = ModalProps & {
  walletBalance: number;
  getLotteryEvents: Function;
  setVisibility: () => void;
  enabled: boolean;
  decimals: number;
};

const BuyTicketModal: FC<BuyTicketModalProps> = ({
                                                   onCancel, decimals, getLotteryEvents,
                                                   enabled, setVisibility, walletBalance,
                                                   ...modalProps
                                                 },
) => {
  const wallet = useWallet();
  const { account } = wallet;

  const {
    contractLottery,
    lottery: lotteryInfo,
    isTransitioning,
    swappContract,
    lotteryId,
    token: { lotteryAddress, tokenSymbol },
  } = useLottery();

  const [enabling, setEnabling] = useState<boolean>(false);
  const [ticketNumber, setTicketNumber] = useState<string>('0');
  const [maxTicketNumber, setMaxTicketNumber] = useState<number>(0);
  // const [ticketCost, setTicketCost] = useState<number>(0);
  const [ticketDiscountSum, setTicketDiscountSum] = useState<number>(0);
  const [ticketDiscountPercent, setTicketDiscountPercent] = useState<number>(0);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [editModal, setEditModal] = useState<boolean>(false);


  const ticketCost = +getHumanValue(
    new BigNumber(lotteryInfo?.priceTicketInTokens ?? ZERO_BIG_NUMBER),
    decimals,  //18 //getDecimalsByLotteryAddress(lotteryAddress)
  )!;

  useEffect(() => {
    setTicketNumber('1');
  }, []);

  useEffect(() => {
    if (contractLottery?.methods) getMaxTicketsAvailible().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractLottery?.methods]);

  // useEffect(() => {
  //   console.log('sdfsdfsdf---------');
  //   const d = getDecimalsByLotteryAddress(lotteryAddress);
  //   console.log('d', d);
  //   // @ts-ignore
  //   const ticketPriceInTokens = +getHumanValue(
  //     new BigNumber(lotteryInfo?.priceTicketInTokens) ?? ZERO_BIG_NUMBER,
  //     18
  //   );
  //   console.log('ticketPriceInTokens', ticketPriceInTokens)
  //   setTicketCost(ticketPriceInTokens);
  // }, [props.decimals, lotteryInfo, lotteryAddress]);

  const getMaxTicketsAvailible = async () => {
    const maxTicketsAmount = await contractLottery?.methods
      .maxNumberTicketsPerBuyOrClaim()
      .call();
    setMaxTicketNumber(maxTicketsAmount);
  };

  const getDiscount = useCallback(async () => {
    if (!isTransitioning && account && lotteryInfo.discountDivisor) {
      const discount = await contractLottery?.methods
        .calculateTotalPriceForBulkTickets(
          lotteryInfo.discountDivisor,
          lotteryInfo.priceTicketInTokens,
          ticketNumber,
        )
        .call({ from: account });

      const totalPay = +getHumanValue(
        new BigNumber(discount ?? ZERO_BIG_NUMBER),
        decimals,
      )!;
      setTotalPay(totalPay);
    }
  }, [
    lotteryInfo,
    account,
    isTransitioning,
    ticketNumber,
    contractLottery?.methods,
    decimals,
  ]);

  useEffect(() => {
    if (+ticketNumber > 0) {
      getDiscount().catch();
    } else {
      setTotalPay(0);
    }
  }, [ticketNumber, getDiscount]);

  useEffect(() => {
    setTicketDiscountSum(+ticketNumber * ticketCost - totalPay);

    const discount = +(
      ((+ticketNumber * ticketCost - totalPay) * 100) /
      totalPay
    ).toFixed(2);
    setTicketDiscountPercent(
      !isNaN(discount) && discount !== Infinity ? discount : 0,
    );
  }, [totalPay, ticketCost, ticketNumber]);

  const approveSendSwapp = (
    value: BigNumber,
    address: string,
  ): Promise<any> => {
    if (!account) {
      return Promise.reject();
    }

    swappContract?.setProvider(wallet.provider);
    return swappContract?.methods
      .approve(
        address ? address : process.env.REACT_APP_CONTRACT_STAKING_SWAPP_ADDR,
        value,
      )
      .send({ from: account });
  };

  const handleTokenEnable = async () => {
    try {
      setEnabling(true);
      await approveSendSwapp(MAX_UINT_256, lotteryAddress!);
      getLotteryEvents();
      setEnabling(false);
    } catch (e) {
      setEnabling(false);
    }
  };

  const buyTicket = async () => {
    const commonArray: string[] = [];

    for (let i = 0; i < +ticketNumber; i++) {
      let ticketString = getRandomNumberString();
      ticketString += '1';
      ticketString = ticketString.split('').reverse().join('');
      commonArray.push(ticketString);
    }

    try {
      setEnabling(true);
      const storedReferral = localStorage.getItem('referral');
      const referralAddress = storedReferral
        ? storedReferral
        : '0x0000000000000000000000000000000000000000';
      contractLottery?.setProvider(wallet.provider);
      await contractLottery?.methods
        .buyTickets(lotteryId, commonArray, referralAddress)
        .send({ from: account });
    } catch (e) {
      console.log('e', e);
    } finally {
      setVisibility();
      closeEditModal();
      setEnabling(false);
      setTicketNumber('1');
    }
  };

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const reg = /^-?\d*(\.\d*)?$/;
      if (
        (!isNaN(+value) && reg.test(value) && +value <= maxTicketNumber) ||
        value === '' ||
        value === '-'
      ) {
        setTicketNumber(value);
      }
    },
    [maxTicketNumber],
  );

  const editNumbers = () => {
    setEditModal(true);
  };

  const closeEditModal = useCallback(() => {
    setEditModal(false);
  }, []);

  const onSuccessPay = useCallback(() => {
    setVisibility();
    setTicketNumber('1');
  }, [setVisibility]);

  const onModalClose = useCallback((props: any) => {
    if (typeof onCancel === 'function') onCancel(props);
    setTicketNumber('1');
  }, [onCancel]);

  const insufficientBalance = useMemo(() => +totalPay > +walletBalance, [
    totalPay,
    walletBalance,
  ]);
  const sumWithoutDiscount = useMemo(() => +ticketNumber * ticketCost ?? 0, [
    ticketNumber,
    ticketCost,
  ]);

  return (
    <Modal
      className={s.component}
      centered
      closable={true}
      footer={null}
      onCancel={onModalClose}
      {...modalProps}
    >
      <ModalHeader title={'Buy Tickets'} />

      <ModalBody>
        <div className={s.wrapper}>
          <div className={s.buyTicketWrap}>
            <div className={s.infoItem}>Buy:</div>
            <div className={s.infoItem}>
              Tickets <img src={ticketStar} alt='tickets' />
            </div>
          </div>
          <div className={cx(s.summary, insufficientBalance && s.danger)}>
            <input
              value={ticketNumber}
              className={s.sum}
              placeholder='0'
              onChange={onChangeInput}
            />
            {wallet.isActive && (
              <span className={s.sumSammarry}>
                ~{numberWithCommas(sumWithoutDiscount.toFixed(2))} {tokenSymbol}
              </span>
            )}
          </div>

          <div className={s.balance}>
            {insufficientBalance && (
              <p className={s.danger}>Insufficient {tokenSymbol} balance</p>
            )}
            <span>
              {tokenSymbol ?? ''} Balance:{' '}
              {numberWithCommas(walletBalance.toFixed(2))}
            </span>
          </div>
          <div className={s.info}>
            <div className={s.totalPay}>
              <span className={s.infoItem}>Cost ({tokenSymbol}):</span>
              <span className={s.infoItem}>
                {ticketCost} {tokenSymbol}
              </span>
            </div>
            {wallet.isActive && (
              <>
                <div className={s.totalPay}>
                  <span className={s.infoItem}>
                    <strong>{numberWithCommas(ticketDiscountPercent)}%</strong>{' '}
                    bulk discount:
                    <span className={s.infoItemInfo}>
                      <img src={Info} alt='React Logo' />
                    </span>
                  </span>
                  <span className={s.infoItem}>
                    {ticketDiscountSum.toFixed(2)} {tokenSymbol}
                  </span>
                </div>
                <div className={s.totalPay}>
                  <span className={s.infoItem}>You pay</span>
                  <span className={s.infoItem}>
                    {numberWithCommas(totalPay.toFixed(2))} {tokenSymbol}
                  </span>
                </div>
              </>
            )}
          </div>
          {account ? (
            <Button
              primary
              loading={enabling}
              disabled={insufficientBalance || +ticketNumber < 1}
              className={s.walletButton}
              fullWidth
              onClick={() =>
                enabled ? buyTicket() : handleTokenEnable()
              }
            >
              {enabled ? (
                <span className={s.buttonTitle}>
                  <LotteryTicket />
                  Buy ticket instantly
                </span>
              ) : (
                'Enable'
              )}
            </Button>
          ) : (
            <Button primary fullWidth onClick={wallet.showWalletsModal}>
              Connect Wallet
            </Button>
          )}
          {enabled && wallet.isActive && (
            <Button
              fullWidth
              loading={enabling}
              disabled={insufficientBalance || +ticketNumber < 1}
              onClick={editNumbers}
            >
              View / Edit Numbers
            </Button>
          )}
          <p>
            <small className={s.walletInfo}>
              "Buy Instantly" chooses random numbers, with no duplicates among
              your tickets. Prices are set before each round starts, equal to $5
              at that time. Purchases are final.
            </small>
          </p>
          <EditTicketModal
            visible={editModal}
            closeModal={closeEditModal}
            onCancel={closeEditModal}
            totalPay={totalPay}
            ticketNumber={ticketNumber}
            onSuccessPay={onSuccessPay}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BuyTicketModal;
