import { ReactComponent as ETHSvg } from 'resources/svg/tokens/eth.svg';
import { ReactComponent as BinanceSvg } from 'resources/svg/tokens/binance.svg';
import { ReactComponent as PolygonSvg } from 'resources/svg/tokens/polygon.svg';
import { ReactComponent as CronosSvg } from 'resources/svg/tokens/cronos.svg';
import { FC, memo } from 'react';

export enum Icons {
  ETHEREUM = 'Ethereum',
  POLYGON = 'Polygon',
  BINANCE = 'BSC',
  CRONOS = 'Cronos',
}


interface IconSelectorProps {
  name: Icons[keyof Icons];
}

const IconSelector: FC<IconSelectorProps & Partial<Element>> = memo(({ name, ...props }) => {
  switch (name) {

    case Icons.ETHEREUM:
      return <ETHSvg {...props} />;

    case Icons.POLYGON:
      return <PolygonSvg {...props} />;

    case Icons.BINANCE:
      return <BinanceSvg {...props} />;

    case Icons.CRONOS:
      return <CronosSvg {...props} />;

    default:
      return null;
  }
});


export default IconSelector;
