import classes from './styles.module.css';
import React, { DetailedHTMLProps, FC } from 'react';

import { ReactComponent as Background } from 'resources/svg/static/title-backdrop.svg';
import cx from 'classnames';

interface ComponentProps {
  title: React.ReactNode;
  align?: 'center' | 'left' | 'right';
}

interface Interface extends FC<ComponentProps> {
  ContainedContent: FC<DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
  FlowContent: FC<DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
}

const InfoSection: Interface = ({ title, align= 'center', children }) => {

  const style = cx(classes.root, {
    [classes.left]: align === 'left',
    [classes.right]: align === 'right',
    [classes.center]: align === 'center',
  });

  return (
    <section className={style}>
      <div className={classes.title}>
        <Background className={classes.titleBackground} />
        <h2>{title}</h2>
      </div>
      {children}
    </section>
  );
};


InfoSection.FlowContent = ({ className, ...rest }) => (
  <div className={cx(classes.containerFluid, className)} {...rest} />
);

InfoSection.ContainedContent = ({ className, ...rest }) => (
  <div className={cx(classes.container, className)} {...rest} />
);


export default InfoSection;

