import React from 'react';
import RulesSection from './components/rules';
import CriteriaSection from './components/criteria';
import FundsSection from './components/funds';

const LotteryView: React.FunctionComponent = () => {
  return (
    <>
      <RulesSection />
      <CriteriaSection />
      <FundsSection />
      {/* <Divider /> */}
      {/* <HelpSection/> */}
    </>
  );
};

export default LotteryView;
