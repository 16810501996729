import { FC, useState } from 'react';
import { message, Upload } from 'antd';
import cx from 'classnames';
import classes from './styles.module.css';
import Button from '../themed-button';
import { RcCustomRequestOptions } from 'antd/es/upload/interface';
import { avatarUpload } from '../../services/user.service';
import {useProfile} from "../../context/useProfile";

interface AvatarUploadProps {
  className?: string;
  sizeLimit?: number;
  onSuccess: () => any;
}

function getBase64(img: File, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: File, limit = 0.4) {
  const isImage = file.type.match('image');

  if (!isImage) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLtLimit = file.size / 1024 / 1024 < limit;

  if (!isLtLimit) {
    message.error(`Image must smaller than ${limit}MB!`);
  }
  return !!isImage && isLtLimit;
}


const AvatarUpload: FC<AvatarUploadProps> = ({ sizeLimit = 0.4, className }) => {

  const [loading, setLoading] = useState(false);
  const [/*imageUrl*/, setImageUrl] = useState<string>();
  const { updateUser } = useProfile();

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url: string) => {
          setImageUrl(url);
          setLoading(false);
        },
      );
    }
  };

  const upload = async ({ file }: RcCustomRequestOptions) => {
    try {
      await avatarUpload(file);
      await updateUser({});
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const before = (file: File) => beforeUpload(file, sizeLimit);

  return (
    <Upload
      name='avatar'
      listType='text'
      className={classes.upload}
      showUploadList={false}
      customRequest={upload}
      beforeUpload={before}
      onChange={handleChange}
    >
      <Button size={'large'} loading={loading} className={cx(classes.button, className)}>Upload</Button>
    </Upload>
  );
};


export default AvatarUpload;
