import tClasses from './../table.module.css';
import classes from './styles.module.css';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useNetworks } from 'hooks/useNetworks';
import React, { FC, useCallback, useState } from 'react';
import { useHTTPContract, useLotteryContract } from 'hooks/useLotteryContract';
import { useWallet } from 'wallets/wallet';
import Button from 'components/themed-button';
import { RightOutlined } from '@ant-design/icons';
import { Network, Token } from 'interfaces/lottery';
import { sleep } from 'functions/helpers';
import { spliceToButch } from 'functions/splice- to-butch';
import ReferralsDataInfo from 'components/modals/referrals-data-info';
import { useSwitchChainForClaim } from 'hooks/useSwitchChainForClaim';
import { getReferrersReward } from 'functions/referrals';

interface TableRowProps {
  onDetails: (props: GetRefsRewards) => Promise<void>;
  data: Ref;
  busy?: boolean;
}

interface ReferrersResponse {
  referrers: string;
  chainId: number;
}

export interface RoundsWithRewards {
  round: number;
  rewards: string;
  tokenName: string;
  chainId: number;
  lotteryAddress: string;
}


interface GetRefsRewards {
  token: Token;
  network: Omit<Network, 'tokens'>;
}

type Ref = GetRefsRewards & ReferrersResponse


export interface ClaimRewardsProps {
  rewards: RoundsWithRewards[];
}


const roundsToCheck = 20;
const limit = 5;


function createIdsArray(length: number, value: number): number[] {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr[i] = value--;
  }
  return arr;
}

const ReferralTab = () => {

    const networks = useNetworks();
    const contract = useHTTPContract();

    const changeNetworkRequest = useSwitchChainForClaim();

    const setContract = useLotteryContract();
    const { account, provider } = useWallet();

    const [loading, setLoading] = useState(false);
    const [refList, setRefList] = useState<Ref[]>([]);
    const [roundsWithRewards, setRoundsWithRewards] = useState<RoundsWithRewards[]>([]);
    const [lotteryChecked, setLotteryChecked] = useState(false);

  useAsyncEffect(async () => {
      try {
        let data: Ref[] = (await Promise.all(
          networks.map((item) => {

            return Promise.all(
              item.tokens.map(async (token) => {

                const {
                  contract: contractLottery,
                } = await contract({
                  chainId: +item.chainId,
                  lotteryAddress: token.lotteryAddress,
                });

                const referrers: string = await contractLottery?.methods
                  .referrers(account).call();


                const { tokens, ...network } = item;
                return {
                  referrers,
                  chainId: +item.chainId,
                  token,
                  network: network as any,

                } as Ref;
              }));
          })))
          .flat()
          .filter(({ referrers }) => !!+referrers);

        setRefList(data);
      } catch (e) {
        console.error('Referral tab error', e);
      }

    }, [networks, account, contract]);


    const onDetailsClick = useCallback(async ({ network, token }: GetRefsRewards) => {
      try {
        if (account) {

          setLoading(true);

          const { chainId } = network;
          const { lotteryAddress, tokenName } = token;

          const { contract: lotteryContract } = await contract({
            chainId,
            lotteryAddress,
          });

          const lotteryId = await lotteryContract?.methods.currentLotteryId().call();

          const last = lotteryId - 1; // current lottery status is 'Open'
          const idsArray = last > roundsToCheck ? createIdsArray(roundsToCheck, last) : createIdsArray(last, last);

          const checkWithLimit = async (arr: number[][]): Promise<RoundsWithRewards[]> => {
            const _temp: RoundsWithRewards[][] = [];

            for await (const item of arr) {
              const data = await Promise.all(
                item.map(async (id) => ({
                    round: id,
                    tokenName,
                    chainId,
                    lotteryAddress,
                    rewards: await getReferrersReward(
                      { round: id, contract: lotteryContract as any, account },
                    ),
                  }),
                ),
              ).catch();
              _temp.push(data);
              // network throttle
              await sleep(500);
            }
            return _temp.flat();
          };

          const withRewards = ((await checkWithLimit(spliceToButch(idsArray, limit))) ?? [])
            .filter(({ rewards }) => rewards && rewards !== '0');

          setRoundsWithRewards(withRewards);
          setLotteryChecked(true);
        }
      } catch (e) {
        console.log('ReferralTab onDetailsClick error: ', e);
      } finally {
        setLoading(false);
      }
    }, [contract, account]);


    const claimRewards = useCallback(async ({ rewards }: ClaimRewardsProps) => {

      try {

        const { chainId, lotteryAddress } = rewards[0];

        const { contractLottery } = await setContract({
          chainId,
          lotteryAddress,
          switchChain: false,
        });

        await contractLottery?.setProvider(provider);
        const roundsIds = rewards.map(({ round }) => +round);

        await contractLottery?.methods
          .claimRewardsBatch(roundsIds)
          .send({ from: account });

      } catch (e) {
        console.error('claimRewards', e);
      }
    }, [setContract, account, provider]);


    const onModalCancel = useCallback(() => setLotteryChecked(false), []);

    const claim = useCallback(async (rewards: RoundsWithRewards[]) => {
      try {
        setLoading(true);
        await changeNetworkRequest(rewards[0].chainId);
        await claimRewards({ rewards });
        onModalCancel();
      } catch (e) {
        console.log('claim rewards error: ', e);
      } finally {
        setLoading(false);
      }
    }, [changeNetworkRequest, claimRewards, onModalCancel]);

    return (
      <div className={tClasses.root}>
        <h3>Referrals</h3>
        <div className={tClasses.tableContainer}>
          <table className={tClasses.table}>
            <thead className={tClasses.tableHead}>
            <tr>
              <th>NETWORK</th>
              <th>Currency</th>
              <th>Referrals</th>
              <th>DETAILS</th>
            </tr>
            </thead>

            <tbody>
            {
              !!refList.length && refList.map((data, idx) => {
                return (
                  <TableRow key={data.chainId + idx}
                            data={data}
                            onDetails={onDetailsClick} busy={loading} />
                );
              })
            }
            </tbody>
          </table>
        </div>
        <ReferralsDataInfo visible={lotteryChecked} data={roundsWithRewards}
                           onCancel={onModalCancel} onClaim={claim} />
      </div>
    );
  }
;


const TableRow: FC<TableRowProps> = (
  { onDetails, busy, data: { token, referrers, network } }) => {

  const { tokenName, Icon } = token;
  const [loading, setLoading] = useState(false);

  const onDetailsClick = async () => {
    try {
      setLoading(true);
      await onDetails({ network, token });
    } finally {
      setLoading(false);
    }
  };

  return (
    <tr className={tClasses.tableRow}>
      <td>
        <span className={tClasses.network}>
          <span className={tClasses.icon}>{network.networkImage}</span>
          <span>{network.networkName}</span>
      </span>
      </td>
      <td>
        <span className={tClasses.network}>
          <span className={tClasses.icon}>{Icon && <Icon />}</span>
          <span>{tokenName}</span>
        </span>
      </td>
      <td>{referrers}</td>
      <td>
        <div className={classes.detailsCol}>
          <Button size={'small'} ghost className={classes.details}
                  disabled={loading || busy}
                  onClick={onDetailsClick}
          >Show more<RightOutlined /></Button>
        </div>
      </td>
    </tr>
  );
};

export default ReferralTab;
