import { useCallback, useEffect, useState } from 'react';
import { Contract } from 'web3-eth-contract';
import { useLotteryContract } from './useLotteryContract';
import { getLotteryInfo } from '../functions/get-lottery-data';
import { useContractProps } from './useContractProps';
import Web3 from 'web3';
import { ExtendContractProps } from '../interfaces/lottery';

export const useLotteryData = () => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [contractLottery, setContractLottery] = useState<(Contract & ExtendContractProps) | undefined>();
  const [swappContract, setSwappContract] = useState<(Contract & ExtendContractProps) | undefined>();
  const [socketProvider, setSocketProvider] = useState<Web3 | undefined>();

  const [lotteryId, setLotteryId] = useState('');
  const [lottery, setLottery] = useState<any>({});

  const contractProps = useContractProps();
  const contract = useLotteryContract();

  const getInfo = useCallback(async () => {
    try {
      setIsTransitioning(true);
      const { chainId, lotteryAddress, tokenAddress } = contractProps;

      const {
        contractLottery: lotteryContract,
        swappContract: swapp,
        socketProvider: socket,
      } = await contract({
        chainId,
        lotteryAddress,
        tokenAddress,
      });

      setContractLottery(
        lotteryContract as (Contract & ExtendContractProps) | undefined,
      );
      setSwappContract(swapp as (Contract & ExtendContractProps) | undefined);
      setSocketProvider(socket);

      if (lotteryContract) {
        const currentId = await lotteryContract?.methods
          .currentLotteryId()
          .call();
        if (currentId) setLotteryId(currentId);

        const lotteryInfo = await getLotteryInfo({
          contractLottery: lotteryContract,
          lotteryId: currentId,
        });
        if (lotteryInfo) setLottery(lotteryInfo);
      }
    } catch (e) {
      console.log('Get lottery info error', e);
    } finally {
      setIsTransitioning(false);
    }
  }, [contract, contractProps]);

  useEffect(() => {
    getInfo().catch();
  }, [getInfo]);

  return {
    isTransitioning,
    contractLottery,
    swappContract,
    lotteryId,
    lottery,
    token: contractProps,
    socketProvider,
  };
};
