import { createContext, FC, useContext, useMemo } from 'react';
import { useLotteryData } from '../hooks/useLotteryData';
import { Contract } from 'web3-eth-contract';
import { menuConfig } from '../constants';
import Web3 from 'web3';
import { ExtendContractProps, Network, Token } from '../interfaces/lottery';

export interface ContractProps
  extends Partial<Token>,
    Partial<Omit<Network, 'tokens'>> {
}

export interface UseLotteryContract {
  contractLottery: (Contract & ExtendContractProps) | undefined;
  swappContract: (Contract & ExtendContractProps) | undefined;
  socketProvider: Web3 | undefined;

}

export interface LotteryContextProvider extends UseLotteryContract {
  isTransitioning: boolean;
  lotteryId: string;
  lottery: any;
  tokens: string[];
  lotteryPaths: string[];
  token: ContractProps;
}

const initialProps: ContractProps = {
  lotteryAddress: undefined,
  graphUrl: undefined,
  tokenName: undefined,
  tokenAddress: undefined,
  tokenImgUrl: undefined,
  tokenPriceUrl: undefined,
  tokenSymbol: undefined,
  Icon: undefined,
  info: undefined,
};

const LotteryContext = createContext<LotteryContextProvider>({
  isTransitioning: false,
  contractLottery: undefined,
  swappContract: undefined,
  tokens: [],
  lottery: {},
  lotteryId: '',
  lotteryPaths: [],
  token: initialProps,
  socketProvider: undefined,
});

export const useLottery = () => useContext(LotteryContext);
export const LotteryProvider: FC = ({ children }) => {
  const lotteryPaths = useMemo(() => {
    return (
      menuConfig
        ?.map(({ tokens }) => tokens.map(({ route }) => `/${route}`))
        .filter((item) => !!item.length)
        .flat() ?? []
    );
  }, []);
    const tokens = useMemo(() => {
      return (
          menuConfig
              ?.map(({ tokens }) => tokens.map(({ route }) => route))
              .filter((item) => !!item.length)
              .flat() ?? []
      );
  }, []);

  const value = useLotteryData();

  return (
    <LotteryContext.Provider value={{ ...value, lotteryPaths, tokens }}>
      {children}
    </LotteryContext.Provider>
  );
};
