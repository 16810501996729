import React, { useState, useEffect, FC } from 'react';
import cx from 'classnames';
import { getNetworkName } from 'web3/utils';
import { Link } from 'react-router-dom';

import { ReactComponent as TicketSvg } from 'resources/svg/static/ticket.svg';
// import { ReactComponent as TimerSvg } from 'resources/svg/static/timer.svg';

import InfoSection from 'components/info-section';
import Dropdown from 'components/dropdown';
// import Button from 'components/themed-button';

import s from './styles.module.css';

interface HotestLotteriesProps {
  lotteryList: any;
}

const HotLotteries: FC<HotestLotteriesProps> = ({ lotteryList }) => {

  const [selected, setSelected] = useState('all');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (lotteryList.length > 0) {
      let options: any = [{ value: 'all', label: 'All' }];
      lotteryList.map((item: { tokenStatus: any }) => {
        if (
          !options.some((i: { label: any }) => i.label === item.tokenStatus)
        ) {
          options.push({
            value: item.tokenStatus.toLowerCase(),
            label: item.tokenStatus,
          });
        }
        return options;
      });

      setOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryList]);

  const handleSelect = (value: string | number) => {
    setSelected(String(value));
  };

  return (
    <div className={s.section}>
      <InfoSection title="Hottest Tickets">
        <p className={s.description}/>
      </InfoSection>
      <div className={s.topPanel}>
        <div className={s.sortBy}>
          <span>Sort By</span>
          <Dropdown
            className={s.dropdown}
            items={options}
            selected={selected}
            onSelect={handleSelect}
          />
        </div>
        {/* <div className={s.search}>
          <span>Search</span>
          <Search placeholder="Search Tickets" loading={false} enterButton />
        </div> */}
      </div>
      <div className={s.tickets}>
        {lotteryList.length > 0 &&
        lotteryList.map((token: any) => {
          if (
            selected === 'all' ||
            (selected !== 'all' &&
              token.tokenStatus.toLowerCase() === selected)
          ) {
            const { Icon } = token;
            return (
              <div
                className={s.ticket}
                key={token.route}
                style={{ border: `1px ${token.tokenColorSecond} solid` }}
              >
                <div className={s.topInfo}>
                  <div className={s.left}>
                    {
                      !!token.tokenStatus &&
                      <div
                        className={cx(
                          s.new,
                          token.tokenStatus === 'New' ? s.green : s.yellow,
                        )}
                      >
                        {token.tokenStatus}
                      </div>
                    }
                    <div className={s.network}>
                      {token.networkImage}
                      {getNetworkName(token.chainId)}
                    </div>
                  </div>
                  {/* <div className={s.timer}>
                        <TimerSvg /> 12:45:33
                      </div> */}
                </div>
                <div className={s.title}>
                  <div
                    className={s.background}
                    style={{ background: `${token.tokenColor}` }}
                  />
                  <div className={s.tokenImage}>
                    <div
                      style={{
                        background: `${token.tokenColorSecond}`,
                      }}
                      className={s.tokenImageBackground}
                    >
                      <Icon className={s.heroIcon} />
                    </div>
                  </div>
                  <h3 className={s.tokenTitle}>{token.tokenName}</h3>
                </div>
                <div
                  className={s.mainInfo}
                  style={{
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 21.96%, ${token.tokenColorSecond} 100%)`,
                  }}
                >
                  <div className={s.row}>
                    <div className={s.rowTitle}>Prize Pot:</div>
                    <div className={cx(s.rowInfo, s.bold)}>
                      ${token.exchange}
                    </div>
                  </div>
                  <div className={s.row}>
                    <div className={s.rowTitle}>Winners:</div>
                    <div className={s.rowInfo}>Coming Soon</div>
                  </div>
                  <div className={s.row}>
                    <div className={s.rowTitle}>{`next draw #${token.lotteryId}:`}</div>
                    <div className={s.rowInfo}>{token.endTime}</div>
                  </div>
                  <div
                    className={s.playButton}
                    style={{
                      background: `${token.tokenColor}`,
                    }}
                    onClick={() => console.log('!!!')}
                  >
                    <Link to={token.route}>
                      <TicketSvg /> Buy Tickets
                    </Link>
                    <div
                      className={s.afterElement}
                      style={{ background: `${token.tokenColor}` }}
                    />
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default HotLotteries;
