import classes from './styles.module.css';
import AvatarUpload from 'components/avatar-upload';
import { Avatar } from 'antd';
import OutlinedTextField from 'components/outlined-textfield';
import Button from 'components/themed-button';
import { useCallback, useEffect, useState } from 'react';
import { useProfile } from 'context/useProfile';
import SocialNetworkInput from 'components/social-network-input';
import { UserProfileLinks } from 'interfaces/userProfile';
import { socialLinks, socialLinksIcons } from '../../../../../../constants';
import { UserDto, UserSocials } from 'interfaces/user.dto';
import { noop } from 'functions/utils';

const userLinks = (user: UserDto | null) => {
  if (user) {
    const linksArray: any = [];
    for (const link of socialLinks) {
      const name: keyof UserSocials = link.id.toLowerCase() as keyof UserSocials;
      const value = user[name];
      linksArray.push([name, { value: value ?? '', connected: false }]);
    }
    return Object.fromEntries(new Map(linksArray));
  }
  return {};
};

async function asyncNoop() {
  await  noop()
}

const SettingsTab = () => {

  const { user, updateUser, avatar, userSaving } = useProfile();

  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [email, setEmail] = useState(user?.email ?? '');

  /*TODO: change to react-hook-form*/
  const onLinkTextChange = useCallback((text: string, name: string) => {
    setLinks(prevState => ({
      ...prevState,
      [name]: {
        value: text,
      },
    }));
  }, []);

  useEffect(() => {
    setFirstName(user?.firstName ?? '');
    setLastName(user?.lastName ?? '');
    setEmail(user?.email ?? '');

    for (const link of socialLinks) {
      const name: keyof UserSocials = link.id.toLowerCase() as keyof UserSocials;
      if (user) {
        const value = typeof user[name] !== 'undefined' ? user[name] : '';
        onLinkTextChange(value ?? '', name);
      }
    }
  }, [user, avatar, onLinkTextChange]);

  const [links, setLinks] = useState<Partial<UserProfileLinks>>(() => userLinks(user));
  const [saving, setSaving] = useState(false);

  const socialLinksValues = () => {
    return Object.entries(links).reduce((acc, curr) => {
      const [name, value] = curr;
      return Object.assign(acc, { [name]: value?.value ?? '' });
    }, {});
  };

  const onSubmit = async () => {
    if (userSaving) return
    try {
      setSaving(true);
      await updateUser({ lastName, firstName, email, ...socialLinksValues() });
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };


  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Avatar size={120} className={classes.avatar} src={avatar} />
        </div>
        <div className={classes.headerContent}>
          <p className={classes.headerTitle}>Profile photo</p>
          <p className={classes.headerSubtitle}>
            We recommend an image of at least 400x400. Gifs work too 🙌
          </p>
          <AvatarUpload onSuccess={noop} />
        </div>
      </div>

      <form className={classes.form}>

        <div className={classes.row}>
          <OutlinedTextField
            onChange={noop}
            onChangeText={setFirstName}
            label={'First Name'}
            value={firstName}
          />

          <OutlinedTextField
            onChange={noop}
            value={lastName}
            onChangeText={setLastName}
            label={'Last Name'} />

        </div>

        <div className={classes.row}>
          <OutlinedTextField
            value={email}
            onChange={noop}
            onChangeText={setEmail}
            label={'Email'} />
        </div>


        {
          socialLinks.map(({ id }) => {

            const name = id.toLowerCase() as keyof UserProfileLinks;
            if (!name) return null;

            return (

              <SocialNetworkInput
                key={id}
                connected={links?.[name]?.connected || false}
                value={links?.[name]?.value ?? ''}
                rowClassName={classes.row}
                onChangeText={onLinkTextChange} label={id}
                name={name}
                icon={socialLinksIcons[id.toLowerCase() as keyof typeof socialLinksIcons]}

                // FIXME
                onConnect={asyncNoop}
                onShare={asyncNoop}
              />
            );
          })
        }
        <div className={classes.row}>
          <Button size={'large'} primary
                  onClick={onSubmit}
                  className={classes.button}
                  loading={saving}
                  disabled={userSaving}
          >Save changes</Button>
        </div>

      </form>


    </div>
  );
};

export default SettingsTab;

