import React, { FC, memo } from 'react';
import k from './styles.module.css';
import cx from 'classnames';

interface TimerPartProps {
  timeString: string;
}

/**
 *
 * @param timeString format XXh | XXm | XXs?
 * @constructor
 */
export const TimerPart: FC<TimerPartProps> = memo(({ timeString }) => {


  if (!timeString.match(/^[0-9]{2}[m|s|h]$/gm)) {
    return null;
  }

  const [num, num2, letter] = timeString.split('');

  return (
    <span className={k.timerPart}>
        <span className={k.wrapper}>
          <span className={k.num}>{num}</span>
        </span>

        <span className={k.wrapper}>
          <span>{num2}</span>
        </span>

        <span className={cx(k.wrapper, k.ltr)}>
          <span>{letter}</span>
        </span>
    </span>
  );
});

// export default TimerPart;
