import React, { FC } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Web3WalletProvider from 'wallets/wallet';

import ThemeProvider from 'components/theme-provider';

import LayoutView from 'views/layout';
import { CurrencyProvider } from './context/useCurrency';
import { LotteryProvider } from './context/useLottery';
import ProfileProvider from './context/useProfile';
import { BuildProviderTree } from './context/providerTree';
import UsersListProvider from './context/useUsersList';
import {IFrame} from "./views/iframe";
import paths from './constants/paths';

const Providers = BuildProviderTree([
  ThemeProvider,
  Web3WalletProvider,
  Router,
  LotteryProvider,
  CurrencyProvider,
  ProfileProvider,
  UsersListProvider,
]);

const App: FC = () => {
  return (
    <Providers>
      <Switch>
        <Route path={paths.IFRAME} component={IFrame} exact={true} />
        <Route path='/*' component={LayoutView} />
      </Switch>
    </Providers>
  );
};

export default App;
