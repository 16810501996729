import React, { useState, useEffect, useCallback, FC } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { useWallet } from 'wallets/wallet';

import { numberWithCommas, sleep } from 'functions/helpers';

import s from './styles.module.css';
import { useLottery } from 'context/useLottery';
import { getRandomNumberString } from 'functions/utils';
import capitalize from 'lodash/capitalize';
import { Button, Modal } from 'antd';

export type EditTicketModalProps = ModalProps & {
  totalPay: number;
  ticketNumber: string;
  closeModal: () => void;
  onSuccessPay: () => void;
};

type NumbersArray = {
  id: string;
  value: string;
};

const EditTicketModal: FC<EditTicketModalProps> = ({
                                                     ticketNumber, closeModal, totalPay,
                                                     onSuccessPay, ...modalProps
                                                   }) => {
  const wallet = useWallet();

  const {
    contractLottery,
    lotteryId,
    token: { tokenSymbol },
  } = useLottery();

  const [numbersArray, setNumbersArray] = useState<NumbersArray[][]>([]);
  const [disableProgress, setDisableProgress] = useState<boolean>(false);
  const [enabling, setEnabling] = useState<boolean>(false);

  useEffect(() => {
    numbersArray.forEach((item: any) => {
      item.forEach((digit: any) => {
        if (digit.value.length === 0) {
          setDisableProgress(true);
        }
      });
    });
  }, [numbersArray]);

  const getNumbers = useCallback(() => {
    setNumbersArray(
      Array.from(new Array(+ticketNumber), (_, i) => {
        const number = getRandomNumberString();

        return number.split('').map((value, index) => ({
          id: `${i}-${index}`,
          value,
        }));
      }),
    );
  }, [ticketNumber]);

  useEffect(() => {
    getNumbers();
  }, [getNumbers]);

  const changeNumber = (id: string, value: string) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (value.length < 2 && reg.test(value)) {
      const newArray = [...numbersArray];
      const str = id.split('-');
      newArray[+str[0]][+str[1]].value = value;
      setNumbersArray(newArray);
      setDisableProgress(false);
    }
  };

  const buyTicket = async () => {
    try {
      setEnabling(true);
      const storedReferral = localStorage.getItem('referral');
      const referralAddress = storedReferral
        ? storedReferral
        : '0x0000000000000000000000000000000000000000';
      const commonArray: string[] = numbersArray.map(
        (array) =>
          '1' +
          array
            .map(({ value }) => value)
            .reverse()
            .join(''),
      );

      contractLottery?.setProvider(wallet.provider);
      await contractLottery?.methods
        .buyTickets(lotteryId, commonArray, referralAddress)
        .send({ from: wallet.account });

      setEnabling(false);
      closeModal();
      await sleep(100);
      onSuccessPay();
    } catch (e) {
      setEnabling(false);
      console.log(EditTicketModal.name, buyTicket.name, e);
    }
  };

  return (
    <Modal
      className={s.component}
      centered
      closable
      destroyOnClose
      footer={null}
      {...modalProps}
    >
      <div className={s.headerLabel}>Edit numbers</div>
      <div className={s.totalPay}>
        <div className={s.infoItem}>Total Cost:</div>
        <span className={s.total}>
          ~{numberWithCommas(totalPay.toFixed(2))}{' '}
          {capitalize(tokenSymbol)}
        </span>
      </div>
      <div className={s.infoItem}>
        Numbers are randomized, with no duplicates among your tickets. Tap a
        number to edit it. Availible digits: 0-9
      </div>
      <Button
        type='ghost'
        className={s.backBtn}
        disabled={enabling}
        onClick={getNumbers}
      >
        Randomize
      </Button>

      <div className={s.numbersBlock}>
        {numbersArray.map((row: any, index: number) => {
          return (
            <div key={index}>
              <div className={s.rowTitle}>#{index + 1}</div>
              <div className={s.row}>
                {row.map((number: any) => {
                  return (
                    <div key={number.id}>
                      <input
                        className={s.sindleDigit}
                        value={number.value}
                        onChange={(e) =>
                          changeNumber(number.id, e.target.value)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Button
        type='ghost'
        loading={enabling}
        disabled={disableProgress}
        className={s.backBtn}
        onClick={buyTicket}
      >
        Confirm and Buy
      </Button>
      <Button
        type='ghost'
        className={s.backBtn}
        onClick={closeModal}
      >
        Go Back
      </Button>
    </Modal>
  );
};

export default EditTicketModal;
