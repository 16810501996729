import { gql, request } from 'graphql-request';
import { GetAllTicketsProps, GetAllTicketsReturnType, User } from './interfaces';


type GetAllTickets = (props: GetAllTicketsProps) => Promise<GetAllTicketsReturnType>

export const getAllTickets: GetAllTickets = async (
  { first, skip, where, account, url, signal }) => {

  let user: User = null;

  try {
    if (url) {
      const response = await request({
        url,
        document: gql`
					query getUserLotteries(
						$account: ID!
						$first: Int!
						$skip: Int!
						$where: Round_filter
					) {
						user(id: $account) {
							id
							totalTickets
							totalCake
							rounds(
								first: $first
								skip: $skip
								where: $where
								orderDirection: desc
								orderBy: block
							) {
								id
								lottery {
									id
									endTime
									status
									finalNumber
								}
								claimed
								totalTickets
							}
						}
					}
				`,
        variables: { account: account.toLowerCase(), first, skip, where },
        signal
      });

      user = response.user;
    }

  } catch (e: any) {
    console.log('getAllTickets error', e);
  }

  return {
    user,
  };
};
