import React, { FC, useState } from 'react';
import ModalHeader from '../components/modal-header';
import ModalBody from '../components/modal-body';
import { RoundsWithRewards } from 'views/profile/components/tabs/components/referral';
import classes from './style.module.css';
import { useCurrency } from 'context/useCurrency';
import { getHumanValue } from 'web3/utils';
import { BigNumber } from 'bignumber.js';
import Button from '../../themed-button';
import { ModalProps } from 'antd/es/modal';
import { Modal } from 'antd';

interface ReferralsDataInfoProps extends ModalProps {
  data: RoundsWithRewards[];
  onClaim: (props: RoundsWithRewards[]) => Promise<void>;
}

const ReferralsDataInfo: FC<ReferralsDataInfoProps> = ({ data, onClaim, visible, onCancel, ...props }) => {

  const { exchange } = useCurrency();
  const [loading, setLoading] = useState(false);

  const getPrize = (
    data: string,
    tokenName: string,
  ) => {
    const value = +getHumanValue(new BigNumber(data), 18)!;
    const usdValue = value * (exchange?.[tokenName.toLowerCase()]?.usd ?? 0);
    return `$${usdValue.toFixed(2)}`;
  };

  const claim = async () => {
    try {
      setLoading(true);
      if (typeof onClaim === 'function') await onClaim(data);
    } catch (e) {

    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      {...props}
      footer={null}
    >
      <ModalHeader title={data.length ? 'Collect rewards' : 'No rewards to claim!'} />
      <ModalBody>
        {
          data.length
            ? (
              <>
                <table className={classes.table}>
                  <thead className={classes.tableHead}>
                  <tr>
                    <th>ROUND #</th>
                    <th>REWARDS</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    data.map(({ round, rewards, tokenName}) => (
                        <tr key={round} className={classes.tableRow}>
                          <td>{round}</td>
                          <td>{getPrize(rewards, (tokenName))}</td>
                        </tr>
                      ),
                    )
                  }
                  </tbody>
                </table>

                <Button fullWidth success disabled={loading}
                        loading={loading} onClick={claim}>Claim</Button>
              </>
            )
            : (
              <>
                <div className={classes.empty}>
                  <h4>No rewards available</h4>
                </div>

                <Button fullWidth primary disabled={loading}
                        onClick={onCancel}>Close</Button>
              </>
            )
        }


      </ModalBody>
    </Modal>
  );
};

export default ReferralsDataInfo;
