import React, { FC } from 'react';
import ParagraphSkeleton from 'components/skeletons/paragraph-skeleton';
import { numberWithCommas } from 'functions/helpers';
import k from './styles.module.css';
import { useCurrency } from 'context/useCurrency';
import { exchangeUsdValue } from 'functions/utils';
import { useLottery } from 'context/useLottery';


interface PriceProps {
  value: number;
}

interface UsdPriceProps {
  value: number;
  prefix?: string;
}

export const PriceWithIcon: FC<PriceProps> = ({ value = 0 }) => {

  const { currencyFetching } = useCurrency();
  const { token: { Icon } } = useLottery();

  return (
    currencyFetching
      ? <ParagraphSkeleton />
      :
      (
        <>
          <span>{value !== undefined && numberWithCommas(value.toFixed(2))}</span>
          {Icon && (<> {' '} <Icon className={k.prizeIcon} /></>)}
        </>
      )
  );
};

export const UsdPrice: FC<UsdPriceProps> = ({ value, prefix }) => {
  const { currencyFetching, exchange } = useCurrency();
  const { token: { tokenFullName } } = useLottery();

  return (
    currencyFetching && tokenFullName
      ? <ParagraphSkeleton />
      /*FIXME: exchangeUsdValue*/
      : <span>{`${prefix}${(value * (exchangeUsdValue(exchange, tokenFullName?.toLowerCase()) ?? 0)).toFixed(2)}`}</span>
  );
};
