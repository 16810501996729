import React, { useCallback, useEffect, useState } from 'react';
import { Button, Menu } from 'antd';
import Burger from '@animated-burgers/burger-squeeze';
import cx from 'classnames';
import { useWallet } from 'wallets/wallet';
import { Link } from 'react-router-dom';
import { InfoSquare, Category, Document } from 'react-iconly';

import { menuConfig } from '../../constants';

// import SiderNavLink from 'components/sider-nav-link';
import FadeBlock from 'components/fade-block';
import IconSelector from '../../components/icon-selector';

import '@animated-burgers/burger-squeeze/dist/styles.css';

import { ReactComponent as LogoSvg } from 'resources/svg/logo.svg';
// import { ReactComponent as LotteryArrowsSvg } from '../../resources/svg/icons/lottery.svg';
import { ReactComponent as LotterySvg } from '../../resources/svg/icons/lottery.svg';
// import { ReactComponent as SwappSvg } from '../../resources/svg/tokens/swapp.svg';
import { ReactComponent as DashboardSvg } from 'resources/svg/icons/dashboard.svg';

import s from './styles.module.css';
// import { ReactComponent as MoonSvg } from 'resources/svg/icons/moon.svg';
// import { ReactComponent as SunSvg } from 'resources/svg/icons/sun.svg';
import { useLottery } from 'context/useLottery';
import paths from '../../constants/paths';

declare let window: any;

const { SubMenu } = Menu;


const saveNewChainId = (id: number = 1) => [
  {
    icon: <LotterySvg />,
    label: 'How It Works',
    path: paths.HOW_IT_WORKS,
  },
  {
    icon: <DashboardSvg />,
    label: 'Dashboard',
    path: paths.DASHBOARD,
  },
];


const MobileMenu: React.FunctionComponent = () => {
  const wallet = useWallet();
  const {
    token: { tokenSymbol },
  } = useLottery();

  // const { toggleDarkTheme, isDarkTheme } = useTheme();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [/*siderNavLinks*/, setiderNavLinks]: any = useState([]);

  const handleMenuClick = useCallback(() => setIsOpen((prevState) => !prevState), []);

  // function handleThemeToggle() {
  //   toggleDarkTheme();
  // }

  useEffect(() => {
    setiderNavLinks(saveNewChainId(wallet.networkId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.networkId]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('networkChanged', (id: number) => {
        setiderNavLinks(saveNewChainId(id));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      if (!document.body.classList.contains('mobile-menu-open')) {
        document.body.classList.add('mobile-menu-open');
      }
    } else {
      if (document.body.classList.contains('mobile-menu-open')) {
        document.body.classList.remove('mobile-menu-open');
      }
    }
  }, [isOpen]);

  return (
    <div className={s.component}>
      <Button type='link' className={s.menuBtn} onClick={handleMenuClick}>
        <Burger className={s.burger} isOpen={isOpen} />
      </Button>
      <FadeBlock className={s.logo} visible={!isOpen}>
        <LogoSvg />
        <span>{tokenSymbol}</span>
      </FadeBlock>
      <div className={cx(s.mask, { [s.maskOpen]: isOpen })} />
      <div className={cx(s.menuList, { [s.menuListOpen]: isOpen })}>
        <div className={s.innerLogo}>
          <LogoSvg />
          <span>{tokenSymbol}</span>
        </div>

        <Menu theme='dark' defaultSelectedKeys={['1']} mode='inline'>
          <Menu.Item
            key='dashboard'
            onClick={handleMenuClick}
            icon={<Category primaryColor={'var(--primary-theme-color)'} />}
          >
            <Link to={paths.DASHBOARD}>
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
          <span className={s.titleDivider}>LOTTERIES</span>
          {(menuConfig ?? []).map(({ networkName, chainId, tokens }) => (
            <SubMenu
              className={s.submenu}
              key={chainId}
              icon={<IconSelector name={networkName} />}
              title={networkName}
            >
              {tokens?.length ? (
                tokens.map((token) => (
                  <Menu.Item key={token.route}>
                    <Link
                      onClick={handleMenuClick}
                      className={s.tokenMenuItem}
                      to={`/${token.route}`}
                    >
                      <div className={s.tokenImageWrapper}>
                        {typeof token.tokenImgUrl === 'string' &&
                          token.tokenImgUrl && (
                            <img
                              className={s.tokenImage}
                              width={16}
                              height={16}
                              src={token.tokenImgUrl}
                              alt={token.tokenName}
                            />
                          )}
                      </div>
                      <span>{token.tokenName}</span>
                    </Link>
                  </Menu.Item>
                ))
              ) : (
                <Menu.Item key={networkName + chainId}>
                  <div className={s.tokenMenuItem}>
                    <div className={s.tokenImageWrapper} />
                    <span>Coming soon</span>
                  </div>
                </Menu.Item>
              )}
            </SubMenu>
          ))}

          <span className={s.titleDivider}>Other</span>
          <Menu.Item key='11' onClick={handleMenuClick} icon={<InfoSquare />}>
            <Link to={paths.HOW_IT_WORKS}>
              <span>How it works</span>
            </Link>
          </Menu.Item>

          <Menu.Item key='12' onClick={handleMenuClick} icon={<Document />}>
            <Link to={paths.CONTACTS}>
              <span>Contacts</span>
            </Link>
          </Menu.Item>
        </Menu>

        {/*<Button*/}
        {/*  type='link'*/}
        {/*  className={s.themeToggle}*/}
        {/*  onClick={handleThemeToggle}*/}
        {/*>*/}
        {/*  {!isDarkTheme ? <MoonSvg /> : <SunSvg />}*/}
        {/*  <FadeBlock visible={isOpen}>*/}
        {/*    {!isDarkTheme ? 'Dark' : 'Light'} Theme*/}
        {/*  </FadeBlock>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default MobileMenu;
