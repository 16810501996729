import { FC } from 'react';
import classes from './styles.module.css';


interface HistoryWrapperProps extends FC {
  Header: FC;
  Body: FC;
}


const HistoryWrapper: HistoryWrapperProps = ({children}) => {
  return (
    <>
    <div className={classes.root}>
      {children}
    </div>
    </>
  );
};

HistoryWrapper.Header = ({ children }) => (
  <div className={classes.header}>
    {children}
  </div>
);

HistoryWrapper.Body = ({ children }) => (
  <div className={classes.body}>{children}</div>
);


export default HistoryWrapper;
