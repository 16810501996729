import { FC, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { BigNumber } from 'bignumber.js';

import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { getHumanValue } from 'web3/utils';

import LatestClaimers from './components/latest-claimers';
import Referrals from './components/referrals';
import Jackpot from './components/jackpot';
import HotestTickets from './components/hotest-tickets';
import Journey from './components/journey';
import flags from 'config/flags';
import { getAdditionalLotteryInfo } from 'functions/get-lottery-data';
import { useHTTPContract } from 'hooks/useLotteryContract';
import { useCurrency } from 'context/useCurrency';
import { useNetworks } from 'hooks/useNetworks';
import { getDecimalsBySymbol } from 'functions/utils';
import { useUsersList } from '../../context/useUsersList';

const DashboardView: FC = () => {
  const [lotteryList, setLotteryList]: any = useState([]);

  const contract = useHTTPContract();
  const { exchange } = useCurrency();
  const networks = useNetworks();
  const { restart, stop, hasStopped } = useUsersList();


  const getPrize = useCallback((data: string, tokenName: string, tokenSymbol: string) => {
    const decimals = getDecimalsBySymbol(tokenSymbol);

    const value = +getHumanValue(new BigNumber(+data), decimals)!;
    const usdValue = value * (exchange?.[tokenName.toLowerCase()]?.usd ?? 0);
    return `${usdValue.toFixed(2)}`;
  }, [exchange]);

  useAsyncEffect(async () => {

    let data = await Promise.all(
      networks.map(async (item) => {
        return Promise.all(
          item.tokens.map(async (token) => {
            const { lotteryInfo, lotteryId } = await getAdditionalLotteryInfo(
              item.chainId,
              token.lotteryAddress,
              contract,
            ) ?? {};

            const exchange = getPrize(
              lotteryInfo.amountCollectedInTokens,
              token.tokenFullName ? token.tokenFullName : token.tokenName,
              token.tokenSymbol,
            );

            return {
              ...token,
              chainId: item.chainId,
              networkImage: item.networkImage,
              networkName: item.networkName,
              amountCollectedInTokens: lotteryInfo.amountCollectedInTokens,
              endTime: format(
                new Date(lotteryInfo.endTime * 1000),
                'dd MMM yyyy, HH:mm',
              ),
              exchange: exchange,
              lotteryId,
            };
          }),
        );
      }),
    );
    let result = data.flat();
    result = result.sort((a, b) => (+a.exchange < +b.exchange && 1) || -1);

    setLotteryList(result);
  }, [networks, getPrize]);

  useEffect(() => {
    if (hasStopped) restart();
    return () => stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restart, stop]);

  return (
    <>
      {
        flags.latestClaimers &&
        <LatestClaimers />
      }
      {
        flags.referrals &&
        <Referrals />
      }
      <Jackpot lotteryList={lotteryList[0]} />
      <HotestTickets lotteryList={lotteryList} />
      <Journey />
    </>
  );
};
export default DashboardView;
