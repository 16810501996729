import React, { FC } from 'react';
import { ModalProps } from 'antd/lib/modal';

import s from './styles.module.css';
import { Button, Modal } from 'antd';

export type InstallMetaMaskModalProps = ModalProps & {};

const InstallMetaMaskModal: FC<InstallMetaMaskModalProps> = ({ visible, onCancel }) => {

  return (
    <Modal
      className={s.component}
      centered
      closable={false}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <div className={s.headerLabel}>Install MetaMask</div>
      <div className={s.text}>
        You need to have MetaMask installed to continue. Once you have
        installed it, please refresh the page
      </div>
      <div className={s.footer}>
        <Button
          type='primary'
          className={s.switchBtn}
          href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
          target='_blank'
        >
          Install MetaMask
        </Button>

        <Button
          type='ghost'
          className={s.backBtn}
          onClick={onCancel}
        >
          Go Back
        </Button>
      </div>
    </Modal>
  );
};

export default InstallMetaMaskModal;
