import React, {FC, useMemo } from "react";
import {useQuery} from "../../hooks/useQuery";
import {useLottery} from "../../context/useLottery";
import LotteryView from "views/lottery";
import WalletConnect from "../../components/wallet-connector";
import s from './styles.module.css';

export const IFrame: FC = () =>{
    const { tokens } = useLottery();

    const query = useQuery();
    const token = query.get('token') ?? undefined;
    const topinfo = query.get('topinfo') ? Boolean(Number(query.get('topinfo'))) : undefined;
    const history = query.get('history') ? Boolean(Number(query.get('history'))) : undefined;
    const winner = query.get('winner') ? Boolean(Number(query.get('winner'))) : undefined;

    const isAvailableToken = useMemo(()=>{
      return (token && tokens.includes(token))
    },[tokens, token])

    return <>
        {!isAvailableToken ? <div className={s.empty} >Lottery not found</div> : <>
            <WalletConnect />
            <div className={s.container}>
                <LotteryView
                    topinfo={topinfo}
                    history={history}
                    winner={winner}
                    isInfo={false}
                />
            </div>
        </>
            }
    </>
}
