import { useCallback } from 'react';

type GetCookie = (key: string) => string | void;
type SetCookie = (key: string, value: any) => boolean | void;
type RemoveCookie = (key: string) => string | void;


type CookieStore = () => {
  get: GetCookie
  set: SetCookie
  remove: RemoveCookie
}

export const useCookieStore: CookieStore = () => {

    const get: GetCookie = useCallback(key => {

      let value: string | undefined = undefined;
      try {
        const cookies = document.cookie
          .split('; ');

        const cookie = cookies.find(cookie => cookie.includes(key));
        const [, val] = cookie?.split('=') ?? [];

        value = val;
      } catch {

      }

      return value;
    }, []);

    const set: SetCookie = useCallback((key, value = '') => {
      let success: boolean | undefined;
      try {
        document.cookie = `${key}=${JSON.stringify(value)}`;
        success = true;
      } catch {
        success = false;
      }
      return success;
    }, []);

    const remove: RemoveCookie = useCallback((key) => {
      try {
        document.cookie = `${key}=;expires=${new Date(0)}`;
      } catch {

      }
    }, []);

    return {
      get, set, remove,
    };
};
