import React, { FC } from 'react';
import classes from './styles.module.css';
import OutlinedTextField from '../outlined-textfield';
import { noop } from 'functions/utils';

interface SocialNetworkInputActions {
  onConnect: () => Promise<void>;
  onShare: () => Promise<void>;
}

interface SocialNetworkInputProps extends SocialNetworkInputActions {
  label: string;
  name: string;
  onChangeText: (value: string, name: string) => void;
  value: string;
  connected: boolean;

  rowClassName?: string;
  icon?: React.ReactElement;
}

// const enum Icons {
//   TWITTER = 'TWITTER',
//   DISCORD = 'DISCORD',
// }

const SocialNetworkInput: FC<SocialNetworkInputProps> = (
  {
    rowClassName, value = '',
    onChangeText, label, name,
    icon, connected,
    onConnect, onShare,

  }) => {

  const textChange = (text: string) => {
    onChangeText(text, name);
  };

  return (
    <div className={classes.root}>

      <div className={rowClassName}>
        <OutlinedTextField
          value={value}
          onChange={noop}
          onChangeText={textChange}
          placeholder={`Enter your ${name} address`}
          label={label}
          suffix={icon}
        />

      </div>

      {/*<div className={classes.buttonContainer}>
        {
          connected
            ? <Button className={classes.button} size={'large'} primary onClick={onShare}>Share</Button>
            : <Button className={classes.button} size={'large'} primary disabled={!value} onClick={onConnect}>Connect</Button>
        }
      </div>*/}
    </div>
  );
};

export default SocialNetworkInput;
