import { chainArrays } from '../constants';

export const toHexString = (num: number) => `0x${num.toString(16)}`;
declare let window: any;

export const switchChain = async (chain: number) => {
  let params = [];
  let method = 'wallet_addEthereumChain';

  if (chain === 1) {
    params = [
      {
        chainId: toHexString(chain),
      },
    ];
    method = 'wallet_switchEthereumChain';
  } else {

    const { label, blockExplorerUrls, symbol, decimals, rpcUrls } = chainArrays
      .find(({ chainId }) => chainId === chain) ?? {};

    params = [
      {
        chainId: toHexString(chain),
        chainName: label,
        nativeCurrency: {
          name: symbol,
          symbol: symbol,
          decimals: decimals,
        },
        rpcUrls: [rpcUrls],
        blockExplorerUrls: [blockExplorerUrls],
      },
    ];
  }

  return window.ethereum
    .request({ method, params });
  // .catch((error: Error) => console.log('Error', error.message));
};
