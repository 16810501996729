import React from 'react';

import JorneyImage from 'resources/images/lottery/journey.png';
import InfoSection from 'components/info-section';

import s from './styles.module.css';
import SocialContacts from 'components/social-links/social-contacts';

interface JourneyProps {}

const Journey: React.FC<JourneyProps> = () => {
  return (
    <div className={s.section}>
      <div className={s.image}>
        <img src={JorneyImage} alt="journey" />
      </div>
      <InfoSection title="Become part of the journey">
        <p className={s.description}/>
      </InfoSection>
      <div className={s.socialsBox}>
        <div className={s.socials}>
          <SocialContacts/>
        </div>
        <div className={s.bottomInfo}>
          <a href="mailto: support@lotteryx.io">Support@LotteryX.io</a>
        </div>
      </div>{' '}
    </div>
  );
};

export default Journey;
