import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { apiUrl } from '../constants';
import { UserDto } from '../interfaces/user.dto';
import { getCurrentUser } from './auth.service';
import { UserUpdateDto } from '../interfaces/userUpdate.dto';
import { emptyUserData } from '../functions/emptyUserData';


axios.interceptors.request.use((req) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    if (!req.headers?.['Authorization'])
      req.headers = { ...req.headers, Authorization: `Bearer ${accessToken}` };
  }

  return req;
});

export const updateUser = (data: UserUpdateDto) => {
  return axios.patch(apiUrl.user.update, data)
    .then((response) => {
      const user: UserDto = response.data;
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    });
};

export const getUser = (cancelToken?: CancelToken) => {
  if (localStorage.getItem('accessToken')) {
    return axios.get(apiUrl.user.currentUser, { cancelToken })
      .then((response) => {
        const user: UserDto = response.data;
        return user;
      });
  } else {
    return emptyUserData;
  }
};

export const getUserByParams = (id: number | null = null, address: string | null = null, cancelToken?: AxiosRequestConfig['cancelToken']) => {
  if (localStorage.getItem('accessToken') && (id || address)) {
    const param = id ?? address;
    return axios.get(`${apiUrl.user.getByParams}/${param}`, { cancelToken })
      .then((response) => {
        const user: UserDto = response.data;
        return user;
      });
  } else {
    return emptyUserData;
  }
};

export const getUserList = (limit: number | null = null, order: string | null = null, orderDirection: string = 'DESC', listType: string = '', cancelToken?: AxiosRequestConfig['cancelToken']) => {
  const paramsArray: any[] = [];
  if (limit) {
    paramsArray.push(['limit', limit]);
  }
  if (order) {
    paramsArray.push(['order', order]);
    paramsArray.push(['orderDirection', orderDirection]);
  }
  if (listType) {
    paramsArray.push(['listType', listType]);
  }
  return axios.get(apiUrl.user.getList, {
    params: Object.fromEntries(new Map(paramsArray)),
    cancelToken,
  })
    .then(async (response) => {
      const users: UserDto[] = response.data;
      /*if(users.length) {
        for(const user of users){
          user.avatar = await getAvatar(user);
        }
      }*/
      return users;
    });
};

export const getAvatar = (user: UserDto = getCurrentUser()) => {
  if (user.avatarFileId) {
    return axios.get(
      `${apiUrl.files.getBlob}/?id=${user.avatarFileId}`,
      {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), ''),
        );
        //const avatar =  `data:image/jpeg;base64,${image}`;
        return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
      });
  }
  return '';
};


export const avatarUpload = async (file: any) => {
  const formData = new FormData();
  formData.set('file', file);
  const fileItem = await axios.post(apiUrl.files.upload, formData, Object.assign({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }))
    .then((response) => {
      return response.data;
    });
  if (fileItem && fileItem.id) {
    return updateUser({ avatarFileId: fileItem.id });
  }
};
