import React, { useState, useEffect } from 'react';
import { formatDuration, intervalToDuration, add } from 'date-fns';

import k from './styles.module.css';
import { useCookieStore } from 'hooks/useCookieStore';
import { STORAGE_KEYS } from '../../../../constants';
import { useLottery } from 'context/useLottery';
import { TimerPart } from './timer-part';

const preCountdownText = null;
const postCountdownText = 'until the draw';
const betweenCountdownText = 'until new lottery will start';

const Timer: React.FunctionComponent = () => {

  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [nextTime, setNextTime] = useState(new Date());

  const { lottery } = useLottery();

  const { set, get, remove } = useCookieStore();

  const { endTime } = lottery ?? {};
  const [betweenLotteryTimer, setBetweenLotteryTimer] = useState(false);

  useEffect(() => {
    if (!endTime) return;

    if (endTime) {
      const lotteryEndTime = new Date(endTime * 1000);

      if (lotteryEndTime.getTime() < Date.now()) {

        const oldTime = get(STORAGE_KEYS.time);

        if (!oldTime) {
          const newTime = add(new Date(), { minutes: 30 });
          setNextTime(newTime);
          set(STORAGE_KEYS.time, newTime.getTime());
        } else {
          setNextTime(new Date(+oldTime));
        }

        setBetweenLotteryTimer(true);
      } else {
        remove(STORAGE_KEYS.time);
        setNextTime(lotteryEndTime);
        setBetweenLotteryTimer(prevState => prevState ? !prevState : prevState);
      }
    }
  }, [endTime, get, set, remove]);

  useEffect(() => {

    if (!endTime) {
      return;
    }

    const intervalID = setInterval(() => {

      const finished = new Date(endTime * 1000).getTime() < Date.now()
        && nextTime.getTime() < Date.now();

      let duration = intervalToDuration({
        start: new Date(),
        end: new Date(),
      });

      if (!finished) {
        if (!betweenLotteryTimer) {
          duration = intervalToDuration({
            start: new Date(),
            end: new Date(endTime * 1000),
          });
        }
        if (betweenLotteryTimer) {
          duration = intervalToDuration({
            start: new Date(),
            end: nextTime,
          });
        }
      }


      setTimeRemaining(
        formatDuration(duration, {
          format: ['hours', 'minutes', 'seconds'],
          delimiter: ' ',
          zero: true,
          locale: {
            formatDistance: (token, value) => {
              switch (token) {
                case 'xHours':
                  return `${String(value).padStart(2, '0')}h`;
                case 'xMinutes':
                  return `${String(value).padStart(2, '0')}m`;
                // case 'xSeconds':
                //   return `${String(value).padStart(2, '0')}s`;
              }
            },
          },
        }),
      );
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [endTime, betweenLotteryTimer, nextTime]);

  const timerPart = timeRemaining.split(' ');

  return (
    <div className={k.timer}>
      {preCountdownText && <h2 className={'k'}>{preCountdownText}</h2>}
      <div className={k.timerContainer}>
        {timerPart.map((part) => <TimerPart timeString={part} key={part} />)}
      </div>
      {/*{lottery.endTime && <span className={k.time}>{timeRemaining}</span>}*/}
      {postCountdownText && !betweenLotteryTimer && <h4 className={k.timer}>{postCountdownText}</h4>}
      {betweenCountdownText && betweenLotteryTimer && <h4 className={k.timer}>{betweenCountdownText}</h4>}
    </div>
  );
};

export default Timer;
