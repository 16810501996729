import React from 'react';
import cx from 'classnames';

import { useWallet } from 'wallets/wallet';
import { shortenAddr } from 'web3/utils';

import { ReactComponent as ChevronTopSvg } from 'resources/svg/icons/chevron-top.svg';
import { ReactComponent as BellSvg } from 'resources/svg/icons/bell.svg';

import s from './styles.module.css';
import Button from '../themed-button';
import { Badge, Col, Popover, Row, Tooltip } from 'antd';
import { Avatar } from 'antd';
import { useProfile } from 'context/useProfile';
import {Link, useLocation} from 'react-router-dom';
import { nameInitials } from 'functions/utils';
import paths from "../../constants/paths";

export type ConnectedWalletProps = {};

// declare let window: any;

const ConnectedWallet: React.FunctionComponent<ConnectedWalletProps> = () => {
  const wallet = useWallet();
  const { pathname } = useLocation();
  const { user, avatar } = useProfile();
  // not used, temporarily disabled

  // const {swappContract} = useLottery()

  // const [/*ethBalance*/, setEthBalance] = useState(0);
  // const [/*chains*/] = useState([...chainArrays]);
  //
  //
  // useAsyncEffect(async () => {
  //
  //   if (wallet.account && swappContract?.methods) {
  //     try {
  //       const balance = getHumanValue(
  //         new BigNumber(await
  //             swappContract?.methods.balanceOf(wallet.account).call(),
  //           18,)
  //       );
  //       if (balance) {
  //         setEthBalance(parseFloat(balance.toString()));
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }, [ wallet.account, swappContract]);

  function handleWalletConnect() {
    wallet.showWalletsModal();
  }

  function handleWalletDisconnect() {
    wallet.disconnect();
  }

  /*const addNewChain = (chain: any) => {*/
  //   const params = [
  //     {
  //       chainId: chain.chainIdHex,
  //       chainName: chain.label,
  //       nativeCurrency: {
  //         name: chain.symbol,
  //         symbol: chain.symbol,
  //         decimals: chain.decimals,
  //       },
  //       rpcUrls: [chain.rpcUrls],
  //       blockExplorerUrls: [chain.blockExplorerUrls],
  //     },
  //   ];
  //
  //   window.ethereum
  //     .request({ method: 'wallet_addEthereumChain', params })
  //     .then(() => console.log('Success'))
  //     .catch((error: Error) => console.log('Error', error.message));
  // };

  if (wallet.connecting) {
    return (
      <div className={s.component}>
        <Popover
          overlayClassName={s.overlay}
          placement='bottomRight'
          content={
            <div>
              <Row className={s.disconnectBtnRow}>
                <Button
                  className={s.disconnectBtn}
                  onClick={handleWalletDisconnect}
                >
                  Disconnect
                </Button>
              </Row>
            </div>
          }
          trigger='click'
        >
          {/*<Badge
            className={cx(s.notificationBtn, s.hidden)}
            dot
            count={0}
            showZero={false}
          >
            <BellSvg />
          </Badge>*/}
          <Button size='large'>
            Connecting...
          </Button>
        </Popover>
      </div>
    );
  }

  if (!wallet.isActive) {
    return (
      <div className={s.component}>
        <Badge
          className={cx(s.notificationBtn, s.hidden)}
          dot
          count={0}
          showZero={false}
        >
          <BellSvg />
        </Badge>
        <Button
          size='large'
          onClick={handleWalletConnect}
        >
          Connect Wallet
        </Button>
      </div>
    );
  }

  return (
    <div className={s.component}>
      <div className={s.walletBox}>
        {/* hidden notification pending clarification */}
        {/*<NotificationsPopup>*/}
        {/*  <BellSvg/>*/}
        {/*</NotificationsPopup>*/}
        <Popover
          placement='bottomRight'
          content={
            <div>
              <div className={s.overlay}>
                <Row className={s.disconnectBtnRow}>
                  <Button
                    ghost
                    fullWidth
                    className={s.disconnectBtn}
                    onClick={handleWalletDisconnect}
                  >
                    Disconnect
                  </Button>
                  <Tooltip
                    placement='bottom'
                    title='Text copied!'
                    color='#795ced'
                    overlayInnerStyle={{ color: 'white' }}
                  >
                    <Button
                      ghost
                      fullWidth
                      className={s.disconnectBtn}
                      onMouseEnter={() => {
                        navigator.clipboard.writeText(
                          `https://lotteryx.io/?ref=${wallet.account!}`,
                        );
                      }}
                    >
                      Copy Referral Link
                    </Button>
                  </Tooltip>
                </Row>
              </div>
              {/* <div className={s.overlay}>
                <Row className={s.disconnectBtnRow}>
                  <>
                    <span className={s.navegationName}>
                      Add Networks to MetaMask:
                    </span>
                    <div className={s.connectorList}>
                      {chains!.map(
                        (chain: any, index: number) =>
                          index > 0 && (
                            <Button
                              key={chain.id}
                              type="ghost"
                              className={s.connectorBtn}
                              onClick={() => addNewChain(chain)}
                            >
                              {chain.icon}
                              <span className={s.connectorName}>
                                {chain.label}
                              </span>
                            </Button>
                          )
                      )}
                    </div>
                  </>
                </Row>
              </div> */}
            </div>
          }
          trigger='click'
        >
          <Row className={s.walletPreview}>
            {/* <Col>
              <Identicon address={wallet.account} className={s.walletPreviewAvatar} />
            </Col> */}
            <Col>
              <span className={s.walletPreviewHashOnBtn}>
                {shortenAddr(wallet.account!, 4, 4)}
              </span>
            </Col>
            <Col>
              <ChevronTopSvg className={s.walletPreviewArrow} />
            </Col>
          </Row>
        </Popover>
      </div>
        {pathname !== paths.IFRAME && <Link to={'/profile'}>
            {<Avatar src={avatar} size={38} shape={'square'} className={s.avatar}>
                {!avatar && nameInitials(user)}
            </Avatar>}
        </Link>}

    </div>
  );
};

export default ConnectedWallet;
