import { useCallback, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { getAllTickets } from '../views/lottery/components/check-prizes/get-all-tickets';
import {
  LotteryRoundEntity,
  LotteryStatus,
} from '../views/lottery/components/check-prizes/interfaces';
import { NUM_ROUNDS_TO_CHECK_FOR_REWARDS } from '../views/lottery/constants';
import {
  getLotteriesInfoObjects,
  getLotteryEvents,
  getWinningTickets,
} from '../functions/get-lottery-data';
import {
  FetchUserTicketsForMultipleRounds,
  LotteryTicketClaimData,
  RoundDataAndUserTickets,
  TicketsForMultipleRounds,
  UseGetUnclaimedRewards,
} from '../interfaces/lottery';
import { useLottery } from '../context/useLottery';

const useGetUnclaimedRewards: UseGetUnclaimedRewards = (props) => {
  const { skip = 0, first = 100, where = {} } = props ?? {};

  const { account } = useWeb3React();
  const {
    token: { graphUrl },
    contractLottery,
  } = useLottery();

  const [fetching, setFetching] = useState(false);
  const [unclaimedRewards, setUnclaimedRewards] = useState<
    LotteryTicketClaimData[]
  >();

  const fetchAllRewards = useCallback(async (): Promise<any> => {
    try {
      setFetching(true);
      const { user } = await getAllTickets({
        skip,
        where,
        first,
        account: account!,
        url: graphUrl!,
      });

      if (!user) {
        setUnclaimedRewards([]);
      } else {
        const { rounds } = user;
        // Filter out non-claimable rounds
        const claimableRounds = (rounds ?? []).filter(
          ({ lottery }) =>
            lottery?.status?.toLowerCase() === LotteryStatus.CLAIMABLE
        );

        // Rounds with no tickets claimed OR rounds where a user has over 100 tickets, could have prizes
        const roundsWithPossibleWinnings = claimableRounds.filter((round) => {
          return !round.claimed || parseInt(round.totalTickets, 10) > 100;
        });

        // Check last X most recent rounds, where X is NUM_ROUNDS_TO_CHECK_FOR_REWARDS
        const roundsToCheck = roundsWithPossibleWinnings.slice(
          0,
          NUM_ROUNDS_TO_CHECK_FOR_REWARDS
        );
        if (roundsToCheck.length > 0) {
          const idsToCheck = roundsToCheck.map((round) => round.lottery.id);
          const userTicketData = await getUserTicketData({
            contractLottery,
            idsToCheck,
            account: account!,
          });
          const roundsWithTickets = userTicketData.filter(
            (roundData) => roundData?.userTickets
          );
          const lotteriesData = await getLotteriesInfoObjects({
            lotteries: roundsWithTickets,
            contractLottery,
          });

          const roundDataAndWinningTickets = roundsWithTickets.map(
            (roundData) => {
              return {
                ...roundData,
                finalNumber: getWinningNumbersForRound(
                  roundData.roundId,
                  lotteriesData
                ),
              };
            }
          );

          const winningTicketsForPastRounds = await Promise.all(
            roundDataAndWinningTickets.map((roundData) =>
              getWinningTickets(
                roundData as RoundDataAndUserTickets,
                contractLottery,
                account!
              )
            )
          );

          const unclaimedTickets = winningTicketsForPastRounds.filter(
            (i) => i !== null && i.ticketsWithUnclaimedRewards !== null
          ) as LotteryTicketClaimData[];

          setUnclaimedRewards(unclaimedTickets);
        }
      }
    } catch (e) {
      console.log('useGetUnclaimedRewards error', e);
    } finally {
      setFetching(false);
    }
  }, [contractLottery, account, skip, first, where, graphUrl]);

  return {
    fetching,
    fetchAllRewards,
    unclaimedRewards,
  };
};

export default useGetUnclaimedRewards;

export const getUserTicketData: FetchUserTicketsForMultipleRounds = async ({
  contractLottery,
  idsToCheck,
  account,
}) => {
  const fetchUserTicketsForMultipleRounds = async (
    idsToCheck: string[],
    account: string
  ): Promise<TicketsForMultipleRounds> => {
    const ticketsForMultipleRounds: TicketsForMultipleRounds = [];
    for (let i = 0; i < idsToCheck.length; i += 1) {
      const roundId = idsToCheck[i];
      // eslint-disable-next-line no-await-in-loop
      const ticketsForRound = await getLotteryEvents(
        account,
        roundId,
        contractLottery
      );
      ticketsForMultipleRounds.push({
        roundId,
        userTickets: ticketsForRound,
      });
    }
    return ticketsForMultipleRounds;
  };

  return fetchUserTicketsForMultipleRounds(idsToCheck, account);
};

const getWinningNumbersForRound = (
  targetRoundId: string,
  lotteriesData: LotteryRoundEntity[]
) => {
  const targetRound = lotteriesData.find(
    (pastLottery) => pastLottery.id === targetRoundId
  );
  return targetRound?.finalNumber;
};

/***
 *
 * @param ticketNumber string i.e. '1123456'
 * @param finalNumber string i.e. '1123456'
 *
 * @return length match numbers quantity - 1
 */
export const getRewardBracketByNumber = (
  ticketNumber: string,
  finalNumber: string
): number => {
  // Winning numbers are evaluated right-to-left in the smart contract, so we reverse their order for validation here:
  // i.e. '1123456' should be evaluated as '6543211'
  const ticketNumAsArray = ticketNumber.split('').reverse();
  const winningNumsAsArray = finalNumber.split('').reverse();
  const matchingNumbers = [];

  // The number at index 6 in all tickets is 1 and will always match, so finish at index 5
  for (let index = 0; index < winningNumsAsArray.length - 1; index++) {
    if (ticketNumAsArray[index] !== winningNumsAsArray[index]) {
      break;
    }
    matchingNumbers.push(ticketNumAsArray[index]);
  }

  // Reward brackets refer to indexes, 0 = 1 match, 5 = 6 matches. Deduct 1 from matchingNumbers' length to get the reward bracket
  return matchingNumbers.length - 1;
};
