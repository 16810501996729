import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalProps } from 'antd/lib/modal';
import cx from 'classnames';

import ClaimPrizeModal from '../claime-prize-modal';

import { parseRetrievedNumber } from 'functions/helpers';

import classes from './styles.module.css';
import { ExtendContractProps, TicketNumber } from 'interfaces/lottery';
import { Modal } from 'antd';
import Button from '../../themed-button';
import ModalHeader from '../components/modal-header';
import ModalBody from '../components/modal-body';
import HistoryNumbers from 'views/lottery/components/winning-numbers/components/history-numbers';
import { LotteryData } from 'views/lottery/components/winning-numbers/components/history';
import { ReactComponent as TicketStar } from 'resources/svg/icons/ticket-star.svg';
import { ReactComponent as Trophy } from 'resources/svg/icons/trophy.svg';
import { ReactComponent as Wallet } from 'resources/svg/icons/wallet-outline.svg';
import { Contract } from 'web3-eth-contract';
import { OnClaimResponse } from 'views/profile/components/tabs/components/history';


export type ShowTicketModalProps = ModalProps & {
  lotteryId: string;
  lotteryData: LotteryData | null
  onClaim?: () => Promise<OnClaimResponse>
};

const LotteryInfoModal: React.FunctionComponent<ShowTicketModalProps> = (
  {onCancel, lotteryData, lotteryId, visible, onClaim},
) => {

  const [claimPrizeVisible, setClaimPrizeVisible] = useState<boolean>(false);

  const [winningTicketsQty, setWinningTicketsQty] = useState(0);
  const [numbers, setNumbers] = useState<TicketNumber[]>([]);

  const [contract, setContract] = useState<Contract & ExtendContractProps>();

  const winningNumbers = useMemo(() => {
    if (lotteryData?.finalNumber === '0' || !lotteryData?.finalNumber)
      return null;

    return parseRetrievedNumber(lotteryData.finalNumber);
  }, [lotteryData?.finalNumber]);

  useEffect(() => {
    const result =
      lotteryData?.events.id.map((id, ind) => {
        const ticket: string = parseRetrievedNumber(
          lotteryData.events.number[ind],
        );

        if (!winningNumbers) {
          return {
            id,
            win: '-1',
            number: ticket,
          };
        }

        let lastIndex = -1;
        for (let i = 0; i < ticket.length; i++) {
          if (winningNumbers[i] === ticket[i]) {
            lastIndex++;
          } else {
            i = ticket.length;
          }
        }

        return {
          id,
          win: lastIndex.toString(),
          number: ticket,
        };
      }) ?? [];

    const counter = result.filter((item) => +item.win >= 0).length;
    setWinningTicketsQty(counter);
    setNumbers(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winningNumbers, lotteryData?.events]);

  const changeClaimModalVisibility = useCallback(() => setClaimPrizeVisible(prevState => !prevState), []);

  const onClaimPress = useCallback(async () => {
    if (typeof onClaim === 'function') {
      const { contract, success } = await onClaim();
      if (success) {
        setContract(contract);
        changeClaimModalVisibility();
      }
    }
  }, [changeClaimModalVisibility, onClaim]);

  if (!lotteryData) return null;

  return (
    <Modal
      centered
      destroyOnClose
      closable={true}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <ModalHeader title={`Round ${lotteryId}`} />

      <ModalBody className={classes.body}>
        {winningNumbers &&
          <>
            <Divider className={classes.topDivider} />
            <div className={classes.winning}>
              <HistoryNumbers
                title={'Winning Number'}
                numbers={{ win: winningNumbers }}
                winning
                titleClassName={classes.subtitle}
                numbersClassName={classes.winningNumbers}
              />
            </div>
            <Divider />
          </>
        }

        <p className={classes.subtitle}>Your tickets</p>

        <div>
          <p className={classes.infoRow}>
              <span className={classes.icon}>
                <TicketStar aria-hidden />
              </span>
            <span className={classes.text}>Total Tickets:</span>
            <span>{lotteryData.events.number.length ?? 0}</span>
          </p>
          {
            winningNumbers &&
            <p className={classes.infoRow}>
              <span className={classes.icon}>
                <Trophy aria-hidden />
              </span>
              <span className={classes.text}>Winner Tickets:</span>
              <span>{winningTicketsQty}</span>
            </p>
          }
        </div>

        <div className={classes.tickets}>
          {
            numbers.map(({ id, win, number }) => (
              <div key={id}>
                <HistoryNumbers
                  titleClassName={classes.rowSubtitle}
                  title={`#${id}`}
                  numbers={{ win, ticket: number }}
                  outlined
                />
              </div>
            ))}
        </div>

        <Divider />

        {winningNumbers ?
          lotteryData.winningTickets
            ? lotteryData.winningTickets.ticketsWithUnclaimedRewards
              ? (
                <Button success
                        fullWidth
                        onClick={onClaimPress}
                        className={classes.buttonIcon}><Wallet /> Claim</Button>

              )
              : (
                <Button primary
                        fullWidth
                        onClick={onCancel}
                        className={classes.buttonIcon}><Wallet /> Already claimed</Button>
              )
            : (
              <Button primary
                      fullWidth
                      onClick={onCancel}
                      className={classes.buttonIcon}><Wallet /> Better luck next time!</Button>
            )
          : (
            <Button primary
                    fullWidth
                    onClick={onCancel}
                    className={classes.buttonIcon}><Wallet /> Calculating rewards</Button>
          )
        }

      </ModalBody>
      <ClaimPrizeModal
        roundsToClaim={lotteryData.winningTickets ? [lotteryData.winningTickets] : []}
        visible={claimPrizeVisible}
        onSuccess={changeClaimModalVisibility}
        contract={contract}
        onCancel={changeClaimModalVisibility}
      />
    </Modal>
  );
};


const Divider: FC<Partial<HTMLDivElement>> = ({ className }) => (
  <div className={cx(classes.divider, className)} />
);
export default LotteryInfoModal;
