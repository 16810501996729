import React from "react";
import { MenuInfo } from "rc-menu/lib/interface";
import { CaretDownOutlined } from "@ant-design/icons";
import cx from "classnames";

import s from "./styles.module.css";
import { Button, Menu, Dropdown as OrigDropDown } from 'antd';

export type DropdownOption = {
  value: string | number;
  label: string;
};

export type DropdownProps = {
  className?: string;
  listClassName?: string;
  button?: boolean;
  label?: string;
  items: DropdownOption[];
  selected?: string | number;
  disabled?: boolean;
  onSelect?: (selected: string | number) => void;
};

const Dropdown: React.FunctionComponent<DropdownProps> = (props) => {
  const { className, listClassName, button, label, items, selected, disabled } =
    props;

  const propsRef = React.useRef(props);
  propsRef.current = props;

  const menu = React.useMemo(() => {
    const onSelect = ({ key }: MenuInfo) => {
      propsRef.current.onSelect?.(key);
    };

    return (
      <Menu
        className={listClassName}
        selectedKeys={[String(selected)]}
        onClick={onSelect}
      >
        {items.map((item) => (
          <Menu.Item key={item.value}>{item.label}</Menu.Item>
        ))}
      </Menu>
    );
  }, [items, selected, listClassName]);

  const selectedItem = React.useMemo<DropdownOption | undefined>(() => {
    return items.find((item) => String(item.value) === String(selected));
  }, [items, selected]);

  return (
    <OrigDropDown
      className={cx(s.component, className)}
      overlay={menu}
      disabled={disabled}
    >
      {button ? (
        <Button>
          {label && <span className={s.label}>{label}</span>}
          {selectedItem && (
            <span className={s.selected}>{selectedItem?.label}</span>
          )}
          <CaretDownOutlined className={s.caret} />
        </Button>
      ) : (
        <a href="?" onClick={(e) => e.preventDefault()}>
          <span className={s.selected}>{selectedItem?.label || label}</span>
          <CaretDownOutlined />
        </a>
      )}
    </OrigDropDown>
  );
};

export default Dropdown;
