import React from 'react';

import InfoSection from 'components/info-section';

import k from './styles.module.css';

import { ReactComponent as Step1Svg } from 'resources/svg/static/step1.svg';
import { ReactComponent as Step2Svg } from 'resources/svg/static/step2.svg';
import { ReactComponent as Step3Svg } from 'resources/svg/static/step3.svg';

import { steps } from '../../constants';

const components = [Step1Svg, Step2Svg, Step3Svg];

const RulesSection: React.FunctionComponent = () => {
  return (
    <div className={k.section}>
      <InfoSection title="How to play">
        <p className={k.description}>
          If the digits on your tickets match the winning numbers in the correct
          order, you win a portion of the prize pool.
        </p>
      </InfoSection>
      <div className={k.rulesWrapper}>
        {steps &&
          steps.map((step, index: number) => {
            const IndexImage = components[index];
            return (
              <div key={step.id} className={k.stepItem}>
                <IndexImage />
                <span className={k.stepNumber}>step {step.id}</span>
                <span className={k.stepTitle}>{step.title}</span>
                <span className={k.stepDescription}>{step.description}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RulesSection;
