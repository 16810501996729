import React, { useEffect } from 'react';

import { numberWithCommas } from 'functions/helpers';
import { shortenAddr } from 'web3/utils';

import { ReactComponent as DollarSvg } from 'resources/svg/static/dollar.svg';

import s from './styles.module.css';
import {useUsersList} from "../../../../context/useUsersList";
import classes from "../../../profile/components/header/styles.module.css";
import { Avatar } from "antd";
import { nameInitials } from "../../../../functions/utils";
import { UserDto } from "../../../../interfaces/user.dto";
import cx from "classnames";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const LatestClaimers: React.FunctionComponent = () => {
  useEffect(() => {}, []);
  const { usersList } = useUsersList();
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 8,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
  return (
    <div className={s.section}>
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
        {(usersList.winners as UserDto[]).map((item: UserDto ) => {
          const avatarName = !item.avatar || item.avatar === '' ? nameInitials(item) : '';
          const avatarUrl = item.avatar && item.avatar.url ? `https://${item.avatar.url}` : '';
          return (
            <div className={s.person} key={item.id}>
              <Avatar src={avatarUrl} className={cx(classes.avatar, s.avatar)} alt={'avatar'}
                      size={64}>{avatarName}</Avatar>
              <div className={s.info}>
                <div className={s.address}>
                  {shortenAddr(item.address, 2, 4)}
                </div>
                <div className={s.moneyWon}>
                  + {numberWithCommas((+item.moneyWon).toFixed(2))}
                  <DollarSvg className={s.currency}/>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default LatestClaimers;
