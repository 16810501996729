import React, { useState, useEffect, useCallback } from 'react';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import { useWallet } from 'wallets/wallet';

import { ZERO_BIG_NUMBER, getHumanValue } from 'web3/utils';

// import { SWAPPTokenMetaN } from 'web3/contracts/swapp';
import { TICKET_LIMIT_PER_REQUEST } from 'views/lottery/constants';

import { ReactComponent as LotteryTicket } from 'resources/svg/icons/lottery-ticket.svg';

import BuyTicketModal from 'components/modals/buy-ticket-modal';
import ShowTicketModal from 'components/modals/show-ticket-numbers-modal';
import Timer from '../timer';
import { useCurrency } from 'context/useCurrency';
import { processRawToTicketsResponse } from 'functions/get-lottery-data';
import k from './styles.module.css';
import { useLottery } from 'context/useLottery';
// import { add, format } from 'date-fns';
// import { NEXT_LOTTERY_PERIOD } from '../../../../constants';
import Button from 'components/themed-button';
import SocialLinks from 'components/social-links';
import { tokenLinks } from 'constants/token-links';
import {useLocation} from "react-router-dom";
import paths, { appLink } from "../../../../constants/paths";

export type LotteryProps = {
  getUpdatedData: Function;
  updatedData: boolean;
};

type TicketsDataType = {
  id: [];
  number: [];
  status: [];
};

type StateType = {
  ticketData: TicketsDataType;
  loading: boolean;
  enabled?: boolean;
  amount?: BigNumber;
  walletBalance?: BigNumber;
  allowanceSwapp?: BigNumber;
};

const InitialState: StateType = {
  ticketData: { id: [], number: [], status: [] },
  loading: false,
  enabled: false,
  amount: undefined,
  walletBalance: undefined,
  allowanceSwapp: undefined,
};

const HeroSection: React.FunctionComponent<LotteryProps> = ({
                                                              getUpdatedData,
                                                              updatedData,
                                                            }) => {
  const wallet = useWallet();
  const { account, networkId, provider } = wallet;
  const { pathname } = useLocation();
  const {
    lotteryId,
    lottery: lotteryInfo,
    contractLottery,
    token: { lotteryAddress, tokenAddress, tokenName, tokenFullName, partner, Icon, decimals },
    swappContract,
  } = useLottery();

  const links = tokenLinks[tokenName as string] ?? [];

  const { amountCollectedInTokens } = lotteryInfo;

  const { exchange } = useCurrency();

  const [walletsModal, setWalletsModal] = useState<boolean>(false);
  const [state, setState] = useState<StateType>(InitialState);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [value, setValue] = useState('');
  const [usdValue, setUsdValue] = useState('');

  const getLotteryEvents = useCallback(async () => {
    let balance: BigNumber | undefined = new BigNumber(0);
    let allowanceSwapp: BigNumber | undefined = new BigNumber(0);

    if (account && networkId && swappContract && lotteryId && decimals) {

      balance = await swappContract?.methods.balanceOf(account).call();
      balance = getHumanValue(
        new BigNumber(balance!),
        decimals,
      );

      allowanceSwapp = await swappContract?.methods
        ?.allowance(account, lotteryAddress)
        .call();

      allowanceSwapp = new BigNumber(allowanceSwapp!);
      let cursor = 0;
      let numReturned = TICKET_LIMIT_PER_REQUEST;
      let data: any = {};

      if (contractLottery?.methods) {
        while (numReturned === TICKET_LIMIT_PER_REQUEST) {
          const response = await contractLottery.methods
            ?.viewUserInfoForLotteryId(
              account,
              lotteryId,
              cursor,
              TICKET_LIMIT_PER_REQUEST,
            )
            .call();
          cursor += TICKET_LIMIT_PER_REQUEST;
          numReturned = response[0].length;
          data = processRawToTicketsResponse(response, data);
        }
      }

      setState((prevState) => ({
        ...prevState,
        ticketData: data,
        walletBalance: balance,
        allowanceSwapp,
        enabled: allowanceSwapp!.gt(ZERO_BIG_NUMBER) ?? false,
        decimals,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    networkId,
    account,
    lotteryAddress,
    lotteryId,
    tokenAddress,
    provider,
    swappContract,
    updatedData,
    decimals,
  ]);

  useEffect(() => {
    getLotteryEvents().catch(console.warn);
  }, [getLotteryEvents]);

  useEffect(() => {
    // let sum = '';
    let usdValue = 0;

    if (amountCollectedInTokens) {
      const value = +getHumanValue(new BigNumber(amountCollectedInTokens) ?? ZERO_BIG_NUMBER, decimals)!;
      const sum = value.toFixed(2);

      setValue(sum);
      usdValue = value * (exchange?.[(tokenFullName || tokenName)?.toLowerCase() ?? '']?.usd ?? 0);
      setUsdValue(usdValue.toFixed(2));
    }
  }, [exchange, amountCollectedInTokens, decimals, tokenFullName, tokenName /*tokenSymbol*/]);

  // const Elem = () => {
  //   if (Icon) return <Icon />;
  //   return null
  // };
  const actionAfterBuy = useCallback(() => {
    setWalletsModal(false);
    getUpdatedData();
    getLotteryEvents().catch();
  }, [getLotteryEvents, getUpdatedData])

  const openWalletModal = () => setWalletsModal(true);
  const closeWalletModal = useCallback(() => setWalletsModal(false) ,[]);
  const closeShowTicketModal = useCallback(() => setShowModal(false) ,[]);
  const showTicketModalSetVisibility = useCallback(() => {
    setShowModal(false);
    setWalletsModal(true);
  } ,[]);

  return (
    <>
      <div className={k.wrapper}>
        {partner && (
          <Button
            className={k.partnerLink}
            ghost
            size={'small'}
            type={'link'}
            target='_blank'
            href={partner}
          >
            Official partner
          </Button>
        )}

        <div className={k.hero}>
          <div className={k.token}>
            {Icon && <Icon className={k.heroIcon} />}
          </div>
          <h3 className={k.title}>
            <span>{tokenName ?? ''}</span> Lottery!
          </h3>

          <h2 className={k.prizeTitle}>
            {!!value && (
              <>
                {Icon && (
                  <>
                    <Icon className={k.prizeIcon} />{' '}
                  </>
                )}
                <span>{value}</span>
              </>
            )}
          </h2>
          <p className={k.titleSmall}>{!!usdValue && `$ ${usdValue}`}</p>
          <h4 className={k.subTitle}>in prizes!</h4>
          <Timer />
          <div className={k.ticketWrapper}>
            <LotteryTicket className={k.ticket} />
            <div className={k.contentWrapper}>
              <h2 className={k.ticketTitle}>Your Ticket</h2>
              <div className={cx(k.innerCont, k.middle)}>
                <span>Prize Pot</span>
                <h3 className={k.prizeCont}>
                  {Icon && <Icon className={k.prize} />}
                  <span className={k.prize}>{!!value && `${value}`}</span>
                  <span className={k.prizeUsd}>
                    {!!usdValue && `~$ ${usdValue}`}
                  </span>
                </h3>
              </div>
              <div>
                <p className={k.innerCont}>
                  <span>Your tickets:</span>
                  <span>
                    You have{' '}
                    <span
                      className={k.ticketsQty}
                      onClick={() =>
                        state.ticketData.number?.length
                          ? setShowModal(true)
                          : null
                      }
                    >
                      {state.ticketData.number?.length ?? 0}
                    </span>{' '}
                    ticket this round
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className={cx(k.footer, 'highlighted')}>
            <Button onClick={openWalletModal} primary size={'large'}>
              Buy Tickets
            </Button>

            <SocialLinks links={links} className={k.socials} />
            {pathname === paths.IFRAME && <p>
              powered by <a href={appLink} target="_blank">LotteryX</a>
            </p>}

          </div>
        </div>
      </div>
      <BuyTicketModal
        visible={walletsModal}
        setVisibility={actionAfterBuy}
        onCancel={closeWalletModal}
        walletBalance={+(state.walletBalance ?? '')}
        getLotteryEvents={getLotteryEvents}
        enabled={state.enabled!}
        decimals={decimals as number}
      />
      <ShowTicketModal
        visible={showModal}
        setVisibility={showTicketModalSetVisibility}
        onCancel={closeShowTicketModal}
        lotteryId={lotteryId}
        ticketData={state.ticketData}
      />
    </>
  );
};

export default HeroSection;
