import classes from './styles.module.css';
import { FC } from 'react';
import cx from 'classnames';

type Numbers = {
  win: string
  ticket?: string
}

interface SharedProps {
  title: string;
  numbers: Numbers;

  titleClassName?: string;
  numbersClassName?: string;
  outlined?: boolean;
}

interface HistoryTicketProps extends SharedProps {
  winning?: never;
}

interface HistoryWinProps extends SharedProps {
  winning?: true;
}

type HistoryNumbersProps = HistoryWinProps | HistoryTicketProps


const HistoryNumbers: FC<HistoryNumbersProps> = (
    {
      winning = false, title, numbers, numbersClassName, titleClassName,
      outlined = false,
    },
  ) => {
    const { win, ticket } = numbers;

    const style = cx(classes.root, numbersClassName,
      winning && classes.winning,
      !winning && outlined && +win > -1 && classes.outlined,
    );


    const nums = winning
      ? win.split('')
      : ticket?.split('') ?? [];
    if (winning && win.length < 6) return null;
    if (!winning && (win.length < 6 && ticket?.length && ticket.length < 6)) return null;

    return (
      <>
        <p className={cx(classes.title, titleClassName)}>{title}</p>
        <p className={style}>
          {
            nums.map((num, ind) => (
              <span key={num + ind}
                    {...{ 'data-selected': !winning && ind <= +win }}
                    className={cx(classes.number, !winning && ind <= +win && classes.winNumber)}
              >{num}</span>
            ))
          }
        </p>
      </>
    );
  }
;

export default HistoryNumbers;
