const batch = (rounds: number[], limit: number, arr: number[][]): number[][] => {
  if (rounds.length > limit) {
    arr.push([...rounds.slice(0, limit)]);

    return batch(rounds.slice(limit), limit, arr);
  } else {
    arr.push([...rounds]);
  }
  return arr;
};

export const spliceToButch = (rounds: number[], limit: number): number[][] => {
  let _: number[][] = [];
  batch(rounds, limit, _);
  return _;
};
