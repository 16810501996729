import {UserDto} from '../interfaces/user.dto';

export const emptyUserData: UserDto = {
    id: null,
    address: '',
    firstName: '',
    lastName: '',
    email: '',
    twitter: '',
    telegram: '',
    facebook: '',
    discord: '',
    instagram: '',
    moneyWon: '',
    moneyReward: '',
    totalTicketsPurchased: 0,
    ticketsWon: 0,
    avatarFileId: null,
};