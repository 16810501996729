import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useWallet } from '../wallets/wallet';
import { /*getUserAvatar,*/ getUserInfo } from '../functions/profile';

import { updateUser } from '../services/user.service';
import { UserDto } from '../interfaces/user.dto';
import { UserUpdateDto } from '../interfaces/userUpdate.dto';
import axios, { CancelTokenSource } from 'axios';

type UpdateUserProfile = (values: UserUpdateDto) => Promise<UserDto | void>

interface ProfileProviderData {
  user: UserDto | null;
  updateUser: UpdateUserProfile;
  userFetching: boolean;
  userSaving: boolean;
  avatar: string;
//  TODO: extend
}

const ProfileContext = createContext<ProfileProviderData>({
  user: null,
  updateUser: async () => undefined,
  userFetching: false,
  userSaving: false,
  avatar: '',
});

const getAvatarUrl = (data: UserDto) => {
  return (data.avatar && data.avatar.url) ? `https://${data.avatar.url}` : '';
}

export const useProfile = () => useContext(ProfileContext);
const ProfileProvider: FC = ({ children }) => {

  const { account } = useWallet();

  const cancelToken = useRef<CancelTokenSource>();

  const [userFetching, setUserFetching] = useState(false);
  const [userSaving, setUserSaving] = useState(false);
  const [user, setUser] = useState<UserDto | null>(null);
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setUserFetching(true);

        if (account !== undefined) {

          setAvatar('');
          setUser(null);

          cancelToken.current?.cancel();
          const source = axios.CancelToken.source();

          if (localStorage.getItem('accessToken')) {
            const data = await getUserInfo(source.token);
            setUser(data);
            setAvatar(getAvatarUrl(data));
          }
        }
      } finally {
        setUserFetching(false);
        cancelToken.current = undefined;
      }
    })();
  }, [account]);

  const onSettingsSave: UpdateUserProfile = useCallback(async (values) => {
    try {
      setUserSaving(true);
      const data = await updateUser(values);
      setUser(data);
      setAvatar(getAvatarUrl(data));
    } catch (e) {
      console.error(ProfileProvider.name, ' ', onSettingsSave.name, e);
    } finally {
      setUserSaving(false);
    }
  }, []);

  return (
    <ProfileContext.Provider value={{ user, avatar, updateUser: onSettingsSave, userFetching, userSaving }}>
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
